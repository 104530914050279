import apiService from '@/core/common/services/api.service';
import { areArraysEqualSets } from '@/core/common/helpers/utils.js';
export const SET_FULL_METADATA_HISTORY = 'reisenMetadata.setFullMetadataHistory';
export const ADD_METADATA_TO_HISTROY = 'reisenMetadata.addMetadataToHistory';
export const SEND_REISE_METADATA_FORM = 'reisenMetadata.sendReisenMetadataForm';

export const SET_IS_BUSY = 'reisenMetadata.setIsBusy';
export const IS_ADDING_METADATA = 'reisenMetadata.setIsAddingReisenMetadata';
export const ADD_REISE_METADATA = 'reisenMetadata.addReisenMetadata';
export const UPDATE_REISE_METADATA = 'reisenMetadata.updateReisenMetadata';
export const SET_IS_ADDING_METADATA = 'reisenMetadata.setIsAddingReisenMetadata';

export const TOGGLE_EDITING_MULTIPLE_REISETERMIN_METADATA_BY_FIELD =
  'reisenMetadata.toggleEditingMultipleReiseterminMetadataByField';
export const UPDATE_MULTIPLE_REISETERMIN_METADATA_BY_FIELD =
  'reisenMetadata.updateMultipleReiseterminMetadataByField';
export const SET_CURRENTLY_EDITING_METADATA_FIELD = 'reisenMetadata.setCurrentlyEditingMetadataField';
export const SET_CURRENTLY_EDITING_METADATA_REISETERMIN_STARTDATUM =
  'reisenMetadata.setcurrentlyEditingMetadataReiseterminStartdatum';

export const START_ADDING_METADATA = 'reisenMetadata.startAddingReisenMetadata';
export const STOP_ADDING_METADATA = 'reisenMetadata.stopAddingReisenMetadata';

export const TOGGLE_EDITING_METADATA = 'reisenMetadata.toggleEditingReisenMetadata';
export const DELETE_METADATA = 'reisenMetadata.deleteMetadata';
export const SET_EDITING_METADATA = 'reisenMetadata.setEditingReisenMetadata';
export const TOGGLE_EDITING_REISETERMIN_METADATA = 'reisenMetadata.toggleEditingReiseterminMetadata';
export const SET_EDITING_REISETERMIN_METADATA = 'reisenMetadata.setEditingReiseterminMetadata';
export const UPDATE_REISE_REISETERMIN_METADATA_FORM = 'reisenMetadata.updateReiseReiseterminMetadataForm';
export const ABORT_EDITING_REISETERMIN_METADATA = 'reisenMetadata.abortEditingReiseterminMetadata';

export const SET_ACTIVE_SAISON_GUELTIG_AB = 'reisenMetadata.setActiveSaisonGueltigAb';

const state = () => ({
  isBusy: false,
  fullMetadataHistory: null,
  isInitiallyLoading: true,
  editingMetadata: null,
  editingReiseterminMetadata: null,
  currentlyEditingMetadataField: null,
  currentlyEditingMetadataReiseterminStartdatum: null,
  isAddingMetadata: false,
  editingReiseterminMetadataArray: null,
  activeSaisonGueltigAb: null,
  defaultReiseterminMetadata: {
    overnightOutbound: null,
    overnightInbound: null,
    hotelstartVorLandung: null,
    rueckflugNachHotelende: null,
    hotelnaechte: null,
    kalkPax: null,
    erwartetePax: null,
    airlineFrist: null,
    reiseMitInlandsflug: null,
    einkaufInlandsflug: null,
    passdatenFrist: null,
    isKritischeDestination: null,
    aufpreisBeiVerspaetetenPassdaten: null,
    transferszenario: null,
    skrFlugangebot: null,
    ziellandZielflughaefen: null,
    ziellandAbflughaefen: null,
    ziellandZielflughafenPrio: null,
    ziellandAbflughafenPrio: null,
  },
});

const initialState = { ...state() };

const getters = {};

const actions = {
  async [UPDATE_MULTIPLE_REISETERMIN_METADATA_BY_FIELD](context, { reisekuerzel }) {
    // Set the state to busy
    context.commit(SET_IS_BUSY, true);
    // Destructure state properties
    const { currentlyEditingMetadataReiseterminStartdatum, editingReiseterminMetadata } = context.state;
    let { currentlyEditingMetadataField } = context.state;

    if (currentlyEditingMetadataField === 'ziellandZielflughaefen') {
      editingReiseterminMetadata.metadata.ziellandZielflughaefenIataCodes =
        prepareIataCodes(editingReiseterminMetadata.metadata.ziellandZielflughaefen) || [];
      currentlyEditingMetadataField = 'ziellandZielflughaefenIataCodes';
    } else if (currentlyEditingMetadataField === 'ziellandAbflughaefen') {
      editingReiseterminMetadata.metadata.ziellandAbflughaefenIataCodes =
        prepareIataCodes(editingReiseterminMetadata.metadata.ziellandAbflughaefen) || [];
      currentlyEditingMetadataField = 'ziellandAbflughaefenIataCodes';
    }

    const patchDtosByStartdatum = currentlyEditingMetadataReiseterminStartdatum.reduce((acc, cur) => {
      acc[cur] = {
        [currentlyEditingMetadataField]: editingReiseterminMetadata.metadata[currentlyEditingMetadataField],
      };

      return acc;
    }, {});
    // Update Reise Reisetermin Metadata and handle the response
    try {
      const updatedReiseterminMetadata = await updateReiseReiseterminMetadata(
        reisekuerzel,
        patchDtosByStartdatum
      );
      return updatedReiseterminMetadata;
    } finally {
      // Reset the editing state and set the state to not busy
      context.commit(SET_EDITING_REISETERMIN_METADATA, null);
      context.commit(SET_IS_BUSY, false);
    }
  },
  async [UPDATE_REISE_REISETERMIN_METADATA_FORM](context, { reisekuerzel }) {
    // Set the application state to busy
    context.commit(SET_IS_BUSY, true);

    // Get the current editing reisetermin and its metadata
    const currentEditingReisetermin = { ...context.state.editingReiseterminMetadata };
    const currentEditingReiseterminMetadata = currentEditingReisetermin.metadata;

    // Prepare IATA codes for ziellandZielflughaefen and ziellandAbflughaefen
    const ziellandZielflughaefenIataCodes = prepareIataCodes(
      currentEditingReiseterminMetadata.ziellandZielflughaefen
    );
    const ziellandAbflughaefenIataCodes = prepareIataCodes(
      currentEditingReiseterminMetadata.ziellandAbflughaefen
    );

    // filter currentEditingReiseterminMetadata so that it only contains keys that are also in defaultReiseterminMetadata
    const filteredMetadata = Object.keys(context.state.defaultReiseterminMetadata).reduce((result, key) => {
      if (
        key in currentEditingReiseterminMetadata &&
        key !== 'ziellandZielflughaefen' &&
        key !== 'ziellandAbflughaefen'
      ) {
        result[key] = currentEditingReiseterminMetadata[key];
      }
      return result;
    }, {});

    // Prepare the data to be sent
    const data = {
      [currentEditingReisetermin.startdatum]: {
        ...filteredMetadata,
        ziellandZielflughaefenIataCodes,
        ziellandAbflughaefenIataCodes,
      },
    };

    // Send the update request and handle the response
    return updateReiseReiseterminMetadata(reisekuerzel, data)
      .then(updatedReiseterminMetadata => {
        return updatedReiseterminMetadata;
      })
      .finally(() => {
        // Reset editing reisetermin metadata and set the application state to not busy
        context.commit(SET_EDITING_REISETERMIN_METADATA, null);
        context.commit(SET_IS_BUSY, false);
      });
  },
  async [SEND_REISE_METADATA_FORM](context, { reisekuerzel }) {
    // Set the application state to busy
    context.commit(SET_IS_BUSY, true);

    // Get the current metadata from the state
    const currentMetadata = { ...context.state.editingMetadata };
    // Prepare the data to be sent
    const data = {
      reisekuerzel: reisekuerzel,
      gueltigAb: new Date(currentMetadata.gueltigAb).toISOString(),
      applyChangesForExistingReisetermine: currentMetadata.applyChangesForExistingReisetermine,
      reiseMetadata: {},
    };

    // Add only non-empty arrays for ziellandZielflughaefenIataCodes and ziellandAbflughaefenIataCodes
    if (
      Array.isArray(currentMetadata.ziellandZielflughaefen) &&
      currentMetadata.ziellandZielflughaefen.length > 0
    ) {
      data.reiseMetadata.ziellandZielflughaefenIataCodes = currentMetadata.ziellandZielflughaefen;
    }
    if (
      Array.isArray(currentMetadata.ziellandAbflughaefen) &&
      currentMetadata.ziellandAbflughaefen.length > 0
    ) {
      data.reiseMetadata.ziellandAbflughaefenIataCodes = currentMetadata.ziellandAbflughaefen;
    }

    // Set the rest of the metadata attributes
    const metadataAttributes = Object.keys(context.state.defaultReiseterminMetadata).filter(
      a => a != 'ziellandZielflughaefen' && a != 'ziellandAbflughaefen'
    );

    metadataAttributes.forEach(attr => {
      data.reiseMetadata[attr] = currentMetadata[attr];
    });

    // Choose the appropriate action based on the isAddingMetadata flag
    const action = context.state.isAddingMetadata ? ADD_REISE_METADATA : UPDATE_REISE_METADATA;

    // Dispatch the action and set the application state to not busy when completed
    return context.dispatch(action, { ...data }).finally(() => {
      context.commit(SET_IS_BUSY, false);
    });
  },
  async [ADD_REISE_METADATA](
    context,
    { reisekuerzel, gueltigAb, reiseMetadata, applyChangesForExistingReisetermine }
  ) {
    context.commit(SET_IS_BUSY, true);
    context.dispatch(STOP_ADDING_METADATA);
    return addReiseMetadata(reisekuerzel, gueltigAb, reiseMetadata, applyChangesForExistingReisetermine)
      .then(newMetadata => {
        return context.commit(ADD_METADATA_TO_HISTROY, {
          ...newMetadata,
          ziellandAbflughaefen: newMetadata.ziellandAbflughaefenIataCodes,
          ziellandZielflughaefen: newMetadata.ziellandZielflughaefenIataCodes,
        });
      })
      .finally(() => {
        context.commit(SET_IS_BUSY, false);
      });
  },
  async [UPDATE_REISE_METADATA](
    context,
    { reisekuerzel, gueltigAb, reiseMetadata, applyChangesForExistingReisetermine }
  ) {
    context.commit(SET_IS_BUSY, true);
    context.dispatch(STOP_ADDING_METADATA);
    return updateReiseMetadata(reisekuerzel, gueltigAb, reiseMetadata, applyChangesForExistingReisetermine)
      .then(updatedReiseMetadata => {
        return updatedReiseMetadata;
      })
      .finally(() => {
        context.commit(SET_IS_BUSY, false);
      });
  },
  [START_ADDING_METADATA](context, { metadata: metadata }) {
    if (metadata) {
      context.commit(SET_EDITING_METADATA, {
        ...metadata,
        gueltigAb: new Date(),
        applyChangesForExistingReisetermine: false,
      });
    } else {
      context.commit(SET_EDITING_METADATA, {
        ...context.state.defaultReiseterminMetadata,
        gueltigAb: new Date(),
        applyChangesForExistingReisetermine: false,
      });
    }
    context.commit(SET_IS_ADDING_METADATA, true);
  },
  [STOP_ADDING_METADATA](context) {
    context.commit(SET_EDITING_METADATA, null);
    context.commit(SET_IS_ADDING_METADATA, false);
  },
  [TOGGLE_EDITING_METADATA](context, metadata) {
    context.commit(SET_IS_ADDING_METADATA, false);
    context.commit(SET_EDITING_METADATA, metadata);
  },
  async [DELETE_METADATA](context, { reisekuerzel, gueltigAb }) {
    context.commit(SET_IS_BUSY, true);
    return deleteReiseMetadata(reisekuerzel, gueltigAb)
      .then(() => {
        const splicedMetadataHistory = context.state.fullMetadataHistory.filter(
          m => m.gueltigAb != gueltigAb
        );
        context.commit(SET_FULL_METADATA_HISTORY, {
          fullMetadataHistory: splicedMetadataHistory,
          shouldTransformFlughaefen: false,
        });
      })
      .finally(() => {
        context.commit(SET_IS_BUSY, false);
      });
  },
  [TOGGLE_EDITING_MULTIPLE_REISETERMIN_METADATA_BY_FIELD](context, { startdatum, field }) {
    context.commit(SET_CURRENTLY_EDITING_METADATA_FIELD, field);
    context.commit(SET_CURRENTLY_EDITING_METADATA_REISETERMIN_STARTDATUM, startdatum);

    context.commit(SET_EDITING_REISETERMIN_METADATA, {
      metadata: { ...context.state.defaultReiseterminMetadata },
    });
  },
  [TOGGLE_EDITING_REISETERMIN_METADATA](context, reisetermin) {
    if (reisetermin.metadata === null) {
      reisetermin.metadata = { ...context.state.defaultReiseterminMetadata };
    }
    const ziellandZielflughaefen =
      reisetermin.metadata.ziellandZielflughaefen.map(fh => fh.flughafenIataCode) || [];
    const ziellandAbflughaefen =
      reisetermin.metadata.ziellandAbflughaefen.map(fh => fh.flughafenIataCode) || [];

    const ziellandAbflughafenPrio = reisetermin.metadata.ziellandAbflughaefen.find(
      flughafen => flughafen.isPrio
    )?.flughafenIataCode;
    const ziellandZielflughafenPrio = reisetermin.metadata.ziellandZielflughaefen.find(
      flughafen => flughafen.isPrio
    )?.flughafenIataCode;
    context.commit(SET_EDITING_REISETERMIN_METADATA, {
      ...reisetermin,
      metadata: {
        ...reisetermin.metadata,
        ziellandAbflughaefen,
        ziellandZielflughaefen,
        ziellandAbflughafenPrio,
        ziellandZielflughafenPrio,
      },
    });
  },
  [ABORT_EDITING_REISETERMIN_METADATA](context) {
    context.commit(SET_EDITING_REISETERMIN_METADATA, null);
    context.commit(SET_CURRENTLY_EDITING_METADATA_REISETERMIN_STARTDATUM, null);
    context.commit(SET_CURRENTLY_EDITING_METADATA_FIELD, null);
  },
};

const mutations = {
  [SET_ACTIVE_SAISON_GUELTIG_AB](state, saisonId) {
    state.activeSaisonGueltigAb = saisonId;
  },
  [SET_CURRENTLY_EDITING_METADATA_REISETERMIN_STARTDATUM](state, startdatum) {
    state.currentlyEditingMetadataReiseterminStartdatum = startdatum;
  },
  [SET_CURRENTLY_EDITING_METADATA_FIELD](state, field) {
    state.currentlyEditingMetadataField = field;
  },
  [ADD_METADATA_TO_HISTROY](state, metadata) {
    state.fullMetadataHistory.push(metadata);
  },
  [SET_FULL_METADATA_HISTORY](state, { fullMetadataHistory, shouldTransformFlughaefen }) {
    state.fullMetadataHistory = fullMetadataHistory.map(metadata => {
      const { ziellandAbflughaefen, ziellandZielflughaefen } = metadata;
      if (shouldTransformFlughaefen) {
        metadata.ziellandAbflughaefen = ziellandAbflughaefen.map(flughafen => flughafen.flughafenIataCode);
        metadata.ziellandZielflughaefen = ziellandZielflughaefen.map(
          flughafen => flughafen.flughafenIataCode
        );
        metadata.ziellandAbflughafenPrio = ziellandAbflughaefen.find(
          flughafen => flughafen.isPrio
        )?.flughafenIataCode;
        metadata.ziellandZielflughafenPrio = ziellandZielflughaefen.find(
          flughafen => flughafen.isPrio
        )?.flughafenIataCode;
        console.log(metadata, 'METADATA');
      }
      return metadata;
    });
  },
  [SET_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },
  [SET_EDITING_REISETERMIN_METADATA](state, editingReiseterminMetadata) {
    state.editingReiseterminMetadata = editingReiseterminMetadata;
  },
  [SET_EDITING_METADATA](state, editingMetadata) {
    state.editingMetadata = editingMetadata;
  },
  [SET_IS_ADDING_METADATA](state, isAddingMetadata) {
    state.isAddingMetadata = isAddingMetadata;
  },
};

function prepareIataCodes(flughafenList) {
  return flughafenList.some(f => f?.id) ? flughafenList.map(f => f.flughafenIataCode) : flughafenList;
}

function updateReiseMetadata(reisekuerzel, gueltigAb, reiseMetadata, applyChangesForExistingReisetermine) {
  return apiService
    .put(`Reise/${reisekuerzel}/metadata/${gueltigAb}`, reiseMetadata, {
      applyChangesForExistingReisetermine: applyChangesForExistingReisetermine,
    })
    .then(response => {
      if (!response.data.result.isSuccess) {
        throw new Error(response.data.value.errors);
      } else {
        return response.data.result.value;
      }
    });
}

function deleteReiseMetadata(reisekuerzel, gueltigAb) {
  return apiService.delete(`Reise/${reisekuerzel}/metadata/${gueltigAb}`).then(response => {
    if (!response.data.result.isSuccess) {
      throw new Error(response.data.value.errors);
    } else {
      return response.data.result.value;
    }
  });
}

function updateReiseReiseterminMetadata(reisekuerzel, patchDtosByStartdatum) {
  return apiService
    .put(`Reise/${reisekuerzel}/Reisetermine/metadata`, patchDtosByStartdatum)
    .then(response => {
      if (!response.data.result.isSuccess) {
        throw new Error(response.data.value.errors);
      } else {
        return response.data.result.value;
      }
    });
}

function addReiseMetadata(reisekuerzel, gueltigAb, reiseMetadata, applyChangesForExistingReisetermine) {
  return apiService
    .post(`Reise/${reisekuerzel}/metadata/${gueltigAb}`, reiseMetadata, {
      applyChangesForExistingReisetermine: applyChangesForExistingReisetermine,
    })
    .then(response => {
      if (!response.data.result.isSuccess) {
        throw new Error(response.data.value.errors);
      } else {
        return response.data.result.value;
      }
    });
}

export default {
  state,
  actions,
  mutations,
  getters,
};
