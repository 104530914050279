export const VISITED_REISETERMIN = 'userSettingsLocal.vistedReisetermin';
export const SET_LAST_VISITED_REISETERMIN_ID = 'userSettingsLocal.setLastVisitedReiseterminId';
export const SET_LAST_VISITED_REISETERMIN_KUERZEL = 'userSettingsLocal.setLastVisitedReiseterminKuerzel';

export const SET_ALWAYS_HIDE_REISETERMIN_COMPACT_HEADER =
  'userSettingsLocal.setAlwaysHideReiseterminCompactHeader';

export const SET_CUSTOM_ROLES = 'userSettingsLocal.setCustomRoles';
export const SET_HIDE_ROLE_INDICATORS = 'userSettingsLocal.setHideRoleIndicators';
export const SET_IS_USING_CUSTOM_ROLES = 'userSettingsLocal.setIsUsingCustomRoles';

export const SET_IS_ANIMATION_ENABLED = 'userSettingsLocal.setIsAnimationEnabled';

const state = {
  lastVisitedReiseterminId: null,
  lastVisitedReiseterminKuerzel: null,
  alwaysHideReiseterminCompactHeader: false,
  hideRoleIndicators: false,
  isAnimationEnabled: false,
  isUsingCustomRoles: false,
  customRoles: [],
};
const actions = {
  [VISITED_REISETERMIN](context, { id, kuerzel }) {
    context.commit(SET_LAST_VISITED_REISETERMIN_ID, id);
    context.commit(SET_LAST_VISITED_REISETERMIN_KUERZEL, kuerzel);
  },
};
const mutations = {
  [SET_IS_ANIMATION_ENABLED](state, isAnimationEnabled) {
    state.isAnimationEnabled = isAnimationEnabled;
  },
  [SET_LAST_VISITED_REISETERMIN_ID](state, id) {
    state.lastVisitedReiseterminId = id;
  },
  [SET_LAST_VISITED_REISETERMIN_KUERZEL](state, kuerzel) {
    state.lastVisitedReiseterminKuerzel = kuerzel;
  },
  [SET_ALWAYS_HIDE_REISETERMIN_COMPACT_HEADER](state, alwaysHideReiseterminCompactHeader) {
    state.alwaysHideReiseterminCompactHeader = alwaysHideReiseterminCompactHeader;
  },
  [SET_CUSTOM_ROLES](state, customRoles) {
    state.customRoles = customRoles;
  },
  [SET_IS_USING_CUSTOM_ROLES](state, isUsingCustomRoles) {
    state.isUsingCustomRoles = isUsingCustomRoles;
  },
  [SET_HIDE_ROLE_INDICATORS](state, hideRoleIndicators) {
    state.hideRoleIndicators = hideRoleIndicators;
  },
};
const getters = {
  customRoles(state) {
    return Array.isArray(state.customRoles) && state.customRoles.length > 0
      ? state.customRoles.map(role => role.key)
      : null;
  },
  lastVisitedReiseterminId(state) {
    return state.lastVisitedReiseterminId;
  },
  lastVisitedReiseterminKuerzel(state) {
    return state.lastVisitedReiseterminKuerzel;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
