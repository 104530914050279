import { getAgenturIdFilterOptions } from '@/core/common/services/odata.service';
import { isIdFilterRefreshable } from '@/core/common/helpers/utils';
import dublettenStateFilter from '@/core/common/dubletten-state-filter.config';

export const GET_AGENTUR_DUPLICATES = 'agenturDuplicates.getAgentur';
export const SET_AGENTUR_DUPLICATES = 'agenturDuplicates.setAgentur';

export const SAVE_AGENTUR_DUPLICATES_MERGE_STATE = 'agenturDuplicates.saveAgenturDuplicatesMergeState';
export const CREATE_AGENTUR_DUPLICATE_AND_ACCEPT = 'agenturDuplicates.createAgenturDuplicateAndAccept';

export const AGENTUR_DUPLICATES_SORT_CHANGED = 'agenturDuplicates.sortChanged';
export const AGENTUR_DUPLICATES_PAGE_CHANGED = 'agenturDuplicates.pageChanged';

export const SET_AGENTUR_DUPLICATES_COUNT = 'agenturDuplicates.setAgenturCount';
export const SET_AGENTUR_DUPLICATES_TOP = 'agenturDuplicates.setAgenturTop';
export const SET_AGENTUR_DUPLICATES_SKIP = 'agenturDuplicates.setAgenturSkip';
export const SET_AGENTUR_DUPLICATES_ORDER_BY = 'agenturDuplicates.setAgenturOrderBy';
export const SET_AGENTUR_DUPLICATES_IS_BUSY = 'agenturDuplicates.setAgenturIsBusy';

export const SET_ALL_FILTER_TO_INTIAL = 'agenturDuplicates.resetAllFilter';
export const RESET_FILTER = 'agenturDuplicates.setAllFilterNull';

export const SET_AGENTUR_DUPLICATES_IS_ANY_FILTER_APPLIED = 'agenturDuplicates.setAgenturisAnyFilterApplied';
export const SET_AGENTUR_DUPLICATES_IS_CURRENT_FILTER_APPLIED =
  'agenturDuplicates.setAgenturIsCurrentFilterApplied';

export const SET_AGENTUR_DUPLICATES_ID_FILTER = 'agenturDuplicates.setAgenturIdFilter';
export const RESET_AGENTUR_DUPLICATES_ID_FILTER = 'agenturDuplicates.resetAgenturIdFilter';
export const GET_AGENTUR_DUPLICATES_ID_FILTER_OPTIONS = 'agenturDuplicates.getAgenturIdFilterOptions';
export const SET_AGENTUR_DUPLICATES_ID_FILTER_OPTIONS = 'agenturDuplicates.setAgenturIdFilterOptions';
export const SET_AGENTUR_DUPLICATES_STATE_FILTER = 'agenturDuplicates.setAgenturStateFilter';
export const RESET_AGENTUR_DUPLICATES_STATE_FILTER = 'agenturDuplicates.resetAgenturStateFilter';

export const SET_CONFIRMATION_MODAL_PAIR_AND_TRIGGER = 'agenturDuplicates.setConfirmationModalPairAndTrigger';

import { convertTableSortToOdata } from '@/core/common/helpers/odataUtils';

import apiService from '@/core/common/services/api.service';
import * as odataService from '@/core/common/services/odata.service';

const state = () => ({
  agenturDuplicates: null,
  appliedAgenturFilter: null,
  currentAgenturFilter: null,
  agenturIdFilterOptions: [],
  confirmationModalPairAndTrigger: null,
  top: 10,
  skip: 0,
  orderBy: ['updatedDateUtc desc'],
  count: 0,
  isCurrentFilterApplied: true,
  isAnyFilterApplied: false,
  filter: {
    isDeleted: false,
    idFilter: [],
    duplicateState: 'new',
  },
  expand: {
    Agentur1: {},
    Agentur2: {},
  },
  isBusy: true,
});
const initialState = { ...state() };

const getters = {
  areSomeAgenturFilterSet(state) {
    return Object.values(state.filter).some(val => val !== false);
  },
};

const actions = {
  [GET_AGENTUR_DUPLICATES](context) {
    context.commit(SET_AGENTUR_DUPLICATES_IS_BUSY, true);
    return getAgenturDuplicates(
      getQueryArrayFromFilterObject(context.state.filter),
      context.state.expand,
      context.state.top,
      context.state.skip,
      context.state.orderBy
    ).then(response => {
      context.commit(SET_AGENTUR_DUPLICATES_IS_CURRENT_FILTER_APPLIED, true);

      context.commit(
        SET_AGENTUR_DUPLICATES_IS_ANY_FILTER_APPLIED,
        compareCurrentFilterWithInitial(context.state.filter, initialState.filter)
      );
      context.commit(SET_AGENTUR_DUPLICATES, response.data || []);
      context.commit(SET_AGENTUR_DUPLICATES_COUNT, response.count || 0);
      context.commit(SET_AGENTUR_DUPLICATES_IS_BUSY, false);
    });
  },
  [AGENTUR_DUPLICATES_SORT_CHANGED](context, { sortContext: sortContext }) {
    if (sortContext.sortBy === 'agenturen') {
      sortContext.sortBy = 'agenturen/$count';
    }
    const orderBy = convertTableSortToOdata(sortContext.sortBy, sortContext.sortDesc);
    context.commit(SET_AGENTUR_DUPLICATES_ORDER_BY, orderBy);
    context.commit(SET_AGENTUR_DUPLICATES_SKIP, 0);
    return context.dispatch(GET_AGENTUR_DUPLICATES);
  },
  [AGENTUR_DUPLICATES_PAGE_CHANGED](context, { pageContext: pageContext }) {
    context.commit(SET_AGENTUR_DUPLICATES_SKIP, pageContext.skip);
    context.commit(SET_AGENTUR_DUPLICATES_TOP, pageContext.top);
    return context.dispatch(GET_AGENTUR_DUPLICATES);
  },
  [GET_AGENTUR_DUPLICATES_ID_FILTER_OPTIONS](context, { idFilter }) {
    const isRefreshable = isIdFilterRefreshable(idFilter, context.state.agenturIdFilterOptions, 3);
    return isRefreshable
      ? getAgenturIdFilterOptions(idFilter).then(options => {
          context.commit(SET_AGENTUR_DUPLICATES_ID_FILTER_OPTIONS, options);
        })
      : context.commit(SET_AGENTUR_DUPLICATES_ID_FILTER_OPTIONS, []);
  },
  [RESET_FILTER](context) {
    context.commit(SET_AGENTUR_DUPLICATES_IS_ANY_FILTER_APPLIED, true);
    context.commit(SET_AGENTUR_DUPLICATES_IS_CURRENT_FILTER_APPLIED, true);
    context.commit(SET_ALL_FILTER_TO_INTIAL);
    return context.dispatch(GET_AGENTUR_DUPLICATES);
  },
  [SAVE_AGENTUR_DUPLICATES_MERGE_STATE](
    context,
    { agentur1Id: agentur1Id, agentur2Id: agentur2Id, trigger: trigger }
  ) {
    return sendAgenturDublettenTrigger(agentur1Id, agentur2Id, trigger).then(() => {
      const currentAgenturDuplicates = context.state.agenturDuplicates;
      const agenturDuplicateIndex = currentAgenturDuplicates.findIndex(
        duplicate => duplicate.agentur1.id === agentur1Id && duplicate.agentur2.id === agentur2Id
      );
      currentAgenturDuplicates[agenturDuplicateIndex] = {
        ...currentAgenturDuplicates[agenturDuplicateIndex],
        hidden: true,
      };
      context.commit(SET_AGENTUR_DUPLICATES, currentAgenturDuplicates);
      return setTimeout(() => {
        context.commit(SET_CONFIRMATION_MODAL_PAIR_AND_TRIGGER, null);
        return context.dispatch(GET_AGENTUR_DUPLICATES);
      }, 400);
    });
  },
  [CREATE_AGENTUR_DUPLICATE_AND_ACCEPT](context, { agentur1Id: agentur1Id, agentur2Id: agentur2Id }) {
    return createAgenturDuplicateAndAccept(agentur1Id, agentur2Id).then(() => {
      return context.dispatch(GET_AGENTUR_DUPLICATES);
    });
  },
};

const mutations = {
  // set general data und table mutations
  [SET_AGENTUR_DUPLICATES](state, agenturDuplicates) {
    state.agenturDuplicates = [...agenturDuplicates];
  },
  [SET_AGENTUR_DUPLICATES_COUNT](state, count) {
    state.count = count;
  },
  [SET_AGENTUR_DUPLICATES_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },
  [SET_AGENTUR_DUPLICATES_ORDER_BY](state, orderBy) {
    state.orderBy = orderBy;
  },
  [SET_AGENTUR_DUPLICATES_SKIP](state, skip) {
    state.skip = skip;
  },
  [SET_AGENTUR_DUPLICATES_TOP](state, top) {
    state.top = top;
  },
  [SET_CONFIRMATION_MODAL_PAIR_AND_TRIGGER](state, pair) {
    state.confirmationModalPairAndTrigger = pair;
  },
  // Filter mutations
  [SET_AGENTUR_DUPLICATES_IS_ANY_FILTER_APPLIED](state, isAnyFilterApplied) {
    state.isAnyFilterApplied = isAnyFilterApplied;
  },
  [SET_AGENTUR_DUPLICATES_IS_CURRENT_FILTER_APPLIED](state, isCurrentFilterApplied) {
    state.isCurrentFilterApplied = isCurrentFilterApplied;
  },
  [SET_AGENTUR_DUPLICATES_STATE_FILTER](state, duplicateState) {
    state.filter.duplicateState = duplicateState;
  },
  [RESET_AGENTUR_DUPLICATES_STATE_FILTER](state) {
    state.filter.duplicateState = 'new';
  },
  // Base State
  [SET_AGENTUR_DUPLICATES_ID_FILTER_OPTIONS](state, options) {
    state.agenturIdFilterOptions = options;
  },
  [SET_AGENTUR_DUPLICATES_ID_FILTER](state, idFilter) {
    state.filter.idFilter = idFilter;
  },
  [RESET_AGENTUR_DUPLICATES_ID_FILTER](state) {
    state.filter.idFilter = [];
  },
  // General mutations
  [SET_ALL_FILTER_TO_INTIAL](state) {
    state.filter.idFilter = initialState.filter.idFilter;
    state.filter.duplicateState = initialState.filter.duplicateState;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

function getQueryArrayFromFilterObject(filterObject) {
  if (!filterObject) return [];
  const idFilter = buildAgenturFilterQuery(filterObject.idFilter);
  const agenturDefaultFilter = getAgenturDefaultFilter(); //General filter, is always set
  const duplicateStateFilter = dublettenStateFilter[filterObject.duplicateState];

  const atleastOneAgenturFilter = filterObject.atleastOneAgenturFilter
    ? { agenturen: { $count: { gt: 1 } } }
    : null;

  return [atleastOneAgenturFilter, idFilter, agenturDefaultFilter, duplicateStateFilter];
}

function getAgenturDuplicates(filter, expand, top, skip, orderBy) {
  return odataService
    .getAgenturDuplicates({
      filter: filter,
      expand: expand,
      top: top,
      skip: skip,
      orderBy: orderBy,
      count: true,
    })
    .then(response => {
      return response.data ? parseAgenturDuplicatesValuesAndAddDiff(response) : [];
    })
    .catch(error => {
      console.log(error);
    });
}

function createAgenturDuplicateAndAccept(agentur1Id, agentur2Id) {
  return apiService
    .post(`Agentur/AgenturDuplicates?agentur1Id=${agentur1Id}&agentur2Id=${agentur2Id}`)
    .then(data => {
      return data;
    })
    .catch(err => {
      throw err;
    });
}

function sendAgenturDublettenTrigger(agentur1Id, agentur2Id, trigger) {
  return apiService
    .put(`Agentur/AgenturDuplicates`, {
      agentur1Id: agentur1Id,
      agentur2Id: agentur2Id,
      trigger: trigger,
    })
    .then(data => {
      return data;
    })
    .catch(err => {
      throw err;
    });
}

function getAgenturDefaultFilter() {
  return null;
}

function buildAgenturFilterQuery(agentur) {
  if (agentur && agentur.length > 0) {
    return {
      or: [
        { agentur1Id: { in: agentur.map(agentur => agentur.id) || null } },
        { agentur2Id: { in: agentur.map(agentur => agentur.id) || null } },
      ],
    };
  } else {
    return null;
  }
}

function compareCurrentFilterWithInitial(currentFilter, initalFilter) {
  return JSON.stringify(currentFilter) !== JSON.stringify(initalFilter);
}

function parseAgenturDuplicatesValuesAndAddDiff(response) {
  const agenturDuplicates = response.data;
  return {
    count: response.count,
    data: agenturDuplicates.map(agenturDuplicate => {
      const agenturAgentur1 = agenturDuplicate.agentur1;
      const agenturAgentur2 = agenturDuplicate.agentur2;
      if (agenturAgentur2 && agenturAgentur1) {
        if (agenturAgentur1.name && agenturAgentur2.name) {
          agenturAgentur1.nameDiff = agenturAgentur2.name !== agenturAgentur1.name;
        }
        if (agenturAgentur1.email && agenturAgentur2.email) {
          agenturAgentur1.emailDiff = agenturAgentur2.email !== agenturAgentur1.email;
        }
        if (agenturAgentur1.strasse && agenturAgentur2.strasse) {
          agenturAgentur1.strasseDiff = agenturAgentur2.strasse !== agenturAgentur1.strasse;
        }
        if (agenturAgentur1.ort && agenturAgentur2.ort) {
          agenturAgentur1.ortDiff = agenturAgentur2.ort !== agenturAgentur1.ort;
        }
        if (agenturAgentur1.plz && agenturAgentur2.plz) {
          agenturAgentur1.plzDiff = agenturAgentur2.plz !== agenturAgentur1.plz;
        }
        if (agenturAgentur1.telefon && agenturAgentur2.telefon) {
          agenturAgentur1.telefonDiff = agenturAgentur2.telefon !== agenturAgentur1.telefon;
        }

        agenturAgentur1.nameMatch = agenturDuplicate.resultCodes.includes('MS06');
        agenturAgentur1.emailMatch = agenturDuplicate.resultCodes.includes('MS07');
        agenturAgentur1.strasseMatch = agenturDuplicate.resultCodes.includes('MS06');
        agenturAgentur1.ortMatch = agenturDuplicate.resultCodes.includes('MS06');
        agenturAgentur1.plzMatch = agenturDuplicate.resultCodes.includes('MS06');
        agenturAgentur1.telefonMatch = agenturDuplicate.resultCodes.includes('MS08');

        if (agenturAgentur1.steuernummer && agenturAgentur2.steuernummer) {
          agenturAgentur1.steuernummerDiff = agenturAgentur2.steuernummer !== agenturAgentur1.steuernummer;
          agenturAgentur1.steuernummerMatch = false;
        }
        return { ...agenturDuplicate };
      }
    }),
  };
}
