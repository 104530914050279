import apiService from '@/core/common/services/api.service';
import errorHandler from '@/core/common/services/error-handler.service';

export function getReseterminSearchSortExpressionQuery(sortExpressions) {
  const sortExpressionQuery = new URLSearchParams();
  sortExpressions.forEach(sortExpression => {
    sortExpressionQuery.append(
      'SortExpressions',
      `${sortExpression.key} ${sortExpression.desc ? 'DESC' : 'ASC'}`
    );
  });
  return sortExpressionQuery;
}

export function getReiseterminSearchResultQuery(
  bearbeiter,
  states,
  pax,
  garantiert,
  reisen,
  regions,
  countries,
  startdatum,
  releasedatum,
  abreisedatum,
  umsatz,
  frist,
  vorgangBlankStates,
  reiseterminKuerzel,
  roles,
  sechserhuelsen,
  hasVorgaengeWithFlugticketsAusgestellt,
  istAusgeblendet,
  istPending,
  istErledigt
) {
  const filter = [
    ...((bearbeiter &&
      bearbeiter.length > 0 &&
      bearbeiter.map(bearbeiter => ['Bearbeiter', bearbeiter.id])) ||
      []),

    ...((states && states.length > 0 && states.map(state => ['States', state.state])) || []),

    ...((vorgangBlankStates &&
      vorgangBlankStates.length > 0 &&
      vorgangBlankStates.map(state => ['vorgangBlankStates', state.state])) ||
      []),

    ...((pax && pax.length > 0 && pax.map(pax => ['Pax', pax.label])) || []),

    ...((reiseterminKuerzel &&
      reiseterminKuerzel.length > 0 &&
      reiseterminKuerzel.map(kuerzel => ['ReiseterminKuerzel', kuerzel.kuerzelLabel])) ||
      []),

    ...((reisen && reisen.length > 0 && reisen.map(reise => ['ReiseterminKuerzel', reise.reisekuerzel])) ||
      []),

    ...((regions && regions.length > 0 && regions.map(region => ['Regions', region.value])) || []),
    ...((countries && countries.length > 0 && countries.map(countries => ['Countries', countries.value])) ||
      []),

    ...((startdatum && startdatum.start && [['StartdatumFrom', startdatum.start]]) || []),
    ...((startdatum && startdatum.end && [['StartdatumUntil', startdatum.end]]) || []),

    ...((releasedatum && releasedatum.start && [['ReleaseDatumFrom', releasedatum.start]]) || []),
    ...((releasedatum && releasedatum.end && [['ReleaseDatumUntil', releasedatum.end]]) || []),

    ...((abreisedatum && abreisedatum.start && [['AbreisedatumFrom', abreisedatum.start]]) || []),
    ...((abreisedatum && abreisedatum.end && [['AbreisedatumUntil', abreisedatum.end]]) || []),

    ...((umsatz && umsatz.min && [['UmsatzMin', umsatz.min]]) || []),
    ...((umsatz && umsatz.max && [['UmsatzMax', umsatz.max]]) || []),

    ...((frist && Array.isArray(frist) && frist.length > 0 && [['FristMin', frist[0]]]) || []),
    ...((frist && Array.isArray(frist) && frist.length > 0 && [['FristMax', frist[1]]]) || []),

    ...((garantiert && garantiert?.label == 'Garantiert' && [['Garantiert', true]]) || []),

    ...((roles && roles.length > 0 && roles.map(role => ['Roles', role.role])) || []),

    ...((sechserhuelsen && [['FlugstammPrefixes', '6']]) || []),

    ...((hasVorgaengeWithFlugticketsAusgestellt && [['HasOneVorgangWithFlugticketsAusgestellt', false]]) ||
      []),

    ...((istAusgeblendet && [['istAusgeblendet', true]]) || []),
    ...((istPending && [['istPending', true]]) || []),
    ...((istErledigt && [['istErledigt', true]]) || []),
  ];
  const filterURLSearchParams = new URLSearchParams(filter);

  return filterURLSearchParams;
}

export async function getReiseterminSearchResult(
  filterURLSearchParams,
  skip,
  count,
  sortExpressionsURLSearchParams,
  endPoint = 'Reisetermin/search'
) {
  if (skip) {
    filterURLSearchParams.append('Skip', skip);
  }
  if (count) {
    filterURLSearchParams.append('Count', count);
  }
  if (sortExpressionsURLSearchParams) {
    for (let [key, val] of sortExpressionsURLSearchParams.entries()) {
      filterURLSearchParams.append(key, val);
    }
  }

  return apiService
    .get(`${endPoint}?${filterURLSearchParams.toString()}`)
    .then(res => {
      return res.data && res.data.result;
    })
    .catch(errorHandler);
}
