import { getKundenIdFilterOptions } from '@/core/common/services/odata.service';
import { isIdFilterRefreshable } from '@/core/common/helpers/utils';
import dublettenConfirmationStateFilter from '@/core/common/dubletten-state-filter.config';

export const SET_APPLIED_FILTER =
  'kundeDublettenConfirmationFilter.setAppliedKundeDublettenConfirmationFilter';

export const SET_ALL_FILTER_TO_INTIAL = 'kundeDublettenConfirmationFilter.resetAllFilter';

export const SET_KUNDEN_ID_FILTER = 'kundeDublettenConfirmationFilter.setKundenIdFilter';
export const RESET_KUNDEN_ID_FILTER = 'kundeDublettenConfirmationFilter.resetKundenIdFilter';
export const GET_KUNDEN_ID_FILTER_OPTIONS = 'kundeDublettenConfirmationFilter.getKundenIdFilterOptions';
export const SET_KUNDEN_ID_FILTER_OPTIONS = 'kundeDublettenConfirmationFilter.setKundenIdFilterOptions';

export const SET_DUPLIKAT_STATE_FILTER = 'kundeDublettenConfirmationFilter.setDuplikatStateFilter';

export const SET_KUNDEN_CONFRIMATION_IS_LOADING =
  'kundeDublettenConfirmationFilter.setKundenConfirmationIsLoading';

const state = () => ({
  kundenIdFilterOptions: [],
  isLoading: false,
  expand: {
    DuplicateKunde: {
      expand: {
        Name: {},
        Adresse: { expand: { Strasse: {}, Postleitzahl: {}, Ort: {}, Land: {} } },
        TelefonPrivat: {},
        TelefonGeschaeftlich: {},
        TelefonMobil: {},
        Master: {},
      },
    },
    Kunde: {
      expand: {
        Name: {},
        Adresse: { expand: { Strasse: {}, Postleitzahl: {}, Ort: {}, Land: {} } },
        TelefonPrivat: {},
        TelefonGeschaeftlich: {},
        TelefonMobil: {},
        Master: {},
      },
    },
  },
  filter: {
    kundenIdFilter: [],
    duplikatStateFilter: 'new',
  },
  appliedKundeDublettenConfirmationFilter: {
    kundenIdFilter: [],
    duplikatStateFilter: 'new',
  },
});
const initialState = { ...state() };

const getters = {
  kundeDublettenConfirmationFilterArray(state) {
    return getQueryArrayFromFilterObject(state.filter);
  },
  isKundeDublettenConfirmationFilterResettable(state) {
    const initalStateFilter = { ...initialState.filter };
    const appliedKundeDublettenConfirmationFilter = { ...state.appliedKundeDublettenConfirmationFilter };
    return appliedKundeDublettenConfirmationFilter
      ? JSON.stringify(initalStateFilter) !== JSON.stringify(appliedKundeDublettenConfirmationFilter)
      : false;
  },
  areAppliedKundeDublettenConfirmationFiltersEqualsSetFilters(state) {
    const currentStateFilter = { ...state.filter };
    const appliedKundeDublettenConfirmationFilter = { ...state.appliedKundeDublettenConfirmationFilter };
    return JSON.stringify(currentStateFilter) === JSON.stringify(appliedKundeDublettenConfirmationFilter);
  },
  kundeDublettenConfirmationFiltersObject(state) {
    return state.filter;
  },
  areSomeKundeDublettenConfirmationFilterSet(state) {
    return Object.values(state.filter).some(val => val !== false);
  },
};

const actions = {
  [GET_KUNDEN_ID_FILTER_OPTIONS](context, { idFilter }) {
    const isRefreshable = isIdFilterRefreshable(idFilter, context.state.kundenIdFilterOptions, 3);
    return isRefreshable
      ? getKundenIdFilterOptions(idFilter).then(options => {
          context.commit(SET_KUNDEN_ID_FILTER_OPTIONS, options);
        })
      : context.commit(SET_KUNDEN_ID_FILTER_OPTIONS, []);
  },
};

const mutations = {
  [SET_KUNDEN_CONFRIMATION_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_DUPLIKAT_STATE_FILTER](state, dublettenStateFilter) {
    state.filter.duplikatStateFilter = dublettenStateFilter;
  },
  [SET_KUNDEN_ID_FILTER_OPTIONS](state, options) {
    state.kundenIdFilterOptions = options;
  },
  [SET_KUNDEN_ID_FILTER](state, filter) {
    state.filter.kundenIdFilter = filter;
  },
  [RESET_KUNDEN_ID_FILTER](state) {
    state.filter.kundenIdFilter = [];
  },
  // General mutations
  [SET_APPLIED_FILTER](state, appliedKundeDublettenConfirmationFilter) {
    state.appliedKundeDublettenConfirmationFilter = { ...appliedKundeDublettenConfirmationFilter };
  },
  [SET_ALL_FILTER_TO_INTIAL](state) {
    state.filter.kundenIdFilter = initialState.filter.kundenIdFilter;
    state.filter.duplikatStateFilter = initialState.filter.duplikatStateFilter;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

function getQueryArrayFromFilterObject(filterObject) {
  const duplikatStateFilter = dublettenConfirmationStateFilter[filterObject.duplikatStateFilter];

  if (!filterObject) return [duplikatStateFilter];
  const kundenIdFilter = buildKundenFilterQuery(filterObject.kundenIdFilter);

  return [kundenIdFilter, duplikatStateFilter];
}

function buildKundenFilterQuery(kunden) {
  if (kunden && kunden.length > 0) {
    return {
      or: [
        { kundeId: { in: kunden.map(kunde => kunde.id) || null } },
        { duplicateKundeId: { in: kunden.map(kunde => kunde.id) || null } },
      ],
    };
  } else {
    return null;
  }
}
