export const ursachenPossibilities = [
  {
    status: 'NichtVerfuegbar',
    options: [
      { label: '6er Hülse fehlt / Fehler', enumValue: 1, enum: 'Huelse' },
      { label: 'Fehler GRP-FS', enumValue: 4, enum: 'GruppenFS' },
      { label: 'Fehler HH-FS', enumValue: 6, enum: 'HiHiFS' },
      { label: 'Fehler HS', enumValue: 7, enum: 'Hotelstamm' },
      { label: 'Sonstiges', enumValue: 11, enum: 'Sonstiges' },
      { label: 'Tarifkombi nicht möglich', enumValue: 16, enum: 'TarifKombi' },
      { label: 'Technische Störung', enumValue: 17, enum: 'TechnischeStoerung' },
    ],
  },
  {
    status: 'Verfuegbar',
    options: [
      { label: 'Flugpreis höher als TH/keine Alternative', enumValue: 3, enum: 'FlugpreisHoeher' },
      { label: 'Fehler GRP-FS', enumValue: 4, enum: 'GruppenFS' },
      { label: 'Fehler HH-FS', enumValue: 6, enum: 'HiHiFS' },
      { label: 'Keine TO-Tarife in AMA/NDC geladen', enumValue: 8, enum: 'KeineTarife' },
      { label: 'RE', enumValue: 10, enum: 'RE' },
      { label: 'Sonstiges', enumValue: 11, enum: 'Sonstiges' },
      { label: 'Tagesaktuelle Verfügbarkeit', enumValue: 12, enum: 'TagesaktuelleVerfuegbarkeit' },
      { label: 'Timeslot', enumValue: 13, enum: 'Timeslot' },
      { label: 'Zeitrestriktion', enumValue: 15, enum: 'Zeitrestriktion' },
      {
        label: 'Toma sortiert nach Flugpreis nicht nach Gesamtpreis',
        enumValue: 14,
        enum: 'TomaSortiertNichtNachGesamtpreis',
      },
      { label: 'Tarifkombi nicht möglich', enumValue: 16, enum: 'TarifKombi' },
    ],
  },
  {
    status: 'AufAnfrage',
    options: [
      { label: 'Flüge nicht verfügbar', enumValue: 2, enum: 'FluegeNichtVerfuegbar' },
      { label: 'Fehler GRP-FS', enumValue: 4, enum: 'GruppenFS' },
      { label: 'HiHi Response zu groß', enumValue: 5, enum: 'HHResponse' },
      { label: 'Fehler HH-FS', enumValue: 6, enum: 'HiHiFS' },
      { label: 'Keine TO-Tarife in AMA/NDC geladen', enumValue: 8, enum: 'KeineTarife' },
      { label: 'Mehrere FS pro Tag /Blank', enumValue: 9, enum: 'Flugstaemme' },
      { label: 'RE', enumValue: 10, enum: 'RE' },
      { label: 'Sonstiges', enumValue: 11, enum: 'Sonstiges' },
      { label: 'Tagesaktuelle Verfügbarkeit', enumValue: 12, enum: 'TagesaktuelleVerfuegbarkeit' },
      { label: 'Timeslot', enumValue: 13, enum: 'Timeslot' },
      { label: 'Zeitrestriktion', enumValue: 15, enum: 'Zeitrestriktion' },
      { label: 'Tarifkombi nicht möglich', enumValue: 16, enum: 'TarifKombi' },
      { label: 'Technische Störung', enumValue: 17, enum: 'TechnischeStoerung' },
    ],
  },
];

export const allUrsachen = ursachenPossibilities
  .map(item => item.options)
  .flat()
  .filter((value, index, self) => index === self.findIndex(t => t.label === value.label))
  .sort((a, b) => a.enumValue - b.enumValue);

export const akzeptanzgrundPossibilities = [
  {
    status: 'NichtVerfuegbar',
    options: [
      { label: 'Technische Abfragestörung', enumValue: 1, enum: 'TechnischeAbfragestoerung' },
      { label: 'Reise ausgebucht', enumValue: 2, enum: 'ReiseAusgebucht' },
      { label: 'Termin abgesagt', enumValue: 3, enum: 'TerminAbgesagt' },
      { label: 'CM-Wechsel', enumValue: 4, enum: 'CMWechsel' },
      { label: 'HiHi Anlage nicht fertig', enumValue: 10, enum: 'HiHiAnlageNichtFertig' },
      { label: 'HS Anlage nicht fertig', enumValue: 11, enum: 'HSAnlageNichtFertig' },
    ],
  },
  {
    status: 'AufAnfrage',
    options: [
      { label: 'RES GRP', enumValue: 5, enum: 'RESGRP' },
      { label: 'FRA passt - anderer FH akzeptiert', enumValue: 6, enum: 'FRApasst' },
      { label: 'Keine Hotelverfügbarkeit', enumValue: 8, enum: 'KeineHotelverfuegbarkeit' },
      { label: 'HiHi Anlage nicht fertig', enumValue: 10, enum: 'HiHiAnlageNichtFertig' },
      { label: 'HS Anlage nicht fertig', enumValue: 11, enum: 'HSAnlageNichtFertig' },
      { label: 'Gruppe geplant', enumValue: 14, enum: 'GruppeGeplant' },
    ],
  },
  {
    status: 'Verfuegbar',
    options: [
      { label: 'FRA passt - anderer FH akzeptiert', enumValue: 6, enum: 'FRApasst' },
      { label: 'Release erfolgt bald', enumValue: 7, enum: 'ReleaseErfolgtBald' },
      { label: 'Reise ohne Flug', enumValue: 9, enum: 'ReiseOhneFlug' },
      { label: 'HiHi Anlage nicht fertig', enumValue: 10, enum: 'HiHiAnlageNichtFertig' },
      { label: 'HS Anlage nicht fertig', enumValue: 11, enum: 'HSAnlageNichtFertig' },
      { label: 'On hold', enumValue: 12, enum: 'OnHold' },
      { label: 'TH/VK MAX erreicht', enumValue: 13, enum: 'KurzfristigTHMaxErreicht' },
      { label: 'Gruppe geplant', enumValue: 14, enum: 'GruppeGeplant' },
    ],
  },
];

export const allAkzeptanzgruende = akzeptanzgrundPossibilities
  .map(item => item.options)
  .flat()
  .filter((value, index, self) => index === self.findIndex(t => t.label === value.label))
  .sort((a, b) => a.enumValue - b.enumValue);

export const loesungsansatzOptions = [
  { label: 'Gruppe geöffnet', enumValue: 1, enum: 'GruppeGeoeffnet' },
  { label: 'VK erhöht', enumValue: 2, enum: 'VKErhoeht' },
  { label: 'Transferslotanpassung', enumValue: 3, enum: 'Transferslotanpassung' },
  { label: 'Published Fares aktiviert', enumValue: 4, enum: 'PublishedFaresAktiviert' },
  { label: 'Restriktionsanpassung', enumValue: 5, enum: 'Restriktionsanpassung' },
  { label: 'Terminschließung', enumValue: 6, enum: 'Terminschließung' },
];

export const editStatusOptions = [
  { label: 'Verfügbarkeit geschaffen', enumValue: 1, enum: 'VerfuegbarkeitGeschaffen' },
  { label: 'Verfügbarkeit nicht geschaffen', enumValue: 2, enum: 'VerfuegbarkeitNichtGeschaffen' },
  { label: 'Akzeptiert', enumValue: 3, enum: 'Akzeptiert' },
];
