import store from '@/core/common/services/vuex-store.js';
export default {
  inserted(el, binding, vnode) {
    const roles = store.getters.customRoles ? store.getters.customRoles : store.getters.parsedRoles;
    const authorize = binding.value;
    var notAllowed = true;

    roles.forEach(value => {
      if (authorize.includes(value)) {
        notAllowed = false;
      }
    });

    if (notAllowed) {
      vnode.elm.parentElement.removeChild(vnode.elm);
    } else {
      // Marks all for others hidden elements with a yellow star #2045
      if (!store.state.userSettingsLocal.hideRoleIndicators && roles.includes('Admin')) {
        const insertElement = vnode.elm.firstChild instanceof Element ? vnode.elm.firstChild : vnode.elm;
        const titleText = `Nur sichtbar für: ${authorize.join(',')}`;
        insertElement.insertAdjacentHTML(
          'beforeend',
          `<span class="text-warning" title="${titleText}">*</span>`
        );
      }
    }
  },
};
