import apiService from '@/core/common/services/api.service';
import buildQuery from 'odata-query';

export const SAVE_COMMENT = 'comments.saveComment';
export const SAVE_REACTION = 'comments.saveReaction';
export const DELETE_REACTION = 'comments.deleteReaction';
export const CHANGE_REACTION = 'comments.changeReaction';
export const DELETE_COMMENT = 'comments.deleteComment';
export const SAVE_EDIT_COMMENT = 'comments.saveEditComment';
export const SAVE_REPLY_COMMENT = 'comments.saveReplyComment';
export const REFETCH_COMMENTS_AUFGABE = 'comments.refetchCommentsAufgabe';
export const REFETCH_COMMENTS_REISETERMIN = 'comments.refetchCommentsReisetermin';

export const TOGGLE_MARK_COMMENT = 'comments.toggleMarkComment';
export const SET_EDIT_COMMENT_ID = 'comments.setEditCommentId';
export const SET_REPLY_COMMENT_ID = 'comments.setReCommentId';
export const SET_COMMENT_TEXT = 'comments.setCommentText';
export const SET_MODE = 'comments.setMode';
export const SET_IS_COMMENTS_BUSY = 'aufgaben.setIsCommentsBusy';

export const GET_NEW_COMMENTS = 'aufgaben.getNewComments';

const state = () => ({
  editCommentId: null,
  replyCommentId: null,
  commentText: '',
  mode: '',
  isCommentsBusy: false,
});

const actions = {
  [REFETCH_COMMENTS_AUFGABE](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);
    const odataQuery = buildQuery({
      filter: { id: params.aufgabeId },
      select: ['id'],
      expand: { comments: {} },
    });

    return apiService
      .get('/aufgaben' + odataQuery)
      .then(response => {
        return response.data.result.value?.[0];
      })
      .finally(() => context.commit(SET_IS_COMMENTS_BUSY, false));
  },
  [REFETCH_COMMENTS_REISETERMIN](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);
    const odataQuery = buildQuery({
      filter: { id: params.reiseterminId },
      select: ['id'],
      expand: { comments: {} },
    });

    return apiService
      .get('/reisetermin' + odataQuery)
      .then(response => {
        return response.data.result.value?.[0];
      })
      .finally(() => context.commit(SET_IS_COMMENTS_BUSY, false));
  },
  [SAVE_COMMENT](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);
    let { isAufgabenComments, aufgabeId, reiseterminId } = params;
    const body = isAufgabenComments
      ? {
          aufgabeId,
          text: context.state.commentText,
        }
      : {
          reiseterminId,
          text: context.state.commentText,
        };

    return apiService
      .post('/Comments', body)
      .then(async () => {
        return await context.dispatch(GET_NEW_COMMENTS, params);
      })
      .catch(() => context.commit(SET_IS_COMMENTS_BUSY, false));
  },
  [DELETE_COMMENT](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);

    return apiService
      .delete('Comments?commentId=' + params.commentId, {
        CommentId: params.commentId,
      })
      .then(async () => {
        return await context.dispatch(GET_NEW_COMMENTS, params);
      })
      .catch(() => context.commit(SET_IS_COMMENTS_BUSY, false));
  },
  [SAVE_REPLY_COMMENT](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);
    const { isAufgabenComments, aufgabeId, reiseterminId } = params;
    const body = isAufgabenComments
      ? {
          aufgabeId,
          text: context.state.commentText,
          parentId: context.state.replyCommentId,
        }
      : {
          reiseterminId,
          text: context.state.commentText,
          parentId: context.state.replyCommentId,
        };
    return apiService
      .post('/Comments', body)
      .then(async () => {
        context.commit(SET_IS_COMMENTS_BUSY, false);
        context.commit(SET_MODE, '');
        context.commit(SET_COMMENT_TEXT, '');
        context.commit(SET_REPLY_COMMENT_ID, null);

        return await context.dispatch(GET_NEW_COMMENTS, params);
      })
      .finally(() => context.commit(SET_IS_COMMENTS_BUSY, false));
  },
  [SAVE_EDIT_COMMENT](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);

    if (context.state.editCommentId) {
      return apiService
        .put('/Comments?commentId=' + context.state.editCommentId, {
          commentId: context.state.editCommentId,
          text: context.state.commentText,
        })
        .then(async () => {
          context.commit(SET_MODE, '');
          context.commit(SET_COMMENT_TEXT, '');
          context.commit(SET_EDIT_COMMENT_ID, null);
          context.commit(SET_IS_COMMENTS_BUSY, false);

          return await context.dispatch(GET_NEW_COMMENTS, params);
        })
        .catch(() => context.commit(SET_IS_COMMENTS_BUSY, false));
    }
  },
  [SAVE_REACTION](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);
    const { isAufgabenComments, aufgabeId, reiseterminId, text, replyCommentId } = params;

    const body = isAufgabenComments
      ? {
          aufgabeId,
          text,
          parentId: replyCommentId,
        }
      : {
          reiseterminId,
          text,
          parentId: replyCommentId,
        };

    return apiService
      .post('/Comments', body)
      .then(async () => {
        return await context.dispatch(GET_NEW_COMMENTS, params);
      })
      .catch(() => context.commit(SET_IS_COMMENTS_BUSY, false));
  },
  [DELETE_REACTION](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);

    return apiService
      .delete('/Comments?commentId=' + params.commentId, {
        CommentId: params.commentId,
      })
      .then(async () => {
        return await context.dispatch(GET_NEW_COMMENTS, params);
      })
      .catch(() => context.commit(SET_IS_COMMENTS_BUSY, false));
  },
  [CHANGE_REACTION](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);
    const { isAufgabenComments, aufgabeId, reiseterminId, text, replyCommentId } = params;
    const body = isAufgabenComments
      ? {
          aufgabeId,
          text,
          parentId: replyCommentId,
        }
      : {
          reiseterminId,
          text,
          parentId: replyCommentId,
        };

    return apiService
      .delete('/Comments?commentId=' + params.commentId, {
        CommentId: params.commentId,
      })
      .then(() => {
        return apiService.post('/Comments', body).then(async () => {
          return await context.dispatch(GET_NEW_COMMENTS, params);
        });
      })
      .finally(() => context.commit(SET_IS_COMMENTS_BUSY, false));
  },
  async [GET_NEW_COMMENTS](context, params) {
    const { aufgabeId, reiseterminId, isAufgabenComments } = params;
    if (isAufgabenComments) {
      return await context.dispatch(REFETCH_COMMENTS_AUFGABE, {
        aufgabeId,
      });
    }
    return await context.dispatch(REFETCH_COMMENTS_REISETERMIN, {
      reiseterminId,
    });
  },
  [TOGGLE_MARK_COMMENT](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);

    return apiService
      .put('Comments/toggleMark?commentId=' + params.commentId, { commentId: params.commentId })
      .then(async () => {
        return await context.dispatch(GET_NEW_COMMENTS, params);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        context.commit(SET_IS_COMMENTS_BUSY, false);
      });
  },
};

const mutations = {
  [SET_IS_COMMENTS_BUSY](state, isBusy) {
    state.isCommentsBusy = isBusy;
  },
  [SET_EDIT_COMMENT_ID](state, editCommentId) {
    state.editCommentId = editCommentId;
  },
  [SET_REPLY_COMMENT_ID](state, replyCommentId) {
    state.replyCommentId = replyCommentId;
  },
  [SET_COMMENT_TEXT](state, commentText) {
    state.commentText = commentText;
  },
  [SET_MODE](state, mode) {
    state.mode = mode;
  },
};

export default {
  state,
  actions,
  mutations,
};
