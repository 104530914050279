import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
const sanitizeHtml = require('sanitize-html');
const moment = require('moment-timezone');
moment.tz.setDefault('Europe/Berlin');
const Vue2FiltersConfig = {
  currency: {
    symbol: '€',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    showPlusSign: false,
  },
};
const sanitizeHtmlOptions = {
  allowedTags: [
    'address',
    'article',
    'aside',
    'footer',
    'header',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hgroup',
    'main',
    'nav',
    'section',
    'blockquote',
    'dd',
    'div',
    'span',
    'dl',
    'dt',
    'figcaption',
    'figure',
    'hr',
    'li',
    'main',
    'ol',
    'p',
    'pre',
    'ul',
    'a',
    'abbr',
    'b',
    'bdi',
    'bdo',
    'br',
    'cite',
    'code',
    'data',
    'dfn',
    'em',
    'i',
    'kbd',
    'mark',
    'q',
    'rb',
    'rp',
    'rt',
    'rtc',
    'ruby',
    's',
    'samp',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'time',
    'u',
    'var',
    'wbr',
    'caption',
    'col',
    'colgroup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
  ],
  disallowedTagsMode: 'discard',
  allowedAttributes: {
    a: ['href', 'name', 'target'],
    img: ['src'],
    p: ['style'],
    span: ['style'],
  },
  selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
  allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
  allowedSchemesByTag: {},
  allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
  allowProtocolRelative: true,
  enforceHtmlBoundary: false,
};

Vue.filter('date', function (value) {
  if (!value) return '';
  return moment(value, moment.ISO_8601).format('DD.MM.YYYY');
});

Vue.filter('dateAll', function (value) {
  if (!value) return '';
  return moment(value).format('DD.MM.YYYY');
});

Vue.filter('dateWorkday', function (value) {
  if (!value) return '';
  return moment(value).format('dddd, DD.MM.YY');
});

Vue.filter('time', function (value) {
  if (!value) return '';
  return moment(value).format('HH:mm') + ' Uhr';
});

Vue.filter('dateTime', function (value) {
  if (!value) return '';
  return moment(value).format('DD.MM.YY HH:mm');
});

Vue.filter('dateNumeric', function (value) {
  if (!value) return '';
  return moment(value).format('YYYY-MM-DD');
});

Vue.filter('timestamp', function (value) {
  if (!value) return '';
  return moment(value).format('DD.MM.YY HH:mm');
});

Vue.filter('striphtml', function (value) {
  const doc = new DOMParser().parseFromString(value, 'text/html');
  return doc.body.textContent || '';
});

Vue.filter('sanitizeHtml', function (value) {
  return sanitizeHtml(value, sanitizeHtmlOptions);
});

Vue.filter('formatNumber', function (value) {
  const rounded = Math.round(value * 10) / 10;
  return rounded
    .toString()
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
});

Vue.filter('kunde', function (value) {
  return value?.name
    ? `${value.name.anrede} ${value.name.titel || ''} ${value.name.vorname || ''} ${
        value.name.nachname || ''
      }`
    : '';
});

Vue.use(Vue2Filters, Vue2FiltersConfig);

export default Vue2Filters;
