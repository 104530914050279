import {
  getReiseterminKuerzelFilterOptions,
  getReiseFilterOptions,
} from '@/core/common/services/odata.service';

import { isOptionsRefreshableByValueAndKey } from '@/core/common/helpers/utils';

import { transformRegionsCountriesFilter } from '@/core/common/helpers/filterUtils.js';

import { isObjectJSONStringifyEqual } from '@/core/common/helpers/utils';

import {
  getReiseterminSearchResult,
  getReiseterminSearchResultQuery,
  getReseterminSearchSortExpressionQuery,
} from '@/core/common/services/reiseterminsearch.service';

export const SET_APPLIED_FILTER = 'reisetermine.setAppliedReiseterminFilter';

export const GET_REISETERMINE = 'reisetermine.getReisetermine';
export const SET_REISETERMINE = 'reisetermine.setReisetermine';
export const SET_REISETERMINE_TOP = 'reisetermine.setReisetermineTop';
export const SET_REISETERMINE_SKIP = 'reisetermine.setReisetermineSkip';
export const SET_REISETERMINE_SORT_EXPESSION = 'reisetermine.setReisetermineSortExpression';
export const SET_REISETERMINE_IS_BUSY = 'reisetermine.setReisetermineIsBusy';
export const SET_REISETERMINE_COUNT = 'reisetermine.setReisetermineCount';
export const SET_IS_INITIALLY_LOADING = 'reisetermine.setIsInitiallyLoading';

export const SET_IS_ANY_FILTER_APPLIED = 'reisetermine.setIsAnyFilterApplied';
export const SET_IS_CURRENT_FILTER_APPLIED = 'reisetermine.setIsCurrentFilterApplied';

export const REISETERMINE_SORT_CHANGED = 'reisetermine.sortChanged';
export const REISETERMINE_PAGE_CHANGED = 'reisetermine.pageChanged';

export const SET_REISETERMIN_BY_ID = 'reisetermine.setReiseterminById';

export const SAVED_FILTER = 'reisetermine.savedFilter';
export const RESET_FILTER = 'reisetermine.resetFilter';

export const SET_BEARBEITER = 'reisetermine.setBearbeiter';
export const RESET_BEARBEITER = 'reisetermine.resetBearbeiterFilter';
export const SET_DEFAULT_BEARBEITER = 'reisetermine.setDefaultBearbeiter';

export const SET_BASE_STATE_FILTER = 'reisetermine.setBaseStateFilter';
export const RESET_BASE_STATE = 'reisetermine.resetBaseStateFilter';

export const SET_PAX_FILTER = 'reisetermine.setPaxFilter';
export const RESET_PAX_FILTER = 'reisetermine.resetPaxFilter';

export const SET_REISE_FILTER = 'reisetermine.setReiseFilter';
export const RESET_REISE_FILTER = 'reisetermine.resetReiseFilter';
export const GET_REISE_FILTER_OPTIONS = 'reisetermine.getReiseFilterOptions';
export const SET_REISE_FILTER_OPTIONS = 'reisetermine.setReiseFilterOptions';

export const SET_REGION_FILTER = 'reisetermine.setRegionFilter';
export const RESET_REGION_FILTER = 'reisetermine.resetRegionFilter';

export const SET_COUNTRY_FILTER = 'reisetermine.setCountryFilter';
export const RESET_COUNTRY_FILTER = 'reisetermine.resetCountryFilter';

export const SET_START_DATE_FILTER = 'reisetermine.setstartDateFilterFilter';
export const RESET_START_DATE_FILTER = 'reisetermine.resetstartDateFilterFilter';

export const SET_RELEASE_DATE_FILTER = 'reisetermine.setreleaseDateFilterFilter';
export const RESET_RELEASE_DATE_FILTER = 'reisetermine.resetreleaseDateFilterFilter';

export const SET_ABREISE_DATE_FILTER = 'reisetermine.setabreiseDateFilterFilter';
export const RESET_ABREISE_DATE_FILTER = 'reisetermine.resetabreiseDateFilterFilter';

export const SET_UMSATZ_FILTER = 'reisetermine.setUmsatzFilter';
export const RESET_UMSATZ_FILTER = 'reisetermine.resetUmsatzFilter';

export const SET_BASIS_PREIS_FILTER = 'reisetermine.setBasisPreisFilter';
export const RESET_BASIS_PREIS_FILTER = 'reisetermine.resetBasisPreisFilter';

export const SET_FRIST_FILTER = 'reisetermine.setFristFilter';
export const RESET_FRIST_FILTER = 'reisetermine.resetFristFilter';

export const SET_BLANK_STATE_VORGANG_FILTER = 'reisetermine.setblankStateVorgangFilter';
export const SET_BLANK_STATE_VORGANG_FILTER_OPTIONS = 'reisetermine.setblankStateVorgangFilterOptions';
export const RESET_BLANK_STATE_VORGANG_FILTER = 'reisetermine.resetblankStateVorgangFilter';

export const SET_GARANTIERT_FILTER = 'reisetermine.setGarantiertFilter';
export const RESET_GARANTIERT_FILTER = 'reisetermine.resetGarantiertFilter';

export const SET_IST_AUSGEBLENDET_FILTER = 'reisetermine.setIstAusgeblendetFilter';
export const RESET_IST_AUSGEBLENDET_FILTER = 'reisetermine.resetIstAusgeblendetFilter';

export const SET_IST_PENDING_FILTER = 'reisetermine.setIstPendingFilter';
export const RESET_IST_PENDING_FILTER = 'reisetermine.resetIstPendingFilter';
export const SET_IST_ERLEDIGT_FILTER = 'reisetermine.setIstErledigtFilter';
export const RESET_IST_ERLEDIGT_FILTER = 'reisetermine.resetIstErledigtFilter';

export const SET_SECHSERHUELSEN_FILTER = 'reisetermine.setSechserhuelsenFilter';
export const RESET_SECHSERHUELSEN_FILTER = 'reisetermine.resetSechserhuelsenFilter';

export const SET_REISETERMIN_KUERZEL_FILTER = 'reisetermine.setReiseterminKuerzelFilter';
export const RESET_REISETERMIN_KUERZEL_FILTER = 'reisetermine.resetReiseterminKuerzelFilter';
export const GET_REISETERMIN_KUERZEL_FILTER_OPTIONS = 'reisetermine.getReiseterminKuerzelFilterOptions';
export const SET_REISETERMIN_KUERZEL_FILTER_OPTIONS = 'reisetermine.setReiseterminKuerzelFilterOptions';

export const UPDATE_EXPAND_AND_FILTER_FOR_ROLE = 'reisetermine.updateExpandAndFilterForRole';
export const SET_ROLE_FILTER = 'reisetermine.setRoleFilter';
export const RESET_ROLE_FILTER = 'reisetermine.resetRoleFilter';

export const SET_EXPAND = 'reisetermine.setExpand';
export const RESET_EXPAND_TO_INITIAL = 'reisetermine.resetExpandToInitial';

export const SET_INTERESSENTEN_FILTER = 'reisetermine.setInteressentenFilter';
export const RESET_INTERESSENTEN_FILTER = 'reisetermine.resetInteressentenFilter';

export const SET_HASVORGAENGEWITHFLUGTICKETSAUSGESTELLT_FILTER =
  'reisetermine.setHasVorgaengeWithFlugticketsAusgestelltFilter';
export const RESET_HASVORGAENGEWITHFLUGTICKETSAUSGESTELLT_FILTER =
  'reisetermine.resetHasVorgaengeWithFlugticketsAusgestelltFilter';

export const SET_ALL_FILTER_TO_INTIAL = 'reisetermine.setAllFilterToInitial';

const state = () => ({
  appliedReiseterminFilter: null,
  reisetermine: [],
  top: 25,
  skip: 0,
  sortExpressions: [
    { key: 'abreisedatum', desc: false },
    { key: 'reise.prioritaet', desc: false },
  ],
  count: 0,
  isInitiallyLoading: true,
  isBusy: true,
  isCurrentFilterApplied: true,
  isAnyFilterApplied: false,

  // always set blank filters
  blankStateVorgangFilterOptions: null,
  editStateFilterOptions: null,
  reiseterminKuerzelFilterOptions: null,
  reiseFilterOptions: [],

  filter: {
    // Bearbeiter Filter
    selectedBearbeiter: [],
    // Base State Filter
    baseStateFilter: [],
    // Pax Filter
    paxFilter: [],
    // Reise Filter
    reisenFilter: [],
    // Continent Filter TODO: Rename region to continent
    regionFilter: [],
    // Country Filter
    countryFilter: [],
    // Start Datum Filter
    startDateFilter: { end: null, start: null },
    // Release Datum Filter
    releaseDateFilter: { start: null, end: null },
    // Abreise Datum Filter
    abreiseDateFilter: { start: null, end: null },
    // Umsatz Filter
    umsatzFilter: null,
    // BasisPreis Filter
    basisPreisFilter: null,
    // Frist Filter
    fristFilter: [],
    // Edit State Filter
    editStateFilter: null,
    // Edit State Vorgang Filter
    blankStateVorgangFilter: null,
    // Garantiert Filter
    garantiertFilter: null,
    // is Ausgeblendet Filter
    istAusgeblendetFilter: false,
    // is pending Filter
    istPendingFilter: true,
    istErledigtFilter: false,
    // Reiseterminkuerzel Filter
    reiseterminkuerzelFilter: [],
    // Role Filter (For Teamdashboard)
    roleFilter: null,
    // reisedauer Filter
    reisedauerFilter: [],
    // interessenten Filter
    interessentenFilter: null,
    // sechserhuelsen Filter
    sechserhuelsenFilter: false,
    // Filter for Reisetermine with Vorgaenge with Flugtickets ausgestellt
    setHasVorgaengeWithFlugticketsAusgestelltFilter: false,
  },
  expand: {
    Reise: {},
    Metadata: { expand: { ZiellandZielflughaefen: {}, ZiellandAbflughaefen: {}, Inlandsfluege: {} } },
    gueltigeKonfiguration: {},
    pax: {},
    comments: {},
  },
});
const initialState = { ...state() };

const getters = {
  // get the filter object
  reiseterminFiltersObject(state) {
    return state.filter;
  },
  filterMessage(state) {
    const startDate = new Date(state.filter.startDateFilter.start);
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(startDate.getDate() - 2);

    const abreiseDateMessage =
      new Date(state.filter.abreiseDateFilter.start) <= startDate ||
      new Date(state.filter.abreiseDateFilter.start) >= new Date(state.filter.startDateFilter.end)
        ? 'Die ausgewählten Zeiträume für das Start- und das Abreisedatum passen nicht zusammen - bitte überprüfen und korrigieren.'
        : null;
    return abreiseDateMessage;
  },
};

const actions = {
  [GET_REISETERMINE](context) {
    context.commit(SET_REISETERMINE_IS_BUSY, true);
    context.commit(SET_APPLIED_FILTER, context.state.filter);
    context.commit(
      SET_IS_ANY_FILTER_APPLIED,
      !isObjectJSONStringifyEqual(context.state.filter, initialState.filter)
    );
    return getReiseterminSearchResult(
      buildSearchQueryFromFilterObject(context.state.filter),
      context.state.skip,
      context.state.top,
      getReseterminSearchSortExpressionQuery(context.state.sortExpressions),
      'Reisetermin/search/fom-dashboard'
    )
      .then(response => {
        context.commit(SET_IS_CURRENT_FILTER_APPLIED, true);
        context.commit(SET_IS_INITIALLY_LOADING, false);

        context.commit(SET_REISETERMINE, response.value.reisetermine || []);
        context.commit(SET_REISETERMINE_IS_BUSY, false);
        context.commit(SET_REISETERMINE_COUNT, response.value.totalReiseterminCount || 0);
        return response.data;
      })
      .catch(error => {
        console.log(error);
      });
  },
  [REISETERMINE_SORT_CHANGED](context, { sortExpressions: sortExpressions }) {
    context.commit(SET_REISETERMINE_SORT_EXPESSION, sortExpressions);
    context.commit(SET_REISETERMINE_SKIP, 0);
    return context.dispatch(GET_REISETERMINE);
  },
  [REISETERMINE_PAGE_CHANGED](context, { pageContext: pageContext }) {
    context.commit(SET_REISETERMINE_SKIP, pageContext.skip);
    context.commit(SET_REISETERMINE_TOP, pageContext.top);
    return context.dispatch(GET_REISETERMINE);
  },
  [SAVED_FILTER](context) {
    context.commit(SET_REISETERMINE_SKIP, 0);
    return context.dispatch(GET_REISETERMINE);
  },
  [RESET_FILTER](context) {
    context.commit(SET_REISETERMINE_SKIP, 0);
    context.commit(SET_IS_ANY_FILTER_APPLIED, true);
    context.commit(SET_IS_CURRENT_FILTER_APPLIED, true);
    context.commit(SET_ALL_FILTER_TO_INTIAL);
    return context.dispatch(GET_REISETERMINE);
  },
  [GET_REISE_FILTER_OPTIONS](context) {
    const transformedRegionsCountriesFilter = transformRegionsCountriesFilter(
      context.state.filter.regionFilter,
      context.state.filter.countryFilter
    );
    return getReiseFilterOptions(transformedRegionsCountriesFilter).then(options => {
      return context.commit(SET_REISE_FILTER_OPTIONS, options);
    });
  },
  [GET_REISETERMIN_KUERZEL_FILTER_OPTIONS](context, { kuerzelPart }) {
    const isRefreshable = isOptionsRefreshableByValueAndKey(
      kuerzelPart,
      'reiseterminkuerzel',
      context.state.reiseterminKuerzelFilterOptions,
      3
    );
    return isRefreshable
      ? getReiseterminKuerzelFilterOptions(kuerzelPart).then(options => {
          context.commit(SET_REISETERMIN_KUERZEL_FILTER_OPTIONS, options);
        })
      : context.commit(SET_REISETERMIN_KUERZEL_FILTER_OPTIONS, []);
  },
};

const mutations = {
  [RESET_EXPAND_TO_INITIAL](state) {
    state.expand = initialState.expand;
  },
  [SET_EXPAND](state, expand) {
    state.expand = expand;
  },
  [SET_REISETERMINE](state, reisetermine) {
    state.reisetermine = reisetermine;
  },
  [SET_REISETERMINE_COUNT](state, count) {
    state.count = count;
  },
  [SET_REISETERMINE_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },
  [SET_IS_INITIALLY_LOADING](state, isInitiallyLoading) {
    state.isInitiallyLoading = isInitiallyLoading;
  },
  [SET_REISETERMINE_SORT_EXPESSION](state, sortExpressions) {
    state.sortExpressions = sortExpressions;
  },
  [SET_REISETERMINE_SKIP](state, skip) {
    state.skip = skip;
  },
  [SET_REISETERMINE_TOP](state, top) {
    state.top = top;
  },
  // General filter mutations
  [SET_IS_ANY_FILTER_APPLIED](state, isAnyFilterApplied) {
    state.isAnyFilterApplied = isAnyFilterApplied;
  },
  [SET_IS_CURRENT_FILTER_APPLIED](state, isCurrentFilterApplied) {
    state.isCurrentFilterApplied = isCurrentFilterApplied;
  },
  [SET_APPLIED_FILTER](state, appliedReiseterminFilter) {
    state.appliedReiseterminFilter = { ...appliedReiseterminFilter };
  },
  [SET_ALL_FILTER_TO_INTIAL](state) {
    state.filter.selectedBearbeiter = initialState.filter.selectedBearbeiter;
    state.filter.baseStateFilter = initialState.filter.baseStateFilter;
    state.filter.paxFilter = initialState.filter.paxFilter;
    state.filter.reisenFilter = initialState.filter.reisenFilter;
    state.filter.regionFilter = initialState.filter.regionFilter;
    state.filter.countryFilter = initialState.filter.countryFilter;
    state.filter.startDateFilter = initialState.filter.startDateFilter;
    state.filter.releaseDateFilter = initialState.filter.releaseDateFilter;
    state.filter.abreiseDateFilter = initialState.filter.abreiseDateFilter;
    state.filter.umsatzFilter = initialState.filter.umsatzFilter;
    state.filter.basisPreisFilter = initialState.filter.basisPreisFilter;
    state.filter.fristFilter = initialState.filter.fristFilter;
    state.filter.editStateFilter = initialState.filter.editStateFilter;
    state.filter.blankStateVorgangFilter = initialState.filter.blankStateVorgangFilter;
    state.filter.garantiertFilter = initialState.filter.garantiertFilter;
    state.filter.reisedauerFilter = initialState.filter.reisedauerFilter;
    state.filter.reiseterminkuerzelFilter = initialState.filter.reiseterminkuerzelFilter;
    state.filter.interessentenFilter = initialState.filter.interessentenFilter;
    state.filter.sechserhuelsenFilter = initialState.filter.sechserhuelsenFilter;
    state.filter.istPendingFilter = initialState.filter.istPendingFilter;
    state.filter.istPendingFilter = initialState.filter.istPendingFilter;
    state.filter.istAusgeblendetFilter = initialState.filter.istAusgeblendetFilter;
  },
  // Bearbeiter
  [SET_BEARBEITER](state, bearbeiter) {
    state.filter.selectedBearbeiter = Array.isArray(bearbeiter) ? bearbeiter : [bearbeiter];
  },
  [RESET_BEARBEITER](state) {
    state.filter.selectedBearbeiter = [];
  },
  [SET_DEFAULT_BEARBEITER](state, bearbeiter) {
    if (state.filter.selectedBearbeiter.length === 0) {
      state.filter.selectedBearbeiter = Array.isArray(bearbeiter) ? bearbeiter : [bearbeiter];
    }
  },
  // Base State
  [SET_BASE_STATE_FILTER](state, filter) {
    state.filter.baseStateFilter = filter;
  },
  [RESET_BASE_STATE](state) {
    state.filter.baseStateFilter = [];
  },
  // Pax Filter
  [SET_PAX_FILTER](state, filter) {
    state.filter.paxFilter = filter;
  },
  [RESET_PAX_FILTER](state) {
    state.filter.paxFilter = [];
  },
  // Reise Filter
  [SET_REISE_FILTER](state, filter) {
    state.filter.reisenFilter = filter;
  },
  [RESET_REISE_FILTER](state) {
    state.filter.reisenFilter = [];
  },
  [SET_REISE_FILTER_OPTIONS](state, filterOptions) {
    state.reiseFilterOptions = filterOptions;
  },
  // Region (Continent) Filter
  [SET_REGION_FILTER](state, filter) {
    state.filter.regionFilter = filter;
  },
  [RESET_REGION_FILTER](state) {
    state.filter.regionFilter = [];
  },
  // Country (Continent) Filter
  [SET_COUNTRY_FILTER](state, filter) {
    state.filter.countryFilter = filter;
  },
  [RESET_COUNTRY_FILTER](state) {
    state.filter.countryFilter = [];
  },
  // Start Date Filter
  [SET_START_DATE_FILTER](state, date) {
    state.filter.startDateFilter = date || { start: null, end: null };
  },
  [RESET_START_DATE_FILTER](state) {
    state.filter.startDateFilter = { start: null, end: null };
  },
  // Release Date Filter
  [SET_RELEASE_DATE_FILTER](state, date) {
    state.filter.releaseDateFilter = date || { start: null, end: null };
  },
  [RESET_RELEASE_DATE_FILTER](state) {
    state.filter.releaseDateFilter = { start: null, end: null };
  },
  // Abreise Date Filter
  [SET_ABREISE_DATE_FILTER](state, date) {
    state.filter.abreiseDateFilter = date || { start: null, end: null };
  },
  [RESET_ABREISE_DATE_FILTER](state) {
    state.filter.abreiseDateFilter = { start: null, end: null };
  },
  // Umsatz Filter
  [SET_UMSATZ_FILTER](state, range) {
    state.filter.umsatzFilter = range;
  },
  [RESET_UMSATZ_FILTER](state) {
    state.filter.umsatzFilter = null;
  },
  // Basis Preis Filter
  [SET_BASIS_PREIS_FILTER](state, range) {
    state.filter.basisPreisFilter = range;
  },
  [RESET_BASIS_PREIS_FILTER](state) {
    state.filter.basisPreisFilter = null;
  },
  // Frist Filter
  [SET_FRIST_FILTER](state, filter) {
    state.filter.fristFilter = filter;
  },
  [RESET_FRIST_FILTER](state) {
    state.filter.fristFilter = [];
  },
  // Edit State Vorgang Filter
  [SET_BLANK_STATE_VORGANG_FILTER](state, blankStateVorgangFilter) {
    state.filter.blankStateVorgangFilter = blankStateVorgangFilter || null;
  },
  [SET_BLANK_STATE_VORGANG_FILTER_OPTIONS](state, filterOptions) {
    state.blankStateVorgangFilterOptions = filterOptions;
  },
  [RESET_BLANK_STATE_VORGANG_FILTER](state) {
    state.filter.blankStateVorgangFilter = null;
  },
  // Garantiert Filter
  [SET_GARANTIERT_FILTER](state, filter) {
    state.filter.garantiertFilter = filter;
  },
  [RESET_GARANTIERT_FILTER](state) {
    state.filter.garantiertFilter = null;
  },
  // Ist Ausgeblendet Filter
  [SET_IST_AUSGEBLENDET_FILTER](state, filter) {
    state.filter.istAusgeblendetFilter = filter;
  },
  [RESET_IST_AUSGEBLENDET_FILTER](state) {
    state.filter.istAusgeblendetFilter = null;
  },
  // Ist Pending Filter
  [SET_IST_PENDING_FILTER](state, filter) {
    state.filter.istPendingFilter = filter;
  },
  [RESET_IST_PENDING_FILTER](state) {
    state.filter.istPendingFilter = null;
  },
  [SET_IST_ERLEDIGT_FILTER](state, filter) {
    state.filter.istErledigtFilter = filter;
  },
  [RESET_IST_ERLEDIGT_FILTER](state) {
    state.filter.istErledigtFilter = null;
  },
  // Reiseterminkuerzel Filter
  [SET_REISETERMIN_KUERZEL_FILTER](state, filter) {
    state.filter.reiseterminkuerzelFilter = filter;
  },
  [RESET_REISETERMIN_KUERZEL_FILTER](state) {
    state.filter.reiseterminkuerzelFilter = [];
  },
  [SET_REISETERMIN_KUERZEL_FILTER_OPTIONS](state, options) {
    state.reiseterminKuerzelFilterOptions = options;
  },
  // Role Filter (for teamdashboard)
  [SET_ROLE_FILTER](state, filter) {
    state.filter.roleFilter = filter;
  },
  [RESET_ROLE_FILTER](state) {
    state.filter.roleFilter = null;
  },
  // Interessenten Filter
  [SET_INTERESSENTEN_FILTER](state, filter) {
    state.filter.interessentenFilter = filter;
  },
  [RESET_INTERESSENTEN_FILTER](state) {
    state.filter.interessentenFilter = null;
  },
  // Sechserhuelsen Filter
  [SET_SECHSERHUELSEN_FILTER](state, isSechserhuelsenFilterActive) {
    state.filter.sechserhuelsenFilter = isSechserhuelsenFilterActive;
  },
  [RESET_SECHSERHUELSEN_FILTER](state) {
    state.filter.sechserhuelsenFilter = false;
  },
  // Vorganege With Flugtickets Ausgestellt Filter
  [SET_HASVORGAENGEWITHFLUGTICKETSAUSGESTELLT_FILTER](state, filter) {
    state.filter.hasVorgaengeWithFlugTicketsAusgestelltFilter = filter;
  },
  [RESET_HASVORGAENGEWITHFLUGTICKETSAUSGESTELLT_FILTER](state) {
    state.filter.hasVorgaengeWithFlugTicketsAusgestelltFilter = false;
  },
};

function buildSearchQueryFromFilterObject(filterObject) {
  return getReiseterminSearchResultQuery(
    filterObject.selectedBearbeiter,
    filterObject.baseStateFilter,
    filterObject.paxFilter,
    filterObject.garantiertFilter,
    filterObject.reisenFilter,
    filterObject.regionFilter,
    filterObject.countryFilter,
    filterObject.startDateFilter,
    filterObject.releaseDateFilter,
    filterObject.abreiseDateFilter,
    filterObject.umsatzFilter,
    filterObject.fristFilter,
    filterObject.blankStateVorgangFilter,
    filterObject.reiseterminkuerzelFilter,
    filterObject.roleFilter,
    filterObject.sechserhuelsenFilter,
    filterObject.hasVorgaengeWithFlugTicketsAusgestelltFilter,
    filterObject.istAusgeblendetFilter,
    filterObject.istPendingFilter,
    filterObject.istErledigtFilter
  );
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
