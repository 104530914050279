import apiService from '@/core/common/services/api.service';
import * as applicationInsights from '@/core/common/services/application-insights.service';

import { PublicClientApplication } from '@azure/msal-browser';
import { Role as roleMapping } from '@/core/user/roles.js';

// action types
export const VERIFY_AUTH = 'auth.verifyAuth';
export const LOGIN = 'auth.login';
export const LOGOUT = 'auth.logout';
export const AUTHENTICATE = 'auth.authenticate';
export const INITIALIZE_AUTH = 'auth.initializeAuthentication';
export const REFRESH_ACCESS_TOKEN_AND_RETRY = 'auth.refreshAccessTokenAndRetry';
export const GET_MSGRAPH_USERS = 'auth.getGraphUsers';
export const TRY_SSO_SILENT_LOGIN = 'auth.trySSOSilentLogin';
export const GET_AUTH_RESPONSE = 'auth.getAuthResponse';
// mutation types
export const SET_ACCESS_TOKEN = 'auth.setAccessToken';
export const SET_ID_TOKEN = 'auth.setIdToken';
export const SET_ACCOUNT = 'auth.setAccount';
export const SET_LOGIN_REQUIRED = 'auth.setLoginRequired';

const state = {
  errors: null,
  msalConfig: {
    auth: {
      clientId: process.env.VUE_APP_AZURE_AD_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_AD_TENANT_ID}`,
      redirectUri: `${process.env.VUE_APP_LOGIN_REDIRECT_URL}`,
      navigateToLoginRequestUrl: false,
      validateAuthority: false,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message) => {
          //console.info(message);
        },
        piiLoggingEnabled: false,
      },
    },
  },
  accessToken: null,
  idToken: null,
  account: null,
  isLoginRequired: false,
  scopes: ['openid', 'profile', `api://${process.env.VUE_APP_AZURE_AD_CLIENT_ID}/Access`],
  graphScopes: ['User.Read.All'],
  msGraphUsersEndpoint:
    'https://graph.microsoft.com/v1.0/users?$top=900&$select=department,businessPhones, displayName, givenName, id, jobTitle, mail, mobilePhone, officeLocation, preferredLanguage, surname, userPrincipalName',
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

const getters = {
  accessToken(state) {
    return state.accessToken;
  },
  currentUser(state) {
    return state.account;
  },
  currentUserName(state) {
    return state?.account?.username;
  },
  currentUserId(state) {
    return state?.account?.localAccountId;
  },
  parsedRoles(state) {
    return state.account.idTokenClaims.roles.map(role => roleMapping[role.toUpperCase()]);
  },
  isUserFreelancer(state, getters) {
    return getters.parsedRoles.includes('Freelancer');
  },
};

const actions = {
  async [GET_MSGRAPH_USERS](context) {
    const usersReadRequest = { scopes: context.state.graphScopes };
    return getTokenRedirect(this._vm.$msalInstance, usersReadRequest).then(response => {
      return apiService
        .getWithAuthToken(context.state.msGraphUsersEndpoint, response.accessToken)
        .then(response => {
          return response.data.value;
        })
        .catch(error => {
          console.error(error);
          return [];
        });
    });
  },
  async [GET_AUTH_RESPONSE](context) {
    const redirectStartPage = window.location.href;
    const tokenRequest = {
      account: context.state.account,
      scopes: context.state.scopes,
      redirectStartPage,
      forceRefresh: false,
    };
    try {
      return await this._vm.$msalInstance.acquireTokenSilent(tokenRequest);
    } catch (e) {
      this._vm.$msalInstance.acquireTokenRedirect(tokenRequest);
      return null;
    }
  },
  async [AUTHENTICATE](context) {
    return new Promise(async (resolve, reject) => {
      // see https://docs.microsoft.com/de-de/azure/active-directory/develop/scenario-spa-sign-in?tabs=javascript2
      try {
        // Check if the tokenResponse is null
        // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
        // If the tokenResponse === null, you are not coming back from an auth redirect.
        let tokenResponse = await this._vm.$msalInstance.handleRedirectPromise();

        let accountObj;
        if (tokenResponse) {
          accountObj = tokenResponse.account;
        } else {
          accountObj = this._vm.$msalInstance.getAllAccounts()[0];
        }

        if (accountObj && tokenResponse) {
          // both objects are there => User is logged in
          context.commit(SET_ACCESS_TOKEN, tokenResponse.accessToken);
          apiService.setHeader(tokenResponse.accessToken);
          context.commit(SET_ACCOUNT, accountObj);
          resolve(context.state);
        } else if (accountObj) {
          // only accountObj is there -> needs new tokens
          try {
            tokenResponse = await this._vm.$msalInstance.acquireTokenSilent({
              account: this._vm.$msalInstance.getAllAccounts()[0],
              scopes: context.state.scopes,
            });
            context.commit(SET_ACCESS_TOKEN, tokenResponse.accessToken);
            apiService.setHeader(tokenResponse.accessToken);
            context.commit(SET_ACCOUNT, accountObj);
            resolve(context.state);
          } catch (err) {
            await this._vm.$msalInstance.acquireTokenRedirect({ scopes: context.state.scopes });
            return context.state;
          }
        } else {
          // accountObj && tokenResponse are missing => User needs to log in
          context.commit(SET_LOGIN_REQUIRED, true);
          reject(context.state);
        }
      } catch (error) {
        console.error('Failed to handleRedirectPromise()', error);
        reject(error);
      }
    });
  },
  async [INITIALIZE_AUTH](context) {
    this._vm.$msalInstance = new PublicClientApplication(context.state.msalConfig);
  },
  [TRY_SSO_SILENT_LOGIN](context) {
    return this._vm.$msalInstance.ssoSilent({ scopes: context.state.scopes });
  },
  async [LOGIN](context) {
    return await this._vm.$msalInstance.loginRedirect({ scopes: context.state.scopes });
  },
  async [LOGOUT](context) {
    return await this._vm.$msalInstance.logoutRedirect({ acount: context.state.acount });
  },
  async [VERIFY_AUTH](context) {
    if (!this._vm.$msalInstance) {
      context.dispatch(INITIALIZE_AUTH);
    }
    if (context.state.accessToken === '' || context.state.account === null) {
      return await context.dispatch(AUTHENTICATE);
    } else {
      return context.state;
    }
  },
};

const mutations = {
  [SET_LOGIN_REQUIRED](state, isLoginRequired) {
    state.isLoginRequired = isLoginRequired;
  },
  [SET_ACCESS_TOKEN](state, token) {
    state.accessToken = token;
  },
  [SET_ID_TOKEN](state, token) {
    state.idToken = token;
  },
  [SET_ACCOUNT](state, account) {
    applicationInsights.setUserTraceId(account.username);
    state.account = account;
  },
};

function getTokenRedirect(msalInstance, request) {
  request.account = msalInstance.getAllAccounts()[0];
  return msalInstance.acquireTokenSilent(request).catch(error => {
    if (error) {
      return msalInstance.acquireTokenRedirect(request);
    } else {
      console.warn(error);
    }
  });
}

export default {
  state,
  actions,
  mutations,
  getters,
};
