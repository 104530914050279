export const Role = {
  ADMIN: 'Admin',
  READER: 'Reader',
  PM: 'Pm',
  DOM: 'Dom',
  FOM: 'Fom',
  SALES: 'Sales',
  OM: 'Om',
  YIELD: 'Yield',
  AM: 'Am',
  KUNDEN_ADMIN: 'Kunden_Admin',
  AGENTUR_ADMIN: 'Agentur_Admin',
  FREELANCER: 'Freelancer',
  CSM: 'Csm',
};
