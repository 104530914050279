import { getKundenIdFilterOptions } from '@/core/common/services/odata.service';
import { isIdFilterRefreshable } from '@/core/common/helpers/utils';
import * as odataService from '@/core/common/services/odata.service';
import apiService from '@/core/common/services/api.service';

import { convertTableSortToOdata } from '@/core/common/helpers/odataUtils';

import dublettenValidationFilter from '@/core/kunden/dubletten-validation-filter.config.json';

export const SET_APPLIED_FILTER = 'kundeDublettenCleaningFilter.setAppliedKundeDublettenCleaningFilter';

export const GET_KUNDEN = 'kundeDublettenCleaningFilter.getKunden';
export const SET_KUNDEN = 'kundeDublettenCleaningFilter.setKunden';

export const SET_ALL_FILTER_TO_INTIAL = 'kundeDublettenCleaningFilter.resetAllFilter';

export const SET_KUNDEN_ID_FILTER = 'kundeDublettenCleaningFilter.setKundenIdFilter';
export const RESET_KUNDEN_ID_FILTER = 'kundeDublettenCleaningFilter.resetKundenIdFilter';
export const GET_KUNDEN_ID_FILTER_OPTIONS = 'kundeDublettenCleaningFilter.getKundenIdFilterOptions';
export const SET_KUNDEN_ID_FILTER_OPTIONS = 'kundeDublettenCleaningFilter.setKundenIdFilterOptions';

export const KUNDEN_CLEANING_SORT_CHANGED = 'kundeDublettenCleaningFilter.kundenCleaningSortChanged';
export const KUNDEN_CLEANING_PAGE_CHANGED = 'kundeDublettenCleaningFilter.kundenCleaningPageChanged';

export const SET_VALIDATION_STATE_FILTER = 'kundeDublettenCleaningFilter.setValidationStateFilter';
export const GET_VALIDATION_STATE_FILTER = 'kundeDublettenCleaningFilter.getValidationStateFilter';

export const SET_KUNDEN_CLEANING_COUNT = 'kundeDublettenCleaningFilter.setKundenCleaningCount';
export const SET_KUNDEN_CLEANING_TOP = 'kundeDublettenCleaningFilter.setKundenCleaningTop';
export const SET_KUNDEN_CLEANING_SKIP = 'kundeDublettenCleaningFilter.setKundenCleaningSkip';
export const SET_KUNDEN_CLEANING_ORDER_BY = 'kundeDublettenCleaningFilter.setKundenCleaningOrderBy';
export const SET_KUNDEN_CLEANING_IS_BUSY = 'kundeDublettenCleaningFilter.setKundenCleaningIsBusy';
export const SET_KUNDEN_CLEANING_IS_INITIALLY_LOADING =
  'kundeDublettenCleaningFilter.setKundenCleaningIsInitiallyLoading';

export const KUNDEN_CLEANING_TRIGGER_CLEANING_BY_ID =
  'kundeDublettenCleaningFilter.kundenCleaningTriggerCleaningById';
export const KUNDEN_CLEANING_TRIGGER_VALIDATION_RETRY_BY_ID =
  'kundeDublettenCleaningFilter.kundenCleaningTriggerValidationRetryById';
export const KUNDEN_CLEANING_SEND_TRIGGER_BY_ID =
  'kundeDublettenCleaningFilter.kundenCleaningSendTriggerById';
export const KUNDEN_CLEANING_PATCH_VALIDATION_DATA =
  'kundeDublettenCleaningFilter.kundenCleaningPatchValidationData';
export const SET_SINGLE_KUNDE_ROW_KEY = 'kundeDublettenCleaningFilter.setSingleKundeRowKey';
export const MARK_CLEANING_KUNDE_AS_CHANGED = 'kundeDublettenCleaningFilter.markCleaningKundeAsChanged';
export const SAVE_KUNDE_CLEANING_COMMENT = 'kundeDublettenCleaningFilter.saveKundeCleaningComment';

const state = () => ({
  kundenIdFilterOptions: [],
  kunden: [],
  count: 0,
  top: 25,
  skip: 0,
  orderBy: ['updatedDateUtc asc'],
  isBusy: false,
  isInitiallyLoading: true,
  expand: {
    Adresse: { expand: { Strasse: {}, Postleitzahl: {}, Ort: {}, Land: {} } },
    TelefonPrivat: {},
    Name: {},
    TelefonGeschaeftlich: {},
    TelefonMobil: {},
    vorgaenge: {
      filter: {
        and: ['reisedatum ge now()', { blankState: 'Buchung' }],
      },
    },
  },
  filter: {
    kundenIdFilter: [],
    validationStateFilter: 'default',
  },
  appliedKundeDublettenCleaningFilter: {
    kundenIdFilter: [],
    validationStateFilter: 'default',
  },
});
const initialState = { ...state() };

const getters = {
  isKundeDublettenCleaningFilterResettable(state) {
    const initalStateFilter = { ...initialState.filter };
    const appliedKundeDublettenCleaningFilter = { ...state.appliedKundeDublettenCleaningFilter };
    return appliedKundeDublettenCleaningFilter
      ? JSON.stringify(initalStateFilter) !== JSON.stringify(appliedKundeDublettenCleaningFilter)
      : false;
  },
  areAppliedKundeDublettenCleaningFiltersEqualsSetFilters(state) {
    const currentStateFilter = { ...state.filter };
    const appliedKundeDublettenCleaningFilter = { ...state.appliedKundeDublettenCleaningFilter };
    return JSON.stringify(currentStateFilter) === JSON.stringify(appliedKundeDublettenCleaningFilter);
  },
  kundeDublettenCleaningFiltersObject(state) {
    return state.filter;
  },
  areSomeKundeDublettenCleaningFilterSet(state) {
    return Object.values(state.filter).some(val => val !== false);
  },
};

const actions = {
  [GET_KUNDEN](context) {
    context.commit(SET_KUNDEN_CLEANING_IS_BUSY, true);
    getKundenData(
      getQueryArrayFromFilterObject(context.state.filter),
      context.state.expand,
      context.state.top,
      context.state.skip,
      context.state.orderBy
    )
      .then(response => {
        context.commit(SET_KUNDEN_CLEANING_COUNT, response.count);
        context.commit(SET_KUNDEN, response.kunden);
        context.commit(SET_KUNDEN_CLEANING_IS_BUSY, false);
      })
      .finally(() => {
        context.commit(SET_KUNDEN_CLEANING_IS_INITIALLY_LOADING, false);
      });
  },
  [KUNDEN_CLEANING_TRIGGER_CLEANING_BY_ID](context, { id }) {
    context.commit(SET_KUNDEN_CLEANING_IS_BUSY, true);
    return triggerKundenCleaningById(id)
      .then(() => {
        context.commit(SET_KUNDEN_CLEANING_IS_BUSY, false);
        return context.dispatch(GET_KUNDEN, { isOverview: true });
      })
      .catch(() => {
        context.commit(SET_KUNDEN_CLEANING_IS_BUSY, false);
      });
  },
  [KUNDEN_CLEANING_SEND_TRIGGER_BY_ID](context, { id, trigger }) {
    context.commit(SET_KUNDEN_CLEANING_IS_BUSY, true);
    return sendKundenCleaningTriggerById(id, trigger)
      .then(([kunde]) => {
        context.commit(SET_KUNDEN_CLEANING_IS_BUSY, false);
        context.commit(SET_SINGLE_KUNDE_ROW_KEY, {
          kunde: kunde,
          rowKey: 'updatedDateUtc',
          isMarkAsChangedOnly: false,
        });
        return context.dispatch(GET_KUNDEN, { isOverview: true });
      })
      .catch(() => {
        context.commit(SET_KUNDEN_CLEANING_IS_BUSY, false);
      });
  },
  [KUNDEN_CLEANING_PATCH_VALIDATION_DATA](context, { kunde }) {
    context.commit(SET_KUNDEN_CLEANING_IS_BUSY, true);
    return patchKundeCleaningValidationData(kunde)
      .then(() => {
        context.commit(SET_KUNDEN_CLEANING_IS_BUSY, false);
        return context.dispatch(GET_KUNDEN, { isOverview: true });
      })
      .catch(() => {
        context.commit(SET_KUNDEN_CLEANING_IS_BUSY, false);
      });
  },
  [KUNDEN_CLEANING_TRIGGER_VALIDATION_RETRY_BY_ID](context, { id }) {
    context.commit(SET_KUNDEN_CLEANING_IS_BUSY, true);
    return triggerKundenCleaningValidationRetryById(id)
      .then(([kunde]) => {
        context.commit(SET_SINGLE_KUNDE_ROW_KEY, {
          kunde: kunde,
          rowKey: 'updatedDateUtc',
          isMarkAsChangedOnly: false,
        });
      })
      .finally(() => {
        context.commit(SET_KUNDEN_CLEANING_IS_BUSY, false);
      });
  },
  [KUNDEN_CLEANING_SORT_CHANGED](context, { sortContext: sortContext }) {
    const orderBy = convertTableSortToOdata(sortContext.sortBy, sortContext.sortDesc);
    context.commit(SET_KUNDEN_CLEANING_ORDER_BY, orderBy);
    context.commit(SET_KUNDEN_CLEANING_SKIP, 0);
    return context.dispatch(GET_KUNDEN, { isOverview: true });
  },
  [KUNDEN_CLEANING_PAGE_CHANGED](context, { pageContext: pageContext }) {
    context.commit(SET_KUNDEN_CLEANING_SKIP, pageContext.skip);
    context.commit(SET_KUNDEN_CLEANING_TOP, pageContext.top);
    return context.dispatch(GET_KUNDEN, { isOverview: true });
  },
  [GET_KUNDEN_ID_FILTER_OPTIONS](context, { idFilter }) {
    const isRefreshable = isIdFilterRefreshable(idFilter, context.state.kundenIdFilterOptions, 3);
    return isRefreshable
      ? getKundenIdFilterOptions(idFilter).then(options => {
          context.commit(SET_KUNDEN_ID_FILTER_OPTIONS, options);
        })
      : context.commit(SET_KUNDEN_ID_FILTER_OPTIONS, []);
  },
  [MARK_CLEANING_KUNDE_AS_CHANGED](context, { kunde }) {
    context.commit(SET_SINGLE_KUNDE_ROW_KEY, {
      kunde: kunde,
      isMarkAsChangedOnly: true,
    });
  },
  [SAVE_KUNDE_CLEANING_COMMENT](context, { id, comment }) {
    context.commit(SET_KUNDEN_CLEANING_IS_BUSY, true);
    return saveKundeCleaningComment(id, comment)
      .then(() => {
        context.commit(SET_KUNDEN_CLEANING_IS_BUSY, false);
      })
      .catch(() => {
        context.commit(SET_KUNDEN_CLEANING_IS_BUSY, false);
      });
  },
};

const mutations = {
  [SET_KUNDEN](state, kunden) {
    state.kunden = kunden;
  },
  [SET_KUNDEN_CLEANING_COUNT](state, count) {
    state.count = count;
  },
  [SET_KUNDEN_CLEANING_ORDER_BY](state, orderBy) {
    state.orderBy = orderBy;
  },
  [SET_KUNDEN_CLEANING_SKIP](state, skip) {
    state.skip = skip;
  },
  [SET_KUNDEN_CLEANING_TOP](state, top) {
    state.top = top;
  },
  [SET_KUNDEN_CLEANING_IS_INITIALLY_LOADING](state, isInitiallyLoading) {
    state.isInitiallyLoading = isInitiallyLoading;
  },
  [SET_KUNDEN_CLEANING_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },
  [SET_SINGLE_KUNDE_ROW_KEY](state, { kunde, rowKey, isMarkAsChangedOnly = false }) {
    // Find the index of the matching kunde in the kunden array
    const kundeIndex = state.kunden.findIndex(tableKunde => tableKunde.id === kunde.id);

    // If the kunde isn't found in the array, return early
    if (kundeIndex === -1) return;

    // If only marking the kunde as changed, update and return
    if (isMarkAsChangedOnly) {
      state.kunden[kundeIndex] = {
        ...state.kunden[kundeIndex],
        changed: true,
      };
      return;
    }

    // Update the kunde data based on whether a specific column (rowKey) needs to be updated, or the whole kunde
    if (rowKey) {
      state.kunden[kundeIndex] = {
        ...state.kunden[kundeIndex],
        [rowKey]: kunde[rowKey],
        changed: true,
      };
    } else {
      state.kunden[kundeIndex] = {
        ...state.kunden[kundeIndex],
        adresse: {
          ...state.kunden[kundeIndex].adresse,
          validationObject: {
            land: kunde.adresse.land.validation,
            ort: kunde.adresse.ort.validation,
            postleitzahl: kunde.adresse.postleitzahl.validation,
            strasse: kunde.adresse.strasse.validation,
          },
          validation: [
            kunde.adresse.strasse.validation,
            kunde.adresse.postleitzahl.validation,
            kunde.adresse.ort.validation,
            kunde.adresse.land.validation,
          ].join(' '),
        },
        changed: true,
      };
    }
  },
  [SET_VALIDATION_STATE_FILTER](state, validationStateFilterKey) {
    state.filter.validationStateFilter = validationStateFilterKey;
  },
  [SET_KUNDEN_ID_FILTER_OPTIONS](state, options) {
    state.kundenIdFilterOptions = options;
  },
  [SET_KUNDEN_ID_FILTER](state, filter) {
    state.filter.kundenIdFilter = filter;
  },
  [RESET_KUNDEN_ID_FILTER](state) {
    state.filter.kundenIdFilter = [];
  },
  // General mutations
  [SET_APPLIED_FILTER](state, appliedKundeDublettenCleaningFilter) {
    state.appliedKundeDublettenCleaningFilter = { ...appliedKundeDublettenCleaningFilter };
  },
  [SET_ALL_FILTER_TO_INTIAL](state) {
    state.filter.kundenIdFilter = initialState.filter.kundenIdFilter;
    state.filter.validationStateFilter = initialState.filter.validationStateFilter;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

function getQueryArrayFromFilterObject(filterObject) {
  if (!filterObject) return [dublettenValidationFilter[filterObject.validationStateFilter]];
  const kundenIdFilter = buildKundenFilterQuery(filterObject.kundenIdFilter);

  const isDeletedFilter = { isDeleted: false };

  //General filter,  is always set
  const validationStateFilter = dublettenValidationFilter[filterObject.validationStateFilter];

  return [validationStateFilter, kundenIdFilter, isDeletedFilter];
}

function buildKundenFilterQuery(kunden) {
  if (kunden && kunden.length > 0) {
    return { id: { in: kunden.map(kunde => kunde.id) || null } };
  } else {
    return null;
  }
}

function getValueAndValidation(item) {
  return item
    ? {
        validationState: item.validationState,
        errorCodes: item.errorCodes,
        resultCodes: item.resultCodes,
        validation: item.validation,
        value: item.value,
      }
    : null;
}

function parseAdresse(adresse = {}) {
  // Helper function to extract value or validation from address fields
  const getValueOrValidation = (field, type = 'value') => adresse[field]?.[type] || null;

  return {
    // Using optional chaining to simplify checks
    validationState: adresse?.validationState,
    errorCodes: adresse?.errorCodes,
    resultCodes: adresse?.resultCodes,
    valueObject: {
      strasse: getValueOrValidation('strasse'),
      postleitzahl: getValueOrValidation('postleitzahl'),
      ort: getValueOrValidation('ort'),
      land: getValueOrValidation('land'),
    },
    validationObject: {
      strasse: getValueOrValidation('strasse', 'validation'),
      postleitzahl: getValueOrValidation('postleitzahl', 'validation'),
      ort: getValueOrValidation('ort', 'validation'),
      land: getValueOrValidation('land', 'validation'),
    },
    // Combine and filter out falsy values, then join with space
    validation: ['strasse', 'postleitzahl', 'ort', 'land']
      .map(field => getValueOrValidation(field, 'validation'))
      .filter(Boolean)
      .join(' '),
    value: ['strasse', 'postleitzahl', 'ort', 'land']
      .map(field => getValueOrValidation(field))
      .filter(Boolean)
      .join(' '),
  };
}

function prepareKundenData(kunden) {
  return kunden.map(kunde => {
    return {
      ...kunde,
      adresse: parseAdresse(kunde.adresse),
      telefonPrivat: getValueAndValidation(kunde.telefonPrivat),
      telefonGeschaeftlich: getValueAndValidation(kunde.telefonGeschaeftlich),
      telefonMobil: getValueAndValidation(kunde.telefonMobil),
    };
  });
}

function getKundenData(filter, expand, top, skip, orderBy) {
  return odataService
    .getKunde({
      filter: filter,
      expand: expand,
      top: top,
      skip: skip,
      orderBy: orderBy,
      count: true,
    })
    .then(response => {
      return { kunden: prepareKundenData(response.data), count: response.count || 0 };
    })
    .catch(error => {
      return error;
    });
}

function triggerKundenCleaningById(id) {
  return apiService
    .put(`Kunde/trigger-cleaning`, {
      id: id,
    })
    .then(async res => {
      return res;
    })
    .catch(error => {
      return error;
    });
}

function triggerKundenCleaningValidationRetryById(id) {
  return apiService
    .put(`Kunde/${id}/validation/retry`, {
      id: id,
    })
    .then(res => {
      const newKundenArray = res.data && res.data.result ? prepareKundenData([res.data.result]) : [];
      return newKundenArray;
    })
    .catch(error => {
      return error;
    });
}
function sendKundenCleaningTriggerById(id, trigger) {
  return apiService
    .put(`Kunde/${id}/validation/state`, {
      id: id,
      trigger: trigger,
    })
    .then(res => {
      const newKundenArray = res.data && res.data.result ? prepareKundenData([res.data.result]) : [];
      return newKundenArray;
    })
    .catch(error => {
      return error;
    });
}

function patchKundeCleaningValidationData(kunde) {
  return apiService
    .patch(`Kunde/${kunde.id}/validation`, {
      ...kunde,
    })
    .catch(error => {
      console.log(error);
      return error;
    });
}

function saveKundeCleaningComment(id, comment) {
  return apiService.put(`Kunde/${id}/kommentar`, {
    kommentar: comment || '-',
    id: id,
  });
}
