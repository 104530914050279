import apiService from '@/core/common/services/api.service';
import { calculateLastSaturday } from '@/core/common/helpers/utils';
import { sub, format, getISOWeek, parse } from 'date-fns';
import { getRegionsmanagerOfReisekuerzel } from '../../common/helpers/utils';

export const GET_ALL_DATA = 'rmReisebasis.getAllData';
export const GET_HISTORIC_DATA = 'rmReisebasis.getHistoricData';
export const SET_IS_BUSY = 'rmReisebasis.setIsBusy';
export const SET_FORMATTED_DATA = 'rmReisebasis.setFormattedData';
export const SET_FORMATTED_HISTORY_DATA = 'rmReisebasis.setFormattedHistoryData';
export const GET_CONVERSIONS = 'rmReisebasis.getConversions';

export const SET_DATA = 'rmReisebasis.setData';

// this week
export const SET_VERFUEGBAR_DATA = 'rmReisebasis.setVerfuegbarData';
export const SET_NICHT_VERFUEGBAR_DATA = 'rmReisebasis.setNichtVerfuegbarData';
export const SET_AUF_ANFRAGE_DATA = 'rmReisebasis.setAufAnfrageData';
export const SET_AUFPREIS_GT200_DATA = 'rmReisebasis.setAufpreisGt200Data';
export const SET_AUFPREIS_LE200_DATA = 'rmReisebasis.setAufpreisLe200Data';
export const SET_VERFUEGBARKEIT_GESCHAFFEN_DATA = 'rmReisebasis.setVerfuegbarkeitGeschaffenData';
export const SET_VERFUEGBARKEIT_NICHT_GESCHAFFEN_DATA = 'rmReisebasis.setVerfuegbarkeitNichtGeschaffenData';
export const SET_AKZEPTIERT_DATA = 'rmReisebasis.setAkzeptiertData';

// history
export const SET_VERFUEGBAR_HISTORY_DATA = 'rmReisebasis.setVerfuegbarHistoryData';
export const SET_NICHT_VERFUEGBAR_HISTORY_DATA = 'rmReisebasis.setNichtVerfuegbarHistoryData';
export const SET_AUF_ANFRAGE_HISTORY_DATA = 'rmReisebasis.setAufAnfrageHistoryData';
export const SET_AUFPREIS_GT200_HISTORY_DATA = 'rmReisebasis.setAufpreisGt200HistoryData';
export const SET_AUFPREIS_LE200_HISTORY_DATA = 'rmReisebasis.setAufpreisLe200HistoryData';
export const SET_VERFUEGBARKEIT_GESCHAFFEN_HISTORY_DATA =
  'rmReisebasis.setVerfuegbarkeitGeschaffenHistoryData';
export const SET_VERFUEGBARKEIT_NICHT_GESCHAFFEN_HISTORY_DATA =
  'rmReisebasis.setVerfuegbarkeitNichtGeschaffenHistoryData';
export const SET_AKZEPTIERT_HISTORY_DATA = 'rmReisebasis.setAkzeptiertHistoryData';
export const SET_CONVERSION_DATA = 'rmReisebasis.setConversionData';

const state = () => ({
  isBusy: false,
  data: [],
  formattedData: [],
  formattedDataHistory: [],
  verfuegbarData: [],
  nichtVerfuegbarData: [],
  aufAnfrageData: [],
  aufpreisGt200Data: [],
  aufpreisLe200Data: [],
  akzeptiertData: [],
  verfuegbarkeitGeschaffenData: [],
  verfuegbarkeitNichtGeschaffenData: [],

  // history
  verfuegbarHistoryData: [],
  nichtVerfuegbarHistoryData: [],
  aufAnfrageHistoryData: [],
  aufpreisGt200HistoryData: [],
  aufpreisLe200HistoryData: [],
  akzeptiertHistoryData: [],
  verfuegbarkeitGeschaffenHistoryData: [],
  verfuegbarkeitNichtGeschaffenHistoryData: [],
  conversionData: [],
});
const getters = {
  getData(state) {
    return state.data;
  },
  getFormattedData(state) {
    const conversionsAdded = state.formattedData.map(item => {
      const { reise } = item;
      const conversionsOfReise = state.conversionData[reise];
      return { ...item, conversionsOfReise };
    });
    return conversionsAdded;
  },
  getFormattedDataHistory(state) {
    return state.formattedDataHistory;
  },
  getCountForStatusByReisekuerzelAbflughafen: state => (reisekuerzel, abfh, status) => {
    if (status === 'Verfuegbar') {
      return getCount(
        state.verfuegbarData.filter(
          item => item.reisekuerzel === reisekuerzel && item.deutscherAbflughafen === abfh
        )
      );
    } else if (status === 'NichtVerfuegbar') {
      return getCount(
        state.nichtVerfuegbarData.filter(
          item => item.reisekuerzel === reisekuerzel && item.deutscherAbflughafen === abfh
        )
      );
    } else if (status === 'AufAnfrage') {
      return getCount(
        state.aufAnfrageData.filter(
          item => item.reisekuerzel === reisekuerzel && item.deutscherAbflughafen === abfh
        )
      );
    }
  },
  getCountForStatusByReisekuerzelAbflughafenHistory: state => (reisekuerzel, abfh, status, i) => {
    if (status === 'Verfuegbar') {
      return getCount(
        state.verfuegbarHistoryData?.[i]?.filter(
          item => item.reisekuerzel === reisekuerzel && item.deutscherAbflughafen === abfh
        )
      );
    } else if (status === 'NichtVerfuegbar') {
      return getCount(
        state.nichtVerfuegbarHistoryData?.[i]?.filter(
          item => item.reisekuerzel === reisekuerzel && item.deutscherAbflughafen === abfh
        )
      );
    } else if (status === 'AufAnfrage') {
      return getCount(
        state.aufAnfrageHistoryData?.[i]?.filter(
          item => item.reisekuerzel === reisekuerzel && item.deutscherAbflughafen === abfh
        )
      );
    }
  },
  getCountForBearbeitungsstatusByReisekuerzelAbflughafen:
    state => (reisekuerzel, abfh, bearbeitungsStatus) => {
      if (bearbeitungsStatus === 'Akzeptiert') {
        return getCount(
          state.akzeptiertData.filter(
            item =>
              item.reisekuerzel === reisekuerzel &&
              item.deutscherAbflughafen === abfh &&
              item.aufpreisLabel !== 'le200'
          )
        );
      } else if (bearbeitungsStatus === 'VerfuegbarkeitGeschaffen') {
        return getCount(
          state.verfuegbarkeitGeschaffenData.filter(
            item =>
              item.reisekuerzel === reisekuerzel &&
              item.deutscherAbflughafen === abfh &&
              item.aufpreisLabel !== 'le200'
          )
        );
      } else if (bearbeitungsStatus === 'VerfuegbarkeitNichtGeschaffen') {
        return getCount(
          state.verfuegbarkeitNichtGeschaffenData.filter(
            item =>
              item.reisekuerzel === reisekuerzel &&
              item.deutscherAbflughafen === abfh &&
              item.aufpreisLabel !== 'le200'
          )
        );
      }
    },
  getCountForBearbeitungsstatusByReisekuerzelAbflughafenHistory:
    state => (reisekuerzel, abfh, bearbeitungsStatus, i) => {
      if (bearbeitungsStatus === 'Akzeptiert') {
        return getCount(
          state.akzeptiertHistoryData?.[i]?.filter(
            item =>
              item.reisekuerzel === reisekuerzel && item.deutscherAbflughafen === abfh && item.aufpreis > 200
          )
        );
      } else if (bearbeitungsStatus === 'VerfuegbarkeitGeschaffen') {
        return getCount(
          state.verfuegbarkeitGeschaffenHistoryData?.[i]?.filter(
            item =>
              item.reisekuerzel === reisekuerzel && item.deutscherAbflughafen === abfh && item.aufpreis > 200
          )
        );
      } else if (bearbeitungsStatus === 'VerfuegbarkeitNichtGeschaffen') {
        return getCount(
          state.verfuegbarkeitNichtGeschaffenHistoryData?.[i]?.filter(
            item =>
              item.reisekuerzel === reisekuerzel && item.deutscherAbflughafen === abfh && item.aufpreis > 200
          )
        );
      }
    },
  getCountForAufpreisByReisekuerzelAbflughafen: state => (reisekuerzel, abfh, aufpreisLabel) => {
    if (aufpreisLabel === 'gt200') {
      return getCount(
        state.aufpreisGt200Data.filter(
          item => item.reisekuerzel === reisekuerzel && item.deutscherAbflughafen === abfh
        )
      );
    } else if (aufpreisLabel === 'le200') {
      return getCount(
        state.aufpreisLe200Data.filter(
          item => item.reisekuerzel === reisekuerzel && item.deutscherAbflughafen === abfh
        )
      );
    }
  },
  getCountForAufpreisByReisekuerzelAbflughafenHistory: state => (reisekuerzel, abfh, aufpreisLabel, i) => {
    if (aufpreisLabel === 'gt200') {
      return getCount(
        state.aufpreisGt200HistoryData?.[i]?.filter(
          item => item.reisekuerzel === reisekuerzel && item.deutscherAbflughafen === abfh
        )
      );
    } else if (aufpreisLabel === 'le200') {
      return getCount(
        state.aufpreisLe200HistoryData?.[i]?.filter(
          item => item.reisekuerzel === reisekuerzel && item.deutscherAbflughafen === abfh
        )
      );
    }
  },
  getCountOhneBearbeitungstatus: state => (reisekuerzel, abfh, filter) => {
    if (filter === 'aufpreisLe200') {
      return getCount(
        state.aufpreisLe200Data.filter(
          item =>
            item.reisekuerzel === reisekuerzel &&
            item.deutscherAbflughafen === abfh &&
            item.bearbeitungStatus === null
        )
      );
    } else if (filter === 'zuBearbeitende') {
      // N,RQ, J>200 without bearbeitungstatus
      return (
        getCount(
          state.aufpreisGt200Data.filter(
            item =>
              item.reisekuerzel === reisekuerzel &&
              item.deutscherAbflughafen === abfh &&
              item.bearbeitungStatus === null
          )
        ) +
        getCount(
          state.nichtVerfuegbarData.filter(
            item =>
              item.reisekuerzel === reisekuerzel &&
              item.deutscherAbflughafen === abfh &&
              item.bearbeitungStatus === null
          )
        ) +
        getCount(
          state.aufAnfrageData.filter(
            item =>
              item.reisekuerzel === reisekuerzel &&
              item.deutscherAbflughafen === abfh &&
              item.bearbeitungStatus === null
          )
        )
      );
    }
  },
  getCountOhneBearbeitungstatusHistory: state => (reisekuerzel, abfh, filter, i) => {
    if (filter === 'aufpreisLe200') {
      return getCount(
        state.aufpreisLe200HistoryData?.[i]?.filter(
          item =>
            item.reisekuerzel === reisekuerzel &&
            item.deutscherAbflughafen === abfh &&
            item.bearbeitungStatus === null
        )
      );
    } else if (filter === 'zuBearbeitende') {
      // N,RQ, J>200 without bearbeitungstatus
      return (
        getCount(
          state.aufpreisGt200HistoryData?.[i]?.filter(
            item =>
              item.reisekuerzel === reisekuerzel &&
              item.deutscherAbflughafen === abfh &&
              item.bearbeitungStatus === null
          )
        ) +
        getCount(
          state.nichtVerfuegbarHistoryData?.[i]?.filter(
            item =>
              item.reisekuerzel === reisekuerzel &&
              item.deutscherAbflughafen === abfh &&
              item.bearbeitungStatus === null
          )
        ) +
        getCount(
          state.aufAnfrageHistoryData?.[i]?.filter(
            item =>
              item.reisekuerzel === reisekuerzel &&
              item.deutscherAbflughafen === abfh &&
              item.bearbeitungStatus === null
          )
        )
      );
    }
  },
};
const actions = {
  [GET_ALL_DATA](context) {
    const lastSaturdayDate = calculateLastSaturday();
    const filterConfigs = [
      {
        label: 'Keine Verfügbaren',
        aufpreisFilter: null,
        aufpreisLabel: null,
        statusFilter: " and status ne 'Verfuegbar'",
      },
      {
        label: 'Aufpreis <=-200',
        aufpreisLabel: 'le200',
        aufpreisFilter: 'aufpreis le 200',
        statusFilter: " and status eq 'Verfuegbar'",
      },
      {
        label: 'Aufpreis >200',
        aufpreisLabel: 'gt200',
        aufpreisFilter: 'aufpreis gt 200',
        statusFilter: " and status eq 'Verfuegbar'",
      },
    ];
    const req = (date, aufpreisFilter = null, statusFilter = null) =>
      `Flugverfuegbarkeit/?$apply=filter(manuellAbgefragt eq false and abfragedatum ge ${date}${
        aufpreisFilter ? ' and ' + aufpreisFilter : ''
      }${
        statusFilter ? statusFilter : ''
      })/groupby((deutscherAbflughafenIataCode,status,reisetermin/reise/reisekuerzel,reisetermin/reise/region,reisetermin/reise/prioritaet,bearbeitungStatus),aggregate($count as count))`;

    const allRequests = [
      ...filterConfigs.map(item => {
        return { filter: item.label, request: req(lastSaturdayDate, item.aufpreisFilter, item.statusFilter) };
      }),
    ];
    context.commit(SET_IS_BUSY, true);
    return Promise.all(allRequests.map(item => apiService.get(item.request)))
      .then(values => {
        const results = values.map(requestResponse => requestResponse.data.result.value);
        const formattedResponse = results
          .map((entry, i) =>
            entry.map(item => {
              return {
                bearbeitungStatus: item.bearbeitungStatus,
                status: item.status,
                deutscherAbflughafen: item.deutscherAbflughafenIataCode,
                reisekuerzel: item.reisetermin.reise.reisekuerzel,
                prioritaet: item.reisetermin.reise.prioritaet,
                count: item.count,
                label: filterConfigs[i].label,
                aufpreisLabel: filterConfigs[i].aufpreisLabel,
                region: item.reisetermin.reise.region,
              };
            })
          )
          .flat();
        context.commit(SET_DATA, formattedResponse);
        const distinctReisen = [...new Set(formattedResponse.map(item => item.reisekuerzel).flat())]
          .sort()
          .map(reise => ({
            reise,
            prio: formattedResponse.find(item => reise === item.reisekuerzel)?.prioritaet,
            region: formattedResponse.find(item => reise === item.reisekuerzel)?.region,
          }));
        const statusVerfuegbar = formattedResponse.filter(fvc => fvc.status === 'Verfuegbar');
        const statusNichtVerfuegbar = formattedResponse.filter(fvc => fvc.status === 'NichtVerfuegbar');
        const statusAufAnfrage = formattedResponse.filter(fvc => fvc.status === 'AufAnfrage');
        context.commit(SET_AUF_ANFRAGE_DATA, statusAufAnfrage);
        context.commit(SET_NICHT_VERFUEGBAR_DATA, statusNichtVerfuegbar);
        context.commit(SET_VERFUEGBAR_DATA, statusVerfuegbar);

        const aufpreisGt200 = formattedResponse.filter(fvc => fvc.aufpreisLabel === 'gt200');
        context.commit(SET_AUFPREIS_GT200_DATA, aufpreisGt200);
        const aufpreisLe200 = formattedResponse.filter(fvc => fvc.aufpreisLabel === 'le200');
        context.commit(SET_AUFPREIS_LE200_DATA, aufpreisLe200);

        const akzeptiertData = formattedResponse.filter(
          item => item.bearbeitungStatus === 'Akzeptiert' && item.aufpreisLabel !== 'le200'
        );
        const verfuegbarkeitGeschaffenData = formattedResponse.filter(
          item => item.bearbeitungStatus === 'VerfuegbarkeitGeschaffen' && item.aufpreisLabel !== 'le200'
        );
        const verfuegbarkeitNichtGeschaffenData = formattedResponse.filter(
          item => item.bearbeitungStatus === 'VerfuegbarkeitNichtGeschaffen' && item.aufpreisLabel !== 'le200'
        );
        context.commit(SET_AKZEPTIERT_DATA, akzeptiertData);
        context.commit(SET_VERFUEGBARKEIT_GESCHAFFEN_DATA, verfuegbarkeitGeschaffenData);
        context.commit(SET_VERFUEGBARKEIT_NICHT_GESCHAFFEN_DATA, verfuegbarkeitNichtGeschaffenData);
        const abflughaefen = ['FRA', 'MUC', 'DUS', 'HAM', 'BER', 'VIE', 'ZRH'];
        const mix = distinctReisen
          .map(({ reise, prio, region }) => {
            return abflughaefen
              .map(abflughafen => {
                const countGesamt = getCount(
                  formattedResponse.filter(
                    item => item.reisekuerzel === reise && item.deutscherAbflughafen === abflughafen
                  )
                );
                const countVerfuegbarkeitNichtGeschaffen =
                  context.getters.getCountForBearbeitungsstatusByReisekuerzelAbflughafen(
                    reise,
                    abflughafen,
                    'VerfuegbarkeitNichtGeschaffen'
                  );
                const countVerfuegbarkeitGeschaffen =
                  context.getters.getCountForBearbeitungsstatusByReisekuerzelAbflughafen(
                    reise,
                    abflughafen,
                    'VerfuegbarkeitGeschaffen'
                  );
                const countAccepted = context.getters.getCountForBearbeitungsstatusByReisekuerzelAbflughafen(
                  reise,
                  abflughafen,
                  'Akzeptiert'
                );
                const countVerfuegbarLe200 = context.getters.getCountForAufpreisByReisekuerzelAbflughafen(
                  reise,
                  abflughafen,
                  'le200'
                );
                const countVerfuegbarGt200 = context.getters.getCountForAufpreisByReisekuerzelAbflughafen(
                  reise,
                  abflughafen,
                  'gt200'
                );
                const countAufAnfrage = context.getters.getCountForStatusByReisekuerzelAbflughafen(
                  reise,
                  abflughafen,
                  'AufAnfrage'
                );
                const countVerfuegbar = context.getters.getCountForStatusByReisekuerzelAbflughafen(
                  reise,
                  abflughafen,
                  'Verfuegbar'
                );
                const countNichtVerfuegbar = context.getters.getCountForStatusByReisekuerzelAbflughafen(
                  reise,
                  abflughafen,
                  'NichtVerfuegbar'
                );
                const countNochZuBearbeitende = context.getters.getCountOhneBearbeitungstatus(
                  reise,
                  abflughafen,
                  'zuBearbeitende'
                );
                const countAufpreisLe200WithoutBearbeitungstatus =
                  context.getters.getCountOhneBearbeitungstatus(reise, abflughafen, 'aufpreisLe200');
                return {
                  rm: getRegionsmanagerOfReisekuerzel(reise),
                  reise,
                  abfh: abflughafen,
                  prioritaet: prio,
                  region,
                  countAufAnfrage,
                  countVerfuegbar,
                  countNichtVerfuegbar,
                  countGesamt,
                  countVerfuegbarkeitNichtGeschaffen,
                  countVerfuegbarkeitGeschaffen,
                  countAccepted,
                  countVerfuegbarLe200,
                  countVerfuegbarGt200,
                  countEdited:
                    countVerfuegbarkeitGeschaffen + countVerfuegbarkeitNichtGeschaffen + countAccepted,
                  countNotEdited: countNochZuBearbeitende,
                  kpiVorBearbeitung: Math.round((countVerfuegbarLe200 / countGesamt) * 100),
                  kpiNachBearbeitung: Math.round(
                    ((countAccepted +
                      countVerfuegbarkeitGeschaffen +
                      countAufpreisLe200WithoutBearbeitungstatus) /
                      countGesamt) *
                      100
                  ),
                  countAufpreisLe200WithoutBearbeitungstatus,
                };
              })
              .filter(row => row.countGesamt > 0);
          })
          .flat()
          .sort((a, b) => a.prioritaet - b.prioritaet);
        context.commit(SET_FORMATTED_DATA, mix);
      })
      .then(() => {
        context.commit(SET_IS_BUSY, false);
      });
  },
  [GET_HISTORIC_DATA](context) {
    const lastEightWeeks = getLastWeeks(8);
    const reqString = date =>
      'Flugverfuegbarkeit/?$apply=filter(manuellAbgefragt eq false and abfragedatum ge ' +
      date.start +
      ' and abfragedatum le ' +
      date.end +
      ')/groupby((deutscherAbflughafenIataCode,status,reisetermin/reise/reisekuerzel,reisetermin/reise/region,reisetermin/reise/prioritaet,bearbeitungStatus,aufpreis),aggregate($count as count))';
    const allRequests = lastEightWeeks.map(week => reqString(week));
    return Promise.all(allRequests.map(request => apiService.get(request))).then(values => {
      const results = values.map(requestResponse => requestResponse.data.result.value);
      const formattedResponse = results.map((entry, i) => {
        return entry.map(item => {
          return {
            bearbeitungStatus: item.bearbeitungStatus,
            status: item.status,
            deutscherAbflughafen: item.deutscherAbflughafenIataCode,
            reisekuerzel: item.reisetermin.reise.reisekuerzel,
            prioritaet: item.reisetermin.reise.prioritaet,
            akzeptanzgrund: item.akzeptanzgrund,
            count: item.count,
            aufpreis: item.aufpreis,
            region: item.reisetermin.reise.region,
            date: lastEightWeeks[i],
            calendarWeek: getISOWeek(parse(lastEightWeeks[i].start, 'yyyy-MM-dd', new Date())),
          };
        });
      });
      const distinctReisenPerWeek = formattedResponse.map((weekData, i) => {
        return [...new Set(weekData.map(item => item.reisekuerzel).flat())].sort().map(reise => ({
          reise,
          region: formattedResponse[i].find(item => reise === item.reisekuerzel)?.region,
          prio: formattedResponse[i].find(item => reise === item.reisekuerzel)?.prioritaet,
          calendarWeek: formattedResponse[i].find(item => reise === item.reisekuerzel)?.calendarWeek,
        }));
      });
      const statusVerfuegbar = formattedResponse.map(weekData =>
        weekData.filter(fvc => fvc.status === 'Verfuegbar')
      );
      const statusVerfuegbarAufpreisGt200 = formattedResponse.map(weekData =>
        weekData.filter(fvc => fvc.status === 'Verfuegbar' && fvc.aufpreis > 200)
      );
      const statusVerfuegbarAufpreisLe200 = formattedResponse.map(weekData =>
        weekData.filter(fvc => fvc.status === 'Verfuegbar' && fvc.aufpreis <= 200)
      );
      const statusNichtVerfuegbar = formattedResponse.map(weekData =>
        weekData.filter(fvc => fvc.status === 'NichtVerfuegbar')
      );
      const statusAufAnfrage = formattedResponse.map(weekData =>
        weekData.filter(fvc => fvc.status === 'AufAnfrage')
      );
      const akzeptiertData = formattedResponse.map(weekData =>
        weekData.filter(item => item.bearbeitungStatus === 'Akzeptiert' && item.aufpreis > 200)
      );
      const verfuegbarkeitGeschaffenData = formattedResponse.map(weekData =>
        weekData.filter(item => item.bearbeitungStatus === 'VerfuegbarkeitGeschaffen' && item.aufpreis > 200)
      );
      const verfuegbarkeitNichtGeschaffenData = formattedResponse.map(weekData =>
        weekData.filter(
          item => item.bearbeitungStatus === 'VerfuegbarkeitNichtGeschaffen' && item.aufpreis > 200
        )
      );
      // status
      context.commit(SET_AUF_ANFRAGE_HISTORY_DATA, statusAufAnfrage);
      context.commit(SET_NICHT_VERFUEGBAR_HISTORY_DATA, statusNichtVerfuegbar);
      context.commit(SET_VERFUEGBAR_HISTORY_DATA, statusVerfuegbar);

      // bearbeitungsstatus
      context.commit(SET_AKZEPTIERT_HISTORY_DATA, akzeptiertData);
      context.commit(SET_VERFUEGBARKEIT_GESCHAFFEN_HISTORY_DATA, verfuegbarkeitGeschaffenData);
      context.commit(SET_VERFUEGBARKEIT_NICHT_GESCHAFFEN_HISTORY_DATA, verfuegbarkeitNichtGeschaffenData);

      // aufpreis
      context.commit(SET_AUFPREIS_GT200_HISTORY_DATA, statusVerfuegbarAufpreisGt200);
      context.commit(SET_AUFPREIS_LE200_HISTORY_DATA, statusVerfuegbarAufpreisLe200);
      const abflughaefen = ['FRA', 'MUC', 'DUS', 'HAM', 'BER', 'VIE', 'ZRH'];
      const finalData = distinctReisenPerWeek.map((reisen, i) =>
        reisen.map(({ reise, prio, calendarWeek, region }) => {
          return abflughaefen
            .map(abflughafen => {
              const countGesamtHistory = getCount(
                formattedResponse[i].filter(
                  item => item.reisekuerzel === reise && item.deutscherAbflughafen === abflughafen
                )
              );
              const countVerfuegbarHistory =
                context.getters.getCountForStatusByReisekuerzelAbflughafenHistory(
                  reise,
                  abflughafen,
                  'Verfuegbar',
                  i
                );
              const countNichtVerfuegbarHistory =
                context.getters.getCountForStatusByReisekuerzelAbflughafenHistory(
                  reise,
                  abflughafen,
                  'NichtVerfuegbar',
                  i
                );
              const countAufAnfrageHistory =
                context.getters.getCountForStatusByReisekuerzelAbflughafenHistory(
                  reise,
                  abflughafen,
                  'AufAnfrage',
                  i
                );
              const countVerfuegbarkeitNichtGeschaffenHistory =
                context.getters.getCountForBearbeitungsstatusByReisekuerzelAbflughafenHistory(
                  reise,
                  abflughafen,
                  'VerfuegbarkeitNichtGeschaffen',
                  i
                );
              const countVerfuegbarkeitGeschaffenHistory =
                context.getters.getCountForBearbeitungsstatusByReisekuerzelAbflughafenHistory(
                  reise,
                  abflughafen,
                  'VerfuegbarkeitGeschaffen',
                  i
                );
              const countAcceptedHistory =
                context.getters.getCountForBearbeitungsstatusByReisekuerzelAbflughafenHistory(
                  reise,
                  abflughafen,
                  'Akzeptiert',
                  i
                );
              const countVerfuegbarLe200History =
                context.getters.getCountForAufpreisByReisekuerzelAbflughafenHistory(
                  reise,
                  abflughafen,
                  'le200',
                  i
                );
              const countVerfuegbarGt200History =
                context.getters.getCountForAufpreisByReisekuerzelAbflughafenHistory(
                  reise,
                  abflughafen,
                  'gt200',
                  i
                );
              const countNochZuBearbeitendeHistory = context.getters.getCountOhneBearbeitungstatusHistory(
                reise,
                abflughafen,
                'zuBearbeitende',
                i
              );
              return {
                rm: getRegionsmanagerOfReisekuerzel(reise),
                reise,
                abfh: abflughafen,
                prioritaet: prio,
                countAufAnfrageHistory,
                countVerfuegbarHistory,
                countNichtVerfuegbarHistory,
                countGesamtHistory,
                countVerfuegbarkeitNichtGeschaffenHistory,
                countVerfuegbarkeitGeschaffenHistory,
                countAcceptedHistory,
                countVerfuegbarLe200History,
                countVerfuegbarGt200History,
                region,
                countEditedHistory:
                  countVerfuegbarkeitGeschaffenHistory +
                  countVerfuegbarkeitNichtGeschaffenHistory +
                  countAcceptedHistory,
                countNotEditedHistory: countNochZuBearbeitendeHistory,
                kpiVorBearbeitung: Math.round((countVerfuegbarLe200History / countGesamtHistory) * 100),
                kpiNachBearbeitung: Math.round(
                  ((countAcceptedHistory +
                    countVerfuegbarkeitGeschaffenHistory +
                    countVerfuegbarLe200History) /
                    countGesamtHistory) *
                    100
                ),
                calendarWeek,
              };
            })
            .filter(row => row.countGesamtHistory > 0);
        })
      );
      context.commit(
        SET_FORMATTED_HISTORY_DATA,
        finalData.flat(2).sort((a, b) => a.prioritaet - b.prioritaet)
      );
      return finalData.flat(2).sort((a, b) => a.prioritaet - b.prioritaet);
    });
  },
  [GET_CONVERSIONS](context, reisen) {
    context.commit(SET_IS_BUSY, true);

    const commaSeperatedReisekuerzel = reisen.join(',');
    const fromDate = format(sub(new Date(), { days: 365 }), 'yyyy-MM-dd');
    const toDate = format(new Date(), 'yyyy-MM-dd');
    const queryUrl = `https://api.skr.de/zoho-get-stats?code=TlsQD_emiq9WIXh4Iv-y_lxfO1jB1EerIwF9t2DB0V-hAzFuIkfk2w==&kind=conversion&reisekuerzel=${commaSeperatedReisekuerzel}&fromDate=${fromDate}&toDate=${toDate}`;
    return apiService
      .get(queryUrl)
      .then(res => context.commit(SET_CONVERSION_DATA, res.data.conversions))
      .finally(() => context.commit(SET_IS_BUSY, false));
  },
};
const mutations = {
  [SET_DATA](state, data) {
    state.data = data;
  },
  [SET_CONVERSION_DATA](state, data) {
    state.conversionData = data;
  },
  [SET_VERFUEGBAR_DATA](state, data) {
    state.verfuegbarData = data;
  },
  [SET_NICHT_VERFUEGBAR_DATA](state, data) {
    state.nichtVerfuegbarData = data;
  },
  [SET_AUF_ANFRAGE_DATA](state, data) {
    state.aufAnfrageData = data;
  },
  [SET_AUFPREIS_GT200_DATA](state, data) {
    state.aufpreisGt200Data = data;
  },
  [SET_AUFPREIS_LE200_DATA](state, data) {
    state.aufpreisLe200Data = data;
  },
  [SET_VERFUEGBARKEIT_GESCHAFFEN_DATA](state, data) {
    state.verfuegbarkeitGeschaffenData = data;
  },
  [SET_VERFUEGBARKEIT_NICHT_GESCHAFFEN_DATA](state, data) {
    state.verfuegbarkeitNichtGeschaffenData = data;
  },
  [SET_AKZEPTIERT_DATA](state, data) {
    state.akzeptiertData = data;
  },
  [SET_VERFUEGBAR_HISTORY_DATA](state, data) {
    state.verfuegbarHistoryData = data;
  },
  [SET_NICHT_VERFUEGBAR_HISTORY_DATA](state, data) {
    state.nichtVerfuegbarHistoryData = data;
  },
  [SET_AUF_ANFRAGE_HISTORY_DATA](state, data) {
    state.aufAnfrageHistoryData = data;
  },
  [SET_AUFPREIS_GT200_HISTORY_DATA](state, data) {
    state.aufpreisGt200HistoryData = data;
  },
  [SET_AUFPREIS_LE200_HISTORY_DATA](state, data) {
    state.aufpreisLe200HistoryData = data;
  },
  [SET_VERFUEGBARKEIT_GESCHAFFEN_HISTORY_DATA](state, data) {
    state.verfuegbarkeitGeschaffenHistoryData = data;
  },
  [SET_VERFUEGBARKEIT_NICHT_GESCHAFFEN_HISTORY_DATA](state, data) {
    state.verfuegbarkeitNichtGeschaffenHistoryData = data;
  },
  [SET_AKZEPTIERT_HISTORY_DATA](state, data) {
    state.akzeptiertHistoryData = data;
  },
  [SET_FORMATTED_DATA](state, data) {
    state.formattedData = data;
  },
  [SET_FORMATTED_HISTORY_DATA](state, data) {
    state.formattedDataHistory = data;
  },
  [SET_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },
};
const getCount = array => {
  return array.map(item => item.count).reduce((acc, curr) => curr + acc, 0);
};
const getLastWeeks = length => {
  const lastSaturday = calculateLastSaturday();
  const weeks = [];

  for (let i = 0; i < length; i++) {
    const start = format(sub(new Date(lastSaturday), { weeks: i }), 'yyyy-MM-dd');
    const end = format(sub(new Date(lastSaturday), { days: 1, weeks: i - 1 }), 'yyyy-MM-dd');
    weeks.push({ start, end });
  }

  return weeks;
};

export default {
  state,
  actions,
  getters,
  mutations,
};
