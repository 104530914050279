export function buildInteressentenFilterQueryElement(interessentenFilter) {
  if (!interessentenFilter) {
    return null;
  }
  return interessentenFilter.isFreeSpaceSelected
    ? {
        and: [
          { [`pax/ist add ${interessentenFilter.pax}`]: { le: { type: 'guid', value: 'pax/max' } } },
          { [`pax/ist add ${interessentenFilter.pax}`]: { ge: { type: 'guid', value: 'pax/min' } } },
        ],
      }
    : { [`pax/ist add ${interessentenFilter.pax}`]: { le: { type: 'guid', value: 'pax/max' } } };
}

export function buildEditStateQuery(editStateArray, editStateFilterType) {
  if (!Array.isArray(editStateArray) || editStateArray.length === 0) {
    return null;
  }

  const flattenEditState = editStateArray
    .map(editState => {
      return editState.state;
    })
    .flat();

  return {
    [editStateFilterType]: { editState: { in: flattenEditState } },
  };
}

export function buildReiseQueryElement(reisen) {
  if (Array.isArray(reisen) && reisen.length > 0) {
    return { reiseId: { in: reisen.map(reise => reise.id) || null } };
  } else {
    return null;
  }
}

export function buildDateRangeFilterQuery(date, queryKey) {
  return date.start !== null && date.end === null
    ? {
        [queryKey]: {
          eq: { type: 'guid', value: date.start },
        },
      }
    : date.start !== null && date.end !== null
    ? {
        [queryKey]: {
          ge: { type: 'guid', value: date.start },
          le: { type: 'guid', value: date.end },
        },
      }
    : null;
}

export function buildMultiSelectFilterQueryElement(selectedArray) {
  if (Array.isArray(selectedArray) && selectedArray.length > 0) {
    return { or: selectedArray.map(selected => selected.filter) };
  } else {
    return null;
  }
}

export function buildDisplayShiftedFilterQueryElement(displayShifted) {
  if (displayShifted === 0) {
    return { or: [{ aufschiebeDatum: null }, 'aufschiebeDatum lt now()'] };
  } else if (displayShifted === 1) {
    return { and: [{ aufschiebeDatum: { ne: null } }, 'aufschiebeDatum gt now()'] };
  } else if (displayShifted === 2) {
    return null;
  }
}

export function buildQueryElementFromArrayWithProperty(array, property) {
  if (Array.isArray(array) && array.length > 0) {
    return { [property]: { in: array || null } };
  } else {
    return null;
  }
}

export function transformRegionsCountriesFilter(regionFilter, countryFilter) {
  const regionFilterQuery = buildQueryElementFromArrayWithProperty(
    regionFilter.map(region => region.value),
    'region'
  );

  const countryFilterQuery = buildQueryElementFromArrayWithProperty(
    countryFilter.map(country => country.value),
    'country'
  );
  return regionFilterQuery || countryFilterQuery ? [regionFilterQuery, countryFilterQuery] : null;
}

export function buildKuerzelFilterOptionsArray(reisenFilterOptionsArray, kuerzelFilterOptionsArray) {
  const transformedReisekuerzelFilter =
    reisenFilterOptionsArray &&
    reisenFilterOptionsArray.map(filter => ({
      kuerzelId: filter.id,
      kuerzelLabel: filter.reisekuerzel,
      isReisekuerzel: true,
    }));

  const transformedReiseterminkuerzelFilter =
    kuerzelFilterOptionsArray &&
    kuerzelFilterOptionsArray.map(filter => ({
      kuerzelId: filter.id,
      kuerzelLabel: filter.reiseterminkuerzel,
      isReisekuerzel: false,
    }));

  if (
    Array.isArray(transformedReiseterminkuerzelFilter) &&
    transformedReiseterminkuerzelFilter.length !== 0 &&
    Array.isArray(transformedReisekuerzelFilter) &&
    transformedReisekuerzelFilter.length !== 0
  ) {
    return transformedReisekuerzelFilter.concat(transformedReiseterminkuerzelFilter);
  } else if (
    Array.isArray(transformedReiseterminkuerzelFilter) &&
    transformedReiseterminkuerzelFilter.length !== 0
  ) {
    return transformedReiseterminkuerzelFilter;
  } else if (Array.isArray(transformedReisekuerzelFilter) && transformedReisekuerzelFilter.length !== 0) {
    return transformedReisekuerzelFilter;
  } else {
    return [];
  }
}
export function isIdFilterRefreshable(id, currentFilterOptions, startSearchAtLength = 3) {
  // if id is empty set the options to an empty array
  // if the filter is greater than 2 digits start the search
  // if the search id is already in the options array do not search
  const currentObjectInArrayWithSameId = currentFilterOptions.filter(object =>
    object.id.toString().includes(id)
  );
  return id && id.toString().length > startSearchAtLength - 1 && currentObjectInArrayWithSameId.length >= 0;
}

export function buildOdataRangeFilterQuery(odataRange, key) {
  return odataRange
    ? odataRange.min && odataRange.max
      ? {
          and: [
            {
              [key]: { ge: odataRange.min },
            },
            {
              [key]: { le: odataRange.max },
            },
          ],
        }
      : odataRange.min && !odataRange.max
      ? {
          [key]: { ge: odataRange.min },
        }
      : !odataRange.min && odataRange.max
      ? {
          [key]: { le: odataRange.max },
        }
      : null
    : null;
}

export function getReiseterminAvailableInBlankFilter() {
  return {
    and: [
      {
        or: [
          { reiseterminSyncState: 'AvailableInBlank' },
          { reiseterminSyncState: 'AvailableInBlankAndSyncedHotelleistungWithRelations' },
          { reiseterminSyncState: 'AvailableInBlankAndSyncedHotelleistungWithRelationsAndKontingente' },
          {
            reiseterminSyncState:
              'AvailableInBlankAndSyncedHotelleistungWithRelationsAndKontingenteAndZusatzleistungen',
          },
        ],
      },
      { isDeleted: false },
    ],
  };
}

export function buildBearbeiterFilterQuery(bearbeiter) {
  if (bearbeiter && bearbeiter.length > 0) {
    return { bearbeiter: { in: bearbeiter.map(selectedBearbeiter => selectedBearbeiter.id) || null } };
  } else {
    return null;
  }
}

// Helper Methods to build filters
export function buildBlankStateVorgangQuery(editStateArray) {
  if (!Array.isArray(editStateArray) || editStateArray.length === 0) {
    return null;
  }
  const flattenEditState = editStateArray
    .map(editState => {
      return editState.state;
    })
    .flat();

  return {
    hotelleistungen: {
      any: {
        vorgang: {
          blankState: { in: flattenEditState },
        },
      },
    },
  };
}
