import i18n from '@/core/common/plugins/vue-i18n.js';

export const SET_VISIBLE = 'workflowmanager.setVisible';
export const SET_LOADING = 'workflowmanager.setLoading';

export const SET_STATE = 'workflowmanager.setState';
export const SET_STATE_BY_ID = 'workflowmanager.setStateById';

export const SET_REISETERMIN = 'workflowmanager.setReisetermin';
export const SET_TRIGGER = 'workflowmanager.setTrigger';
export const SET_TRIGGER_BY_ID = 'workflowmanager.setTriggerById';
export const SET_TRIGGER_SENT = 'workflowmanager.setTriggerSent';
export const SEND_TRIGGER_CONFIRMED = 'workflowmanager.sendTriggerConfirmed';
export const SET_USER_CONFIRMED = 'workflowmanager.setUserConfirmed';
export const RESET_CONFIRM_MSG_BOX = 'workflowmanager.resetConfirmMsgBox';
export const SET_TEMPTRIGGER = 'workflowmanager.setTempttrigger';

export const SET_LABEL_TITLE = 'workflowmanager.setLabelTitle';

export const SET_STATE_MESSAGE = 'workflowmanager.setStateMessage';
export const SET_NOTIFY_MSG_BOX = 'workflowmanager.setNotifyMsgBox';
export const SET_CONFIRM_MSG_BOX = 'workflowmanager.setConfirmMsgBox';
export const SET_CONFIRM_MSG_BOX_OK = 'workflowmanager.setConfirmMsgBoxOK';

export const GET_NEW_REISETERMIN = 'workflowmanager.getNewReisetermin';
export const SET_REISETERMIN_RELOAD_NEEDED = 'workflowmanager.setReiseterminForReload';
export const OPEN_MODAL = 'workflowmanager.openReiseterminModal';

export const SEND_TRIGGER = 'workflowmanager.sendTrigger';

import apiService from '@/core/common/services/api.service';
import * as odataService from '@/core/common/services/odata.service';
import stateConfig from '@/core/common/states.config.json';
import triggerConfig from '@/core/common/trigger.config.js';
import * as applicationInsights from '@/core/common/services/application-insights.service';

const state = {
  isLoading: true,
  isVisible: false,
  reisetermin: null,
  customState: {},
  title: '',
  stateMsg: '',
  trigger: [],
  triggerSent: { reiseterminId: 0, triggerCode: 0 },
  notifyMsg: {
    text: '',
    options: {},
  },
  confirmMsg: {
    text: '',
    options: {},
  },
  tempTrigger: {
    id: null,
    triggerCode: null,
  },
  userConfirmationNeeded: false,
  needsReload: false,
};

const actions = {
  [OPEN_MODAL](context, id) {
    // if the modal is not visible and needsReload is true -> reload it before setting visible to true
    if (!context.state.isVisible) {
      if (context.state.needsReload) {
        return context.dispatch(GET_NEW_REISETERMIN, id).then(() => {
          context.commit(SET_VISIBLE, true);
          context.commit(SET_REISETERMIN_RELOAD_NEEDED, false);
        });
      } else {
        return context.commit(SET_VISIBLE, true);
      }
    }
  },
  [GET_NEW_REISETERMIN](context, id) {
    //get new state by requesting a new object by id
    const filter = { id: id };
    const expand = {
      pax: {},
      reiseterminReleaseprozess: {},
      comments: {},
      reise: { expand: { konfigurationen: {} } },
      Metadata: { expand: { ZiellandZielflughaefen: {}, ZiellandAbflughaefen: {}, Inlandsfluege: {} } },
    };
    return odataService.getReisetermin({ filter, expand }).then(response => {
      context.commit(SET_REISETERMIN, response.data[0]);
    });
  },
  async [SEND_TRIGGER](context, { id, triggerCode }) {
    const confirmMsg = getTriggerConfirmMsg(triggerCode);
    if (confirmMsg) {
      context.commit(SET_TEMPTRIGGER, { id, triggerCode });
      context.commit(SET_CONFIRM_MSG_BOX, {
        text: confirmMsg,
        options: {
          title: i18n.t('COMMON.BUTTON.DANGER'),
          okTitle: 'OK',
          cancelTitle: 'Abbrechen',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          hideHeaderClose: false,
        },
      });
    } else {
      context.dispatch(SEND_TRIGGER_CONFIRMED, { id, triggerCode });
    }
  },
  [SEND_TRIGGER_CONFIRMED](context, { id, triggerCode }) {
    //send the trigger and use the response as new custom state
    return apiService
      .put(`Reisetermin/${id}/statemachine/trigger/`, {
        id: id,
        trigger: triggerCode,
      })
      .then(({ data }) => {
        const customState = data.result.customState;
        if (
          context.state.customState.groupIdentifier !== customState.groupIdentifier &&
          context.state.customState.groupIdentifier !== null
        ) {
          context.commit(SET_VISIBLE, false);
        }
        // save the trigger as soon at it got sent
        context.commit(SET_TRIGGER_SENT, { id, triggerCode });
        // set the new state
        context.dispatch(SET_STATE_BY_ID, { id, customState });
        // refresh the data for the info panels
        context.dispatch(GET_NEW_REISETERMIN, id);
        // track custom event
        applicationInsights.trackEventWithRouteAndUser(
          { name: 'Send Reisetermin Trigger' },
          buildTrackingDataFromContext(context)
        );
      })
      .catch(error => {
        context.commit(SET_NOTIFY_MSG_BOX, {
          text: 'Diese Aktion konnte nicht durchgeführt werden: ' + JSON.stringify(error.message),
          options: {
            title: 'Fehler',
            okTitle: 'OK',
            cancelTitle: 'Abbrechen',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            hideHeaderClose: false,
          },
        });
        console.error(error);
      });
  },
  [SET_CONFIRM_MSG_BOX_OK](context, isOk) {
    // user pressed ok or abbrechen?
    if (isOk) {
      context.dispatch(SEND_TRIGGER_CONFIRMED, context.state.tempTrigger);
    }
    context.commit(RESET_CONFIRM_MSG_BOX);
  },
  [SET_TRIGGER_BY_ID](context, id) {
    //get new state by requesting a new object by id
    return apiService
      .get(`Reisetermin/${id}/statemachine/trigger`)
      .then(({ data }) => {
        const triggerWithLabels = addLabelsAndConfirmMsgToTriggerArray(data.result.trigger);
        const triggerWithEvaluatedConfirmFunction = evaluateConfirmRequiredFunctionsWithReisetermin(
          triggerWithLabels,
          context.state.reisetermin
        );
        context.commit(SET_TRIGGER, triggerWithEvaluatedConfirmFunction);
      })
      .finally(() => {
        context.commit(SET_LOADING, false);
      });
  },
  async [SET_STATE_BY_ID](context, req) {
    //get new state by requesting a new object by id
    context.commit(SET_LOADING, true);
    context.dispatch(SET_TRIGGER_BY_ID, req.id);
    let customState = req.customState;
    if (!customState) {
      const res = await apiService.get(`Reisetermin/${req.id}/statemachine/state`);
      customState = res.data.result;
    }

    context.commit(SET_STATE, customState);
    context.commit(SET_LABEL_TITLE, getTitleLabel(customState.state, customState.substateOf));
    context.commit(SET_STATE_MESSAGE, getStateMessage(customState.state));
  },
};

function getTitleLabel(state, substateOf) {
  const prefix = substateOf ? i18n.t('REISETERMINE.states.' + substateOf) + ' | ' : '';
  return prefix + i18n.t('REISETERMINE.states.' + state);
}

function getStateMessage(state) {
  return stateConfig.reisetermine[state].hasOwnProperty('msg')
    ? i18n.t('REISETERMINE.statemsg.' + stateConfig.reisetermine[state].msg)
    : null;
}

function evaluateConfirmRequiredFunctionsWithReisetermin(trigger, reisetermin) {
  return trigger.map(t => {
    return {
      ...t,
      isConfirmRequired:
        triggerConfig.reisetermine.hasOwnProperty(t.trigger) &&
        triggerConfig.reisetermine[t.trigger].hasOwnProperty('isConfirmRequiredFunction')
          ? triggerConfig.reisetermine[t.trigger].isConfirmRequiredFunction(reisetermin)
          : true,
    };
  });
}

function addLabelsAndConfirmMsgToTriggerArray(trigger) {
  return trigger.map(t => {
    return {
      ...t,
      LABEL: i18n.t('REISETERMINE.trigger.' + t.trigger),
      confirmMsg:
        triggerConfig.reisetermine.hasOwnProperty(t.trigger) &&
        triggerConfig.reisetermine[t.trigger].hasOwnProperty('confirmmsg')
          ? i18n.t('REISETERMINE.triggermsg.' + triggerConfig.reisetermine[t.trigger].confirmmsg)
          : null,
    };
  });
}

function getTriggerConfirmMsg(triggerCode) {
  const trigger = state.trigger.filter(t => t.trigger === triggerCode)[0];
  return trigger && trigger.isConfirmRequired && trigger.confirmMsg;
}

function buildTrackingDataFromContext(context) {
  return {
    triggerSent: context.state.triggerSent,
    trigger: context.state.trigger,
    customState: context.state.reisetermin.customState,
    reiseterminId: context.state.reisetermin.id,
    reiseId: context.state.reisetermin.reise ? context.state.reisetermin.reise.id : null,
    reisekuerzel: context.state.reisetermin.reise ? context.state.reisetermin.reise.reisekuerzel : null,
    pax: context.state.reisetermin.pax,
    reiseterminReleaseprozess: context.state.reisetermin.reiseterminReleaseprozess,
    startdatum: context.state.reisetermin.startdatum,
    umsatz: context.state.reisetermin.umsatz,
    bearbeiter: context.state.reisetermin.bearbeiter,
  };
}

const mutations = {
  [SET_TRIGGER_SENT](state, triggerSent) {
    state.triggerSent = triggerSent;
  },
  [SET_NOTIFY_MSG_BOX](state, msgBox) {
    state.notifyMsg = msgBox;
  },
  [SET_CONFIRM_MSG_BOX](state, msgBox) {
    state.userConfirmationNeeded = true;
    state.confirmMsg = msgBox;
  },
  [RESET_CONFIRM_MSG_BOX](state) {
    state.userConfirmationNeeded = false;
    state.confirmMsg = {
      text: '',
      options: {},
    };
  },
  [SET_TEMPTRIGGER](state, tempTrigger) {
    state.tempTrigger = tempTrigger;
  },
  [SET_LABEL_TITLE](state, title) {
    state.title = title;
  },
  [SET_STATE_MESSAGE](state, stateMsg) {
    state.stateMsg = stateMsg;
  },
  [SET_VISIBLE](state, visibilityState) {
    state.isVisible = visibilityState;
  },
  [SET_LOADING](state, loadingState) {
    state.isLoading = loadingState;
  },
  [SET_REISETERMIN](state, reisetermin) {
    state.reisetermin = Object.assign({}, reisetermin);
  },
  [SET_REISETERMIN_RELOAD_NEEDED](state, needsReload) {
    state.needsReload = needsReload;
  },
  [SET_STATE](state, customState) {
    state.customState = customState;
  },
  [SET_TRIGGER](state, trigger) {
    state.trigger = trigger;
  },
};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
