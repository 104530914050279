export const INITIALIZE_STATE_STORE = 'stateConfig.initializeStateStore';

export const GET_ALL_STATES = 'stateConfig.getAllStates';
export const SET_ALL_STATES = 'stateConfig.setAllStates';

export const GET_VORGANG_STATES = 'stateConfig.getvorgangBlankStates';
export const SET_VORGANG_STATES = 'stateConfig.setvorgangBlankStates';
export const GET_REISETERMIN_STATES = 'stateConfig.getReiseterminStates';
export const SET_REISETERMIN_STATES = 'stateConfig.setReiseterminStates';

export const GET_TEAMDASHBOARD_FILTER_AND_EXPAND = 'stateConfig.getTeamdashboardAndExpand';

export const SET_TIME_UPDATED = 'stateConfig.setTimeUpdated';
export const SET_STATES_REQUEST_PROMISE = 'stateConfig.setStatesRequestPromise';

export const SET_IS_LOADING = 'stateConfig.setIsLoading';
export const SET_IS_INITIAL_LOADING = 'stateConfig.setIsInitialLoading';

export const GET_KATEGORIEN_CONFIG = 'aufgaben.getKategorienConfig';
export const SET_KATEGORIEN_CONFIG = 'aufgaben.setKategorienConfig';

import apiService from '@/core/common/services/api.service';

import i18n from '@/core/common/plugins/vue-i18n.js';

const moment = require('moment');

const state = {
  isLoading: true,
  isInitialLoading: true,
  vorgangBlankStates: null,
  reiseterminStates: null,
  timeUpdated: null,
  statesRequestPromise: null,
  kategorienConfig: null,
};

const actions = {
  [INITIALIZE_STATE_STORE](context) {
    if (!context.state.timeUpdated || moment().diff(moment(context.state.timeUpdated), 'minutes') > 10) {
      context.dispatch(GET_ALL_STATES);
    } else {
      context.commit(SET_IS_LOADING, false);
    }
  },
  [GET_ALL_STATES](context) {
    context.commit(SET_IS_LOADING, true);

    context.commit(SET_TIME_UPDATED, moment());
    context.dispatch(GET_KATEGORIEN_CONFIG).then(() => {
      context.commit(SET_IS_LOADING, false);
      context.commit(SET_IS_INITIAL_LOADING, false);
    });
  },
  [GET_TEAMDASHBOARD_FILTER_AND_EXPAND](context, role) {
    // If the inital request is not done wait for it and then return the filter and expand
    // If states are set, just use the current state
    if (!context.state.vorgangBlankStates || !context.state.reiseterminStates) {
      context.commit(SET_IS_LOADING, true);
      return context.state.statesRequestPromise
        .then(() => {
          return getTeamdashboardAndExpand(role, context.state);
        })
        .finally(() => {
          context.commit(SET_IS_LOADING, false);
          context.commit(SET_IS_INITIAL_LOADING, false);
        });
    } else {
      return getTeamdashboardAndExpand(role, context.state);
    }
  },
  [GET_KATEGORIEN_CONFIG](context) {
    return apiService.get('/aufgaben/kategorienConfig').then(response => {
      context.commit(SET_KATEGORIEN_CONFIG, response.data.result);
    });
  },
};
const mutations = {
  [SET_TIME_UPDATED](state, time) {
    state.timeUpdated = time;
  },
  [SET_VORGANG_STATES](state, states) {
    state.vorgangBlankStates = states;
  },
  [SET_REISETERMIN_STATES](state, states) {
    state.reiseterminStates = states;
  },
  [SET_STATES_REQUEST_PROMISE](state, statesRequestPromise) {
    state.statesRequestPromise = statesRequestPromise;
  },
  [SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_IS_INITIAL_LOADING](state, isInitialLoading) {
    state.isInitialLoading = isInitialLoading;
  },
  [SET_KATEGORIEN_CONFIG](state, config) {
    state.kategorienConfig = config;
  },
};
const getters = {
  getErgebnisKategorienOptions: state => kategorie => {
    if (state.kategorienConfig) {
      return state.kategorienConfig[kategorie]?.ergebnisKategorienOptions;
    } else return null;
  },
  getAllKategorien: state => {
    if (state.kategorienConfig) {
      return Object.entries(state.kategorienConfig).reduce((acc, curr) => {
        acc.push(curr[1]);
        return acc;
      }, []);
    } else return null;
  },
  getKategorienLabel: state => kategorie => {
    if (state.kategorienConfig) {
      return state.kategorienConfig[kategorie]?.labelFrontend;
    } else {
      return null;
    }
  },
  getKategorienDestinationYield: state => {
    if (state.kategorienConfig) {
      return Object.entries(state.kategorienConfig).reduce((acc, curr) => {
        if (curr[1].isDestinationYieldAufgabe) {
          acc.push(curr[0]);
        }
        return acc;
      }, []);
    } else return [];
  },
  getKategorienWithErgebnisFesthalten: state => {
    if (state.kategorienConfig) {
      return Object.entries(state.kategorienConfig).reduce((acc, curr) => {
        if (curr[1].isErgebnisFesthalten) {
          acc.push(curr[0]);
        }
        return acc;
      }, []);
    } else return [];
  },
  getKategorienManuallyCreatable: state => {
    if (state.kategorienConfig) {
      return Object.entries(state.kategorienConfig).reduce((acc, curr) => {
        if (curr[1].canAddAufgabeManually) {
          acc.push({ text: curr[1].labelFrontend, value: curr[0] });
        }
        return acc;
      }, []);
    } else return null;
  },
  getKategorienReleaseprozess: state => {
    if (state.kategorienConfig) {
      return Object.entries(state.kategorienConfig).reduce((acc, curr) => {
        if (curr[1].isReleaseprozessAufgabe) {
          acc.push(curr[1]);
        }
        return acc;
      }, []);
    } else return [];
  },
  getReiseterminStatesByStateType: state => type => {
    return (
      state.reiseterminStates && state.reiseterminStates.filter(state => state?.stateType?.includes(type))
    );
  },

  getReiseterminBaseStatesFilter: state => {
    return (
      state.reiseterminStates &&
      state.reiseterminStates
        .filter(state => state?.stateType?.includes('BaseState'))
        .map(state => {
          return {
            ...state,
            title: i18n.t(`REISETERMINE.states.${state.state}`),
            class: 'secondary',
            filter: { ['reiseterminState/baseState']: state.state },
          };
        })
    );
  },
  getReiseterminStatesByRole: state => role => {
    return state.reiseterminStates && state.reiseterminStates.filter(state => state?.roles?.includes(role));
  },
};

function getVorgangBlankStates() {
  return new Promise((resolve, reject) => {
    return apiService
      .get('Vorgang?$apply=filter(blankState ne null)/groupby((blankState))')
      .then(res => {
        resolve(
          res?.data?.result?.value.map(state => ({
            state: state.blankState,
            label: i18n.t('VORGAENGE.blankStates.' + state.blankState),
          }))
        );
      })
      .catch(err => {
        reject(err);
      });
  });
}

function getTeamdashboardAndExpand(role, state) {
  const teamdashboardExpand = {
    Metadata: { expand: { ZiellandZielflughaefen: {}, ZiellandAbflughaefen: {} } },
    GueltigeKonfiguration: {},
    reise: { expand: { konfigurationen: {} } },
    comments: {},
    pax: {},
  };
  return {
    expand: teamdashboardExpand,
  };
}

export default {
  state,
  actions,
  mutations,
  getters,
};
