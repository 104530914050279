export const SET_NETWORK_ERROR = 'errorHandler.setFatalError';
export const RESET_NETWORK_ERROR = 'errorHandler.resetFatalError';

export const SET_ERROR_MODAL_MSG = 'errorHandler.setErrorModalMsg';
export const SET_ERROR_TYPE = 'errorHandler.setErrorType';
export const SET_ERROR_NOTIFICATION_MSG = 'errorHandler.setErrorNotificationMsg';

export const DISPLAYED_ERROR_MSG = 'errorHandler.displayedErrorMsg';

export const ADD_NEW_ERROR_INFO = 'errorHandler.addNewErrorInfo';

const state = {
  fatalError: null,
  errorModalMsg: null,
  errorType: null,
  errorNotificationMsg: null,
};
const actions = {
  [ADD_NEW_ERROR_INFO](context, { message: message, type: type }) {
    context.commit(SET_ERROR_NOTIFICATION_MSG, message);
    context.commit(SET_ERROR_TYPE, type);
  },
  [RESET_NETWORK_ERROR](context) {
    context.commit(SET_NETWORK_ERROR, null);
  },
  [DISPLAYED_ERROR_MSG](context) {
    context.commit(SET_NETWORK_ERROR, null);
    context.commit(SET_ERROR_NOTIFICATION_MSG, null);
    context.commit(SET_ERROR_MODAL_MSG, null);
  },
};
const mutations = {
  [SET_NETWORK_ERROR](state, fatalError) {
    state.fatalError = fatalError;
  },
  [SET_ERROR_MODAL_MSG](state, msg) {
    state.errorModalMsg = msg;
  },
  [SET_ERROR_NOTIFICATION_MSG](state, msg) {
    state.errorNotificationMsg = msg;
  },
  [SET_ERROR_TYPE](state, type) {
    state.errorType = type;
  },
};
const getters = {
  currentFatalError: state => {
    return state.fatalError;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
