export const GET_VORGAENGE = 'vorgaenge.getVorgaenge';
export const SET_VORGAENGE = 'vorgaenge.setVorgaenge';

export const SET_VORGANG_RELASEPROCESS_BY_ID = 'vorgaenge.setVorgangReleaseprocessById';

export const GET_VORGAENGE_WITH_FLIGHTS_TEILNEHMER = 'vorgaenge.getVorgaengeWithFlightsTeilnehmer';

export const GET_VORGAENGE_FILTER = 'vorgaenge.getVorgaengeFilter';
export const SET_VORGAENGE_FILTER = 'vorgaenge.setVorgaengeFilter';

export const GET_VORGAENGE_EXPAND = 'vorgaenge.getVorgaengeExpand';
export const SET_VORGAENGE_EXPAND = 'vorgaenge.setVorgaengeExpand';

export const SET_VORGAENGE_COUNT = 'vorgaenge.setVorgaengeCount';
export const SET_VORGAENGE_TOP = 'vorgaenge.setVorgaengeTop';
export const SET_VORGAENGE_SKIP = 'vorgaenge.setVorgaengeSkip';
export const SET_VORGAENGE_ORDER_BY = 'vorgaenge.setVorgaengeOrderBy';

export const VORGAENGE_SORT_CHANGED = 'vorgaenge.sortChanged';
export const VORGAENGE_PAGE_CHANGED = 'vorgaenge.pageChanged';

export const SET_IS_LOADING = 'vorgaenge.setIsLoading';
export const SET_IS_INITIAL_LOADING = 'vorgaenge.setIsInitialLoading';

export const SAVE_VORANG_RELEASEPROCESS_STEP1 = 'vorgaenge.saveVorgangReleaseprocessStep1';
export const SAVE_VORANG_RELEASEPROCESS_STEP1_ARRAY = 'vorgaenge.saveVorgangReleaseprocessStep1Array';
export const SAVE_TRANSFERPLANUNG_PROCESS = 'vorgaenge.saveTransferplanungProcess';
export const RESET_TRANSFERPLANUNG_FLUEGE = 'vorgaenge.resetTransferplanungFluege';

export const GET_TH_WERTE_AT_BUCHUNGSDATUM_BY_VORGANG_ID = 'vorgaenge.getThWerteAtBuchungsdatumByVorgangId';
export const SET_TH_WERTE_AT_BUCHUNGSDATUM_BY_VORGANG_ID = 'vorgaenge.setThWerteAtBuchungsdatumByVorgangId';

export const CHANGE_RELEASEPROCESS_ATTRIBUTES = 'vorgaenge.changeReleaseprocessAttributes';
export const UPDATE_VORGANG_RELEASEPROZESS = 'vorgaenge.updateVorgangReleaseprozess';

import apiService from '@/core/common/services/api.service';
import {
  sortFluegeByAbflugdatumAndAbflugzeit,
  getDifferentFlights,
  parseEigenanreiseText,
} from '@/core/common/helpers/utils';

import * as odataService from '@/core/common/services/odata.service';
import { convertTableSortToOdata } from '@/core/common/helpers/odataUtils';

const state = () => ({
  vorgaenge: [],
  thwerteAtBuchungsdatumByVorgangId: null,
  isLoading: false,
  isInitialLoading: true,
  filter: null,
  top: 100,
  skip: 0,
  orderBy: null,
  count: 0,
  expand: {
    agentur: {},
    hotelleistungen: {
      filter: {
        isDeleted: false,
      },
      expand: {
        Reisetermin: {
          filter: {
            isDeleted: false,
          },
          expand: {
            gueltigeKonfiguration: {},
            Reise: { expand: { konfigurationen: {} } },
            pax: {},
          },
        },
      },
    },
    kunde: {
      expand: {
        master: {
          expand: {
            kunden: {},
          },
        },
      },
    },
  },
});

const getters = {};

const actions = {
  [CHANGE_RELEASEPROCESS_ATTRIBUTES](context, attributes) {},
  [GET_VORGAENGE](context, filter) {
    if (filter) {
      context.commit(SET_VORGAENGE_FILTER, filter);
    }
    context.commit(SET_IS_LOADING, true);
    return odataService
      .getVorgang({
        filter: context.state.filter,
        expand: context.state.expand,
        top: context.state.top,
        skip: context.state.skip,
        count: true,
      })
      .then(response => {
        context.commit(SET_VORGAENGE_COUNT, response.count || 0);
        context.commit(SET_VORGAENGE, response.data);
        return response.data;
      })
      .finally(() => {
        context.commit(SET_IS_LOADING, false);
        context.commit(SET_IS_INITIAL_LOADING, false);
      });
  },
  [GET_VORGAENGE_WITH_FLIGHTS_TEILNEHMER](context, { reiseterminId: reiseterminId, filter: filter }) {
    context.commit(SET_VORGAENGE_FILTER, {
      and: [
        filter && filter,
        {
          hotelleistungen: {
            any: {
              isDeleted: false,
              reiseterminId: parseInt(reiseterminId),
            },
          },
        },
      ],
    });
    context.commit(SET_IS_LOADING, true);
    return odataService
      .getVorgang({
        filter: context.state.filter,
        expand: context.state.expand,
        top: context.state.top,
        skip: context.state.skip,
        count: true,
      })
      .then(async response => {
        const vorgaengeWithFlightsTeilnehmer = await getTeilnehmerAndFluege(response.data, reiseterminId);
        context.commit(SET_VORGAENGE_COUNT, response.count || 0);
        context.commit(SET_VORGAENGE, vorgaengeWithFlightsTeilnehmer);
        return vorgaengeWithFlightsTeilnehmer;
      })
      .finally(() => {
        context.commit(SET_IS_LOADING, false);
        context.commit(SET_IS_INITIAL_LOADING, false);
      });
  },
  [UPDATE_VORGANG_RELEASEPROZESS]({ state }, params) {
    return apiService.put('/Vorgang/releaseprozess', params).then(res => {
      const { result } = res.data;
      const vorgangIds = Object.keys(result);
      for (const vorgangId of vorgangIds) {
        const item = result[vorgangId];
        state.vorgaenge = state.vorgaenge.map(v => {
          if (v.id === +vorgangId) {
            return {
              ...v,
              isReleased: item.isReleased,
              releaseprozessTeilnehmerErledigt: item.releaseprozessTeilnehmerErledigt,
              releaseprozessReiseleistungListenversandErledigt:
                item.releaseprozessReiseleistungListenversandErledigt,
              releaseprozessFluegePassend: item.releaseprozessFluegePassend,
              releaseprozessTransferplanungErledigt: item.releaseprozessTransferplanungErledigt,
            };
          }
          return v;
        });
      }
    });
  },
  [VORGAENGE_SORT_CHANGED](context, { sortContext: sortContext }) {
    const orderBy = convertTableSortToOdata(sortContext.sortBy, sortContext.sortDesc);
    context.commit(SET_VORGAENGE_ORDER_BY, orderBy);
    context.commit(SET_VORGAENGE_SKIP, 0);
    return context.dispatch(GET_VORGAENGE);
  },
  [VORGAENGE_PAGE_CHANGED](context, { pageContext: pageContext }) {
    context.commit(SET_VORGAENGE_SKIP, pageContext.skip);
    context.commit(SET_VORGAENGE_TOP, pageContext.top);
    return context.dispatch(GET_VORGAENGE);
  },
  [SAVE_VORANG_RELEASEPROCESS_STEP1](context, { id: id, releaseProcessObject: releaseProcessObject }) {
    context.commit(SET_IS_LOADING, true);
    return saveReleaseprozessProcess(id, releaseProcessObject)
      .then(response => {
        context.commit(SET_VORGANG_RELASEPROCESS_BY_ID, {
          id: id,
          releaseprocess: {
            gebuchteReiseleistungListenversandGeprueft:
              response[id].gebuchteReiseleistungListenversandGeprueft,
            dokumenteGeprueft: response[id].dokumenteGeprueft,
            passdatenGeprueft: response[id].passdatenGeprueft,
            teilnehmerGeprueft: response[id].teilnehmerGeprueft,
            fluegePassend: response[id].fluegePassend,
          },
          isReleased: response[id].isReleased,
        });
      })
      .finally(() => {
        context.commit(SET_IS_LOADING, false);
        context.commit(SET_IS_INITIAL_LOADING, false);
      });
  },
  [SAVE_VORANG_RELEASEPROCESS_STEP1_ARRAY](
    context,
    { releaseProcessObjectsArray: releaseProcessObjectsArray, reiseterminId: reiseterminId }
  ) {
    context.commit(SET_IS_LOADING, true);
    const mappedValuesReleaseProcessArray = mapReleaseProcessValues(releaseProcessObjectsArray);
    return saveReleaseprozessProcessArray(mappedValuesReleaseProcessArray).finally(() => {
      context.commit(SET_IS_LOADING, false);
      context.commit(SET_IS_INITIAL_LOADING, false);
      return context.dispatch(GET_VORGAENGE_WITH_FLIGHTS_TEILNEHMER, { reiseterminId: reiseterminId });
    });
  },
  [SAVE_TRANSFERPLANUNG_PROCESS](context, { id: id, transferplanungObject: transferplanungObject }) {
    context.commit(SET_IS_LOADING, true);
    transferplanungObject.id = id;
    return saveTransferplanungProcess(id, transferplanungObject).finally(() => {
      context.commit(SET_IS_LOADING, false);
      context.commit(SET_IS_INITIAL_LOADING, false);
    });
  },
  [RESET_TRANSFERPLANUNG_FLUEGE](context, { id: id }) {
    context.commit(SET_IS_LOADING, true);
    return resetTransferplanungFluege(id).finally(() => {
      context.commit(SET_IS_LOADING, false);
      context.commit(SET_IS_INITIAL_LOADING, false);
    });
  },
  [GET_TH_WERTE_AT_BUCHUNGSDATUM_BY_VORGANG_ID](
    context,
    { reiseterminId: reiseterminId, vorgaenge: vorgaenge }
  ) {
    const thwerteAtBuchungsdatumObject = vorgaenge.reduce((acc, cur) => {
      acc[cur.id] = {
        isLoading: true,
        thWert: null,
        basisPreis: null,
      };
      return acc;
    }, {});
    context.commit(SET_TH_WERTE_AT_BUCHUNGSDATUM_BY_VORGANG_ID, thwerteAtBuchungsdatumObject);

    vorgaenge.map(vorgang => {
      return getThWerteAtBuchungsdatum(reiseterminId, vorgang.buchungsdatum, vorgang.id).then(
        ({ data, vorgangId }) => {
          thwerteAtBuchungsdatumObject[vorgangId] = { data, isLoading: false };
          context.commit(SET_TH_WERTE_AT_BUCHUNGSDATUM_BY_VORGANG_ID, thwerteAtBuchungsdatumObject);
        }
      );
    });
    return thwerteAtBuchungsdatumObject;
  },
};

const mutations = {
  [SET_VORGANG_RELASEPROCESS_BY_ID](state, { id, releaseprocess, isReleased }) {
    const vorgang = state.vorgaenge.find(vorgang => vorgang.id === id);
    vorgang.vorgangReleaseprozessStep1 = releaseprocess;
    vorgang.isReleased = isReleased;
  },
  [SET_TH_WERTE_AT_BUCHUNGSDATUM_BY_VORGANG_ID](state, thwerteAtBuchungsdatumByVorgangId) {
    state.thwerteAtBuchungsdatumByVorgangId = thwerteAtBuchungsdatumByVorgangId;
  },
  [SET_VORGAENGE_EXPAND](state, expand) {
    state.filter = expand;
  },
  [SET_VORGAENGE_FILTER](state, filter) {
    state.filter = filter;
  },
  [SET_VORGAENGE](state, vorgaenge) {
    state.vorgaenge = vorgaenge;
  },
  [SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_IS_INITIAL_LOADING](state, isInitialLoading) {
    state.isInitialLoading = isInitialLoading;
  },
  [SET_VORGAENGE_COUNT](state, count) {
    state.count = count;
  },
  [SET_VORGAENGE_ORDER_BY](state, orderBy) {
    state.orderBy = orderBy;
  },
  [SET_VORGAENGE_SKIP](state, skip) {
    state.skip = skip;
  },
  [SET_VORGAENGE_TOP](state, top) {
    state.top = top;
  },
};

async function getTeilnehmerAndFluege(vorgaenge, reiseterminId) {
  let tmpVorgaenge = [...vorgaenge];
  return Promise.all(
    tmpVorgaenge.map(async vorgang => {
      vorgang._showDetails = true;

      vorgang.teilnehmer = await getVorgangTeilnehmerById(vorgang.id, reiseterminId);

      let tmpFluegeData = await getVorgangFluegeById(vorgang.id);
      // sort the flights accoing to dates
      vorgang.fluege = sortFluegeByAbflugdatumAndAbflugzeit(tmpFluegeData.fluege || []);

      // Matchteilnehmer flugIds and flug flugId
      vorgang.fluege.map(flug => {
        // Map the participants of the current vorgang
        vorgang.teilnehmer.map((teilnehmer, teilnehmerIndex) => {
          // If the participant has flight ids and it is an array and the array is not empty
          if (teilnehmer.flugIds && Array.isArray(teilnehmer.flugIds) && teilnehmer.flugIds.length > 0) {
            // If the flight id of the current flight is included in the flight ids of the current participant
            if (teilnehmer.flugIds.includes(flug.flugId)) {
              // Set the match number of the participant to the current index plus one
              teilnehmer.teilnehmerFluegeMatchNumber = teilnehmerIndex + 1;
              // If the current flight has a match numbers array
              if (Array.isArray(flug.teilnehmerFluegeMatchNumbers)) {
                // Add the current index plus one to the match numbers array of the current flight
                flug.teilnehmerFluegeMatchNumbers.push(teilnehmerIndex + 1);
              } else {
                // Create a match numbers array for the current flight and add the current index plus one to it
                flug.teilnehmerFluegeMatchNumbers = [];
                flug.teilnehmerFluegeMatchNumbers.push(teilnehmerIndex + 1);
              }
            }
          }
        });
      });

      // get an array of differences between old and new flights
      const differentFlights = getDifferentFlights(
        tmpFluegeData.fluege,
        tmpFluegeData.fluegeAfterBuchungReleased
      );

      // if atleast one flight is different show it in vorgang table
      vorgang.isFlightsEqual =
        !tmpFluegeData.fluegeAfterBuchungReleased || (differentFlights && differentFlights.length === 0);
      vorgang.allFlights = {
        fluegeAfterBuchungReleased: tmpFluegeData.fluegeAfterBuchungReleased,
        fluege: tmpFluegeData.fluege,
      };
      // Only to set eigenanreise text
      if (!vorgang.eigenanreiseText) return vorgang; // only try to parse text if it is set
      const parsedEigenanreise = parseEigenanreiseText(vorgang);
      const fluegeLength = vorgang.fluege.length;
      if (!parsedEigenanreise || fluegeLength <= 1) return vorgang; // text only contains information about two flights
      if (!parsedEigenanreise.Ankunft) vorgang.failedParse = true;
      vorgang.fluege[0] = {
        // set the first flight infos from parsing function
        ...vorgang.fluege[0],
        flugnummer: parsedEigenanreise.Ankunft ? parsedEigenanreise.Ankunft.flugnummer : null,
        ankunftsflughafenEigen: parsedEigenanreise.Ankunft ? parsedEigenanreise.Ankunft.flughafen : null,
        ankunftsZeit: parsedEigenanreise.Ankunft ? parsedEigenanreise.Ankunft.zeit : null,
      };
      vorgang.fluege[fluegeLength - 1] = {
        // set the last flight infos from parsing function
        ...vorgang.fluege[fluegeLength - 1],
        flugnummer: parsedEigenanreise.Abflug ? parsedEigenanreise.Abflug.flugnummer : null,
        abflughafenEigen: parsedEigenanreise.Abflug ? parsedEigenanreise.Abflug.flughafen : null,
        abflugsZeit: parsedEigenanreise.Abflug ? parsedEigenanreise.Abflug.zeit : null,
      };
      return vorgang;
    })
  );
}

async function getVorgangFluegeById(id) {
  return apiService.get(`Vorgang/${id}/fluege`).then(({ data }) => {
    return {
      fluege: data.result && data.result.fluege && data.result.fluege.filter(flug => !flug.deletedAt),
      fluegeAfterBuchungReleased:
        data.result &&
        data.result.fluegeAfterBuchungReleased &&
        data.result.fluegeAfterBuchungReleased.filter(flug => !flug.deletedAt),
    };
  });
}

async function getVorgangTeilnehmerById(vorgangId, reiseterminId) {
  return apiService.get(`Hotelleistung/${vorgangId}/${reiseterminId}/teilnehmer`).then(({ data }) => {
    return (
      (data.result &&
        data.result.teilnehmer &&
        data.result.teilnehmer.filter(teilnehmer => !teilnehmer.isDeleted)) ||
      []
    );
  });
}

async function getThWerteAtBuchungsdatum(reiseterminId, buchungsdatum, vorgangId) {
  return apiService
    .get(`Reisetermin/${reiseterminId}/historical-prices/${buchungsdatum}`)
    .then(({ data }) => {
      return { data: data?.result?.value || '-', vorgangId: vorgangId };
    });
}

async function saveReleaseprozessProcess(id, releaseProcessObject) {
  return saveReleaseprozessProcessArray([{ ...releaseProcessObject, id }]).then(response => {
    return response;
  });
}

async function saveReleaseprozessProcessArray(releaseProcessObjectsArray) {
  const mappedValuesReleaseProcessArray = mapReleaseProcessValues(releaseProcessObjectsArray);
  return apiService.put('Vorgang/releaseprozesse/', mappedValuesReleaseProcessArray).then(response => {
    return response.data.result;
  });
}

async function saveTransferplanungProcess(id, transferplanungObject) {
  return apiService.put(`Vorgang/releaseprozesse`, transferplanungObject);
}

async function resetTransferplanungFluege(id) {
  return apiService.put(`Vorgang/${id}/workflow/transferplanung/fluege/reset`, { id });
}

function mapReleaseProcessValues(releaseProcessArray) {
  return releaseProcessArray.map(releaseProcessObject => {
    return Object.entries(releaseProcessObject).reduce((acc, key) => {
      acc[key[0]] =
        key[1] === 'NichtGeprueft'
          ? 1
          : key[1] === 'InArbeit'
          ? 2
          : key[1] === 'GeprueftVorhandenKorrekt'
          ? 3
          : key[1] === 'GeprueftNichtNotwendig'
          ? 4
          : key[1] === 'GeprueftNichtKorrektInArbeit'
          ? 5
          : key[1];
      return acc;
    }, {});
  });
}

export default {
  state,
  actions,
  mutations,
  getters,
};
