import {
  isIdFilterRefreshable,
  buildOdataRangeFilterQuery,
  buildBearbeiterFilterQuery,
} from '@/core/common/helpers/filterUtils';

import * as odataService from '@/core/common/services/odata.service';

export const SET_APPLIED_FILTER = 'filterVorgang.setAppliedvorgangFilter';

export const SET_ALL_FILTER_TO_INTIAL = 'filterVorgang.resetAllFilter';

export const SET_VORGANG_ID_FILTER = 'filterVorgang.setvorgangIdFilter';
export const RESET_VORGANG_ID_FILTER = 'filterVorgang.resetvorgangIdFilter';

export const GET_VORGANG_ID_FILTER_OPTIONS = 'filterVorgang.getVorgangIdFilterOptions';
export const SET_VORGANG_ID_FILTER_OPTIONS = 'filterVorgang.setVorgangIdFilterOptions';

export const SET_BEARBEITER = 'filterVorgang.setBearbeiter';
export const RESET_BEARBEITER = 'filterVorgang.resetBearbeiterFilter';
export const SET_DEFAULT_BEARBEITER = 'filterVorgang.setDefaultBearbeiter';

export const SET_UMSATZ_FILTER = 'filterVorgang.setUmsatzFilter';
export const RESET_UMSATZ_FILTER = 'filterVorgang.resetUmsatzFilter';

const state = () => ({
  appliedVorgangFilter: {},
  vorgangIdFilterOptions: [],
  // always set blank filters
  expand: {
    kunde: { expand: { name: {} } },
    agentur: {},
    hotelleistungen: {
      filter: {
        isDeleted: false,
      },
      expand: {
        reisetermin: { expand: { reise: {}, pax: {} } },
      },
    },
  },
  filter: {
    vorgangIdFilter: [],
    selectedBearbeiter: [],

    umsatzFilter: null,
  },
});
const initialState = { ...state() };

const getters = {
  vorgangFilterArray(state) {
    return getQueryArrayFromFilterObject(state.filter);
  },
  isVorgangFilterResettable(state) {
    const initalStateFilter = { ...initialState.filter };
    const appliedVorgangFilter = { ...state.appliedVorgangFilter };
    return appliedVorgangFilter && Array.isArray(state.appliedVorgangFilter.vorgangIdFilter)
      ? JSON.stringify(initalStateFilter) !== JSON.stringify(appliedVorgangFilter)
      : false;
  },
  areAppliedVorgangFiltersEqualsSetFilters(state) {
    const currentStateFilter = { ...state.filter };
    const appliedVorgangFilter = { ...state.appliedVorgangFilter };
    return Array.isArray(state.appliedVorgangFilter.vorgangIdFilter)
      ? JSON.stringify(currentStateFilter) === JSON.stringify(appliedVorgangFilter)
      : true;
  },
  vorgangFiltersObject(state) {
    return state.filter;
  },
  areSomevorgangFilterSet(state) {
    return Object.values(state.filter).some(val => val !== false);
  },
};

const actions = {
  [GET_VORGANG_ID_FILTER_OPTIONS](context, { idFilter }) {
    const isRefreshable = isIdFilterRefreshable(idFilter, context.state.vorgangIdFilterOptions, 5);
    return isRefreshable
      ? getVorgangIdFilterOptions(idFilter).then(options => {
          context.commit(SET_VORGANG_ID_FILTER_OPTIONS, options);
        })
      : context.commit(SET_VORGANG_ID_FILTER_OPTIONS, []);
  },
};

const mutations = {
  [SET_VORGANG_ID_FILTER_OPTIONS](state, options) {
    state.vorgangIdFilterOptions = options;
  },
  [SET_VORGANG_ID_FILTER](state, filter) {
    state.filter.vorgangIdFilter = filter;
  },
  [RESET_VORGANG_ID_FILTER](state) {
    state.filter.vorgangIdFilter = [];
  },
  // General mutations
  [SET_APPLIED_FILTER](state, appliedVorgangFilter) {
    state.appliedVorgangFilter = { ...appliedVorgangFilter };
  },
  [SET_ALL_FILTER_TO_INTIAL](state) {
    state.filter.umsatzFilter = initialState.filter.umsatzFilter;
    state.filter.selectedBearbeiter = initialState.filter.selectedBearbeiter;
    state.filter.vorgangIdFilter = initialState.filter.vorgangIdFilter;
    state.filter.validationStateFilter = initialState.filter.validationStateFilter;
  },
  // Bearbeiter
  [SET_BEARBEITER](state, bearbeiter) {
    state.filter.selectedBearbeiter = Array.isArray(bearbeiter) ? bearbeiter : [bearbeiter];
  },
  [RESET_BEARBEITER](state) {
    state.filter.selectedBearbeiter = [];
  },
  [SET_DEFAULT_BEARBEITER](state, bearbeiter) {
    if (state.filter.selectedBearbeiter.length === 0) {
      state.filter.selectedBearbeiter = Array.isArray(bearbeiter) ? bearbeiter : [bearbeiter];
    }
  },
  // Umsatz Filter
  [SET_UMSATZ_FILTER](state, range) {
    state.filter.umsatzFilter = range;
  },
  [RESET_UMSATZ_FILTER](state) {
    state.filter.umsatzFilter = null;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

function getQueryArrayFromFilterObject(filterObject) {
  if (!filterObject) return [];
  const bearbeiterFilter = buildBearbeiterFilterQuery(filterObject.selectedBearbeiter);
  const vorgangIdFilter = buildvorgangFilterQuery(filterObject.vorgangIdFilter);
  const vorgangDefaultFilter = getVorgangDefaultFilter(); //General filter,  is always set
  const umsatzFilter = buildOdataRangeFilterQuery(filterObject.umsatzFilter, 'brutto');

  return [vorgangIdFilter, vorgangDefaultFilter, bearbeiterFilter, umsatzFilter];
}

function getVorgangDefaultFilter() {
  return {};
}

function getVorgangIdFilterOptions(id = 1) {
  return new Promise((resolve, reject) => {
    return odataService
      .getVorgang({
        filter: `startswith(cast(id,Edm.String),cast(${id},Edm.String))`,
        expand: {
          kunde: {
            expand: {
              name: {},
            },
          },
        },
      })
      .then(res => {
        const vorgaenge = [...res?.data] || [];
        let vorgangOptionsArray = vorgaenge.map(vorgang => {
          const kunde = vorgang?.kunde;
          const name = kunde ? `${kunde?.name?.vorname || ''} ${kunde?.name?.nachname || ''}` : 'Unbekannt';
          return {
            name: name,
            id: vorgang.id,
            label: `${vorgang.id} (${name})`,
          };
        });
        resolve(vorgangOptionsArray);
      })
      .catch(err => {
        reject(err);
      });
  });
}

function buildvorgangFilterQuery(vorgaenge) {
  if (vorgaenge && vorgaenge.length > 0) {
    return { id: { in: vorgaenge.map(vorgang => vorgang.id) || null } };
  } else {
    return null;
  }
}
