import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import router from '@/router';
import store from '@/core/common/services/vuex-store';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.VUE_APP_APPLICATION_INSIGHTS,
  },
});

export function trackEventWithRouteAndUser(event, properties) {
  if (appInsights) {
    appInsights.trackEvent(event, {
      ...properties,
      routeName: router.history.current.name,
      currentUser: store.getters.currentUser,
    });
  }
}

export function trackEvent(event, properties) {
  if (appInsights) {
    appInsights.trackEvent(event, properties);
  }
}
export function trackPageView(name) {
  if (appInsights) {
    appInsights.trackPageView(name);
  }
}

export function setUserTraceId(userId) {
  if (appInsights) {
    appInsights.setAuthenticatedUserContext(userId);
  }
}

export function init() {
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

  if (process.env.VUE_APP_KEEP_LOCAL_CONSOLE_LOG) {
    return;
  }
  // prettier-ignore
  var console = (function (oldConsole) {
    return {
      log() {
        oldConsole.log(...arguments);
        try {
          appInsights.trackTrace({
            message: 'console.log: ' + JSON.stringify(arguments[0]),
            properties: { value: JSON.stringify(arguments[1]) },
            severityLevel: 0,
          });
        } catch (error) {
          console.error('Cyclic JSON.strify cannot be logged in Application Insights');
        }
      },
      info() {
        oldConsole.info(...arguments);
        try {
          appInsights.trackTrace({
            message: 'console.info: ' + JSON.stringify(arguments[0]),
            properties: { value: JSON.stringify(arguments[1]) },
            severityLevel: 1,
          });
        } catch (error) {
          console.error('Cyclic JSON.strify cannot be logged in Application Insights');
        }
      },
      warn() {
        oldConsole.warn(...arguments);
        try {
          appInsights.trackTrace({
            message: 'console.warn: ' + JSON.stringify(arguments[0]),
            properties: { value: JSON.stringify(arguments[1]) },
            severityLevel: 2,
          });
        } catch (error) {
          console.error('Cyclic JSON.strify cannot be logged in Application Insights');
        }
      },
      error() {
        oldConsole.error(...arguments);
        try {
          appInsights.trackTrace({
            message: 'console.error: ' + JSON.stringify(arguments[0]),
            properties: { value: JSON.stringify(arguments[1]) },
            severityLevel: 3,
          });
        } catch (error) {
          console.error('Cyclic JSON.strify cannot be logged in Application Insights');
        }
      },
    };
  })(window.console);

  //Then redefine the old console
  window.console = console;
}
