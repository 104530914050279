import store from '@/core/common/services/vuex-store';
import { SET_NETWORK_ERROR, ADD_NEW_ERROR_INFO } from '@/core/common/stores/error-handler.module';
import router from '@/router';
import i18n from '@/core/common/plugins/vue-i18n.js';

const DEBOUNCE_DELAY = 50;
let debounceTimeoutId = null;
let lastError = null;

export const odataErrorHandler = error => {
  let message,
    type = 'danger';
  if (error.response) {
    if (error.response.status === 401) {
      console.log('Session abgelaufen. Versuche zu erneuern...');
    } else if (error.response.status === 503) {
      message = 'Das System wird derzeit gewartet. Bitte versuchen Sie es später noch einmal.';
      type = 'danger';
    } else if (
      error.response.status === 400 ||
      error.response.status === 500 ||
      error.response.status === 403 ||
      error.response.status === 404
    ) {
      message =
        error.response.data?.responseException?.exceptionMessage?.title ||
        error.response.data?.responseException?.exceptionMessage?.reasons?.join('\r\n') ||
        error.response.data?.responseException?.exceptionMessage?.error?.message ||
        error.response.data?.responseException?.exceptionMessage?.error ||
        error.response.data?.responseException?.exceptionMessage ||
        error.response.data?.responseException ||
        error.response.data;
    } else {
      message = JSON.stringify(error.response);
    }
  } else if (typeof error.response === 'undefined') {
    store.dispatch(SET_NETWORK_ERROR, true);
    if (router.history.current.name !== '403') {
      message = 'VPN oder Netzwerk-Fehler.';
      type = 'danger';
    }
  } else {
    console.log(error);
  }
  if (message === lastError) {
    clearTimeout(debounceTimeoutId);
    debounceTimeoutId = setTimeout(() => {
      store.dispatch(ADD_NEW_ERROR_INFO, { message: message, type: type });
    }, DEBOUNCE_DELAY);
  } else {
    store.dispatch(ADD_NEW_ERROR_INFO, { message: message, type: type });
  }
  lastError = message;
  console.log('beeep!');

  return Promise.reject({ ...error });
};

export const errorHandler = error => {
  let message,
    type = 'danger';
  if (error.response) {
    if (error.response.status === 401) {
      console.log('Session abgelaufen. Versuche zu erneuern...');
    } else if (error.response.status === 503) {
      message = handleErrorMessage({
        response: 'Das System wird derzeit gewartet. Bitte versuchen Sie es später noch einmal.',
      });
    } else if (error.response.status === 500 && !error.response.data.responseException) {
      message = handleErrorMessage({ response: 'Serverfehler. Bitte versuchen Sie es später erneut.' });
    } else {
      message = handleErrorMessage(error.response.data);
    }
  } else if (typeof error.response === 'undefined') {
    store.dispatch(SET_NETWORK_ERROR, true);
    if (router.history.current.name !== '403') {
      message = 'VPN oder Netzwerk-Fehler.';
      type = 'danger';
    }
  } else {
    console.log(error);
  }
  if (message === lastError) {
    clearTimeout(debounceTimeoutId);
    debounceTimeoutId = setTimeout(() => {
      store.dispatch(ADD_NEW_ERROR_INFO, { message: message, type: type });
    }, DEBOUNCE_DELAY);
  } else {
    store.dispatch(ADD_NEW_ERROR_INFO, { message: message, type: type });
  }
  lastError = message;

  return Promise.reject({ ...error });
};

function handleErrorMessage(message) {
  const exceptionMessage = message?.responseException?.exceptionMessage;
  if (message.isError && exceptionMessage) {
    if (!exceptionMessage?.isSuccess && exceptionMessage?.errors) {
      return Object.entries(exceptionMessage.errors)
        .map(([key, value]) => {
          return `${i18n.t(key)}: ${value}`;
        })
        .join('\r\n');
    } else if (exceptionMessage?.error) {
      const errorMessage = exceptionMessage.error?.message;
      return JSON.stringify(errorMessage);
    } else {
      return JSON.stringify(message.responseException.exceptionMessage);
    }
  } else {
    return JSON.stringify(message.response || message);
  }
}

export default errorHandler;
