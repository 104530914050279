export const INITIALIZE_FLUGHAEFEN_STORE = 'flughaefen.initializeFlughaefenStore';

export const SET_FLUGHAEFEN = 'flughaefen.setFlughaefen';

export const SET_IS_LOADING = 'flughaefen.setIsLoading';

export const SET_TIME_UPDATED = 'flughaefen.setTimeUpdated';
export const SET_FLUGHAEFEN_REQUEST_PROMISE = 'flughaefen.setFlughaefenRequestPromise';

import apiService from '@/core/common/services/api.service';
const moment = require('moment');

const state = () => ({
  flughaefen: [],
  isLoading: false,
  timeUpdated: null,
});
const initialState = { ...state() };

const actions = {
  [INITIALIZE_FLUGHAEFEN_STORE](context) {
    if (!context.state.timeUpdated || moment().diff(moment(context.state.timeUpdated), 'minutes') > 10) {
      context.commit(SET_TIME_UPDATED, moment());
      context.commit(SET_IS_LOADING, true);
      return apiService
        .get('Flughaefen')
        .then(response => {
          context.commit(SET_FLUGHAEFEN, response.data.result.value);
          context.commit(SET_IS_LOADING, false);
        })
        .catch(() => {
          context.commit(SET_IS_LOADING, false);
        });
    }
  },
};

const getters = {};

const mutations = {
  [SET_TIME_UPDATED](state, time) {
    state.timeUpdated = time;
  },
  [SET_FLUGHAEFEN_REQUEST_PROMISE](state, flughaefenRequestPromise) {
    state.flughaefenRequestPromise = flughaefenRequestPromise;
  },
  [SET_FLUGHAEFEN](state, flughaefen) {
    state.flughaefen = flughaefen;
  },
  [SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
