import { getKundenMasterIdFilterOptions } from '@/core/common/services/odata.service';
import { isIdFilterRefreshable } from '@/core/common/helpers/utils';

export const SET_APPLIED_FILTER = 'kundeMasterFilter.setAppliedkundeMasterFilter';

export const SET_ALL_FILTER_TO_INTIAL = 'kundeMasterFilter.resetAllFilter';

export const SET_KUNDE_MASTER_ID_FILTER = 'kundeMasterFilter.setkundeMasterIdFilter';
export const RESET_KUNDE_MASTER_ID_FILTER = 'kundeMasterFilter.resetkundeMasterIdFilter';
export const GET_KUNDE_MASTER_ID_FILTER_OPTIONS = 'kundeMasterFilter.getkundeMasterIdFilterOptions';
export const SET_KUNDE_MASTER_ID_FILTER_OPTIONS = 'kundeMasterFilter.setkundeMasterIdFilterOptions';
export const SET_KUNDE_MASTER_ALEAST_ONE_KUNDE_FILTER =
  'kundeMasterFilter.setkundeMasterAtleastOneKundeFilter';

export const SET_KUNDE_MASTER_VALIDATION_STATE_KUNDE_FILTER =
  'kundeMasterFilter.setkundeMasterValidationStateKundeFilter';
const state = () => ({
  appliedKundeMasterFilter: {},
  kundeMasterIdFilterOptions: [],
  // always set blank filters
  filter: {
    kundeMasterIdFilter: [],
    validationStateKundeFilter: [],
    atleastOneKundeFilter: true,
  },
  expand: {
    Kunden: {},
  },
});
const initialState = { ...state() };

const getters = {
  kundeMasterFilterArray(state) {
    return getQueryArrayFromFilterObject(state.filter);
  },
  isKundeMasterFilterResettable(state) {
    const initalStateFilter = { ...initialState.filter };
    const appliedKundeMasterFilter = { ...state.appliedKundeMasterFilter };
    return appliedKundeMasterFilter && Array.isArray(state.appliedKundeMasterFilter.kundeMasterIdFilter)
      ? JSON.stringify(initalStateFilter) !== JSON.stringify(appliedKundeMasterFilter)
      : false;
  },
  areAppliedKundeMasterFiltersEqualsSetFilters(state) {
    const currentStateFilter = { ...state.filter };
    const appliedKundeMasterFilter = { ...state.appliedKundeMasterFilter };
    return Array.isArray(state.appliedKundeMasterFilter.kundeMasterIdFilter)
      ? JSON.stringify(currentStateFilter) === JSON.stringify(appliedKundeMasterFilter)
      : true;
  },
  kundeMasterFiltersObject(state) {
    return state.filter;
  },
  areSomekundeMasterFilterSet(state) {
    return Object.values(state.filter).some(val => val !== false);
  },
};

const actions = {
  [GET_KUNDE_MASTER_ID_FILTER_OPTIONS](context, { idFilter }) {
    const isRefreshable = isIdFilterRefreshable(idFilter, context.state.kundeMasterIdFilterOptions, 3);
    return isRefreshable
      ? getKundenMasterIdFilterOptions(idFilter).then(options => {
          context.commit(SET_KUNDE_MASTER_ID_FILTER_OPTIONS, options);
        })
      : context.commit(SET_KUNDE_MASTER_ID_FILTER_OPTIONS, []);
  },
};

const mutations = {
  [SET_KUNDE_MASTER_ALEAST_ONE_KUNDE_FILTER](state, isAtleastOneKunde) {
    state.filter.atleastOneKundeFilter = isAtleastOneKunde;
  },
  // Base State
  [SET_KUNDE_MASTER_VALIDATION_STATE_KUNDE_FILTER](state, filter) {
    state.filter.validationStateKundeFilter = filter;
  },
  [SET_KUNDE_MASTER_ID_FILTER_OPTIONS](state, options) {
    state.kundeMasterIdFilterOptions = options;
  },
  [SET_KUNDE_MASTER_ID_FILTER](state, filter) {
    state.filter.kundeMasterIdFilter = filter;
  },
  [RESET_KUNDE_MASTER_ID_FILTER](state) {
    state.filter.kundeMasterIdFilter = [];
  },
  // General mutations
  [SET_APPLIED_FILTER](state, appliedKundeMasterFilter) {
    state.appliedKundeMasterFilter = { ...appliedKundeMasterFilter };
  },
  [SET_ALL_FILTER_TO_INTIAL](state) {
    state.filter.kundeMasterIdFilter = initialState.filter.kundeMasterIdFilter;
    state.filter.validationStateKundeFilter = initialState.filter.validationStateKundeFilter;
    state.filter.validationStateKundeFilter = initialState.filter.validationStateKundeFilter;
    state.filter.atleastOneKundeFilter = initialState.filter.atleastOneKundeFilter;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

function getQueryArrayFromFilterObject(filterObject) {
  if (!filterObject) return [];
  const kundeMasterIdFilter = buildkundeMasterFilterQuery(filterObject.kundeMasterIdFilter);
  const kundeMasterDefaultFilter = getKundeMasterDefaultFilter(); //General filter, is always set
  const kundeMasterValidationStateKundeFilter = buildMultiSelectFilterQueryElement(
    filterObject.validationStateKundeFilter
  );
  const isDeletedFilter = { isDeleted: false };
  const atleastOneKundeFilter = filterObject.atleastOneKundeFilter ? { kunden: { $count: { gt: 1 } } } : null;

  return [
    atleastOneKundeFilter,
    kundeMasterValidationStateKundeFilter,
    kundeMasterIdFilter,
    kundeMasterDefaultFilter,
    isDeletedFilter,
  ];
}

function getKundeMasterDefaultFilter() {
  return null;
}

function buildkundeMasterFilterQuery(kunde) {
  if (kunde && kunde.length > 0) {
    return {
      kunden: {
        any: {
          id: { in: kunde.map(kunde => kunde.id) || null },
        },
      },
    };
  } else {
    return null;
  }
}

function buildMultiSelectFilterQueryElement(selectedArray) {
  if (Array.isArray(selectedArray) && selectedArray.length > 0) {
    return { or: selectedArray.map(selected => selected.filter) };
  } else {
    return null;
  }
}
