import apiService from '@/core/common/services/api.service';

export const SET_RELEASEPROCESS_TRIGGERS = 'reiseterminTriggers.setReleaseprocessTriggers';
export const GET_RELEASEPROCESS_TRIGGERS = 'reiseterminTriggers.getReleaseprocessTriggers';
export const TRIGGER_TRIGGER = 'reiseterminTriggers.triggerTrigger';
const state = () => ({
  releaseprocessTriggers: [],
});

const actions = {
  [GET_RELEASEPROCESS_TRIGGERS](context, id) {
    return apiService.get(`/reisetermin/${id}/statemachine/trigger`).then(res => {
      const fetchedTriggers = res.data.result.trigger;
      const systemOnlyTriggers = ['TriggerDurchfuehrung',"TriggerDurchgefuehrt"];
      const filteredTriggers = fetchedTriggers.filter(trigger => !systemOnlyTriggers.includes(trigger.trigger));
      context.commit(SET_RELEASEPROCESS_TRIGGERS, filteredTriggers );
    });
  },
  [TRIGGER_TRIGGER](context, params) {
    const { reiseterminId, trigger } = params;
    return apiService
      .put(`/reisetermin/${reiseterminId}/statemachine/trigger`, { id: reiseterminId, trigger })
      .then(res => {
        context.dispatch(GET_RELEASEPROCESS_TRIGGERS, reiseterminId);
      });
  },
};

const mutations = {
  [SET_RELEASEPROCESS_TRIGGERS](state, triggers) {
    state.releaseprocessTriggers = triggers;
  },
};

export default {
  state,
  actions,
  mutations,
};
