import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Localisation language list
import de from '@/core/common/i18n/de.json';
import en from '@/core/common/i18n/en.json';

Vue.use(VueI18n);
const languages = {
  en: en,
  de: de,
};
const messages = Object.assign(languages);

// get current selected language
const lang = localStorage.getItem('language') || 'de';

var i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'de',
  messages,
});

export default i18n;
