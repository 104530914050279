import Vue from 'vue';

Vue.mixin({
  methods: {
    toast(message, variant = 'success') {
      if (typeof message === 'object') {
        //Warum erfolgt diese Überprüfung?
        if (!Array.isArray(message)) {
          message = Object.values(message);
        }
        for (let m in message) {
          this.$bvToast.toast(message[m].description || message, {
            autoHideDelay: 1000,
            variant: variant,
            noCloseButton: true,
          });
        }
      } else {
        this.$bvToast.toast(message, {
          autoHideDelay: 1000,
          variant: variant,
          noCloseButton: true,
        });
      }
    },
  },
});
