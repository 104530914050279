import { getAgenturMasterIdFilterOptions } from '@/core/common/services/odata.service';
import { isIdFilterRefreshable } from '@/core/common/helpers/utils';

export const GET_AGENTUR_MASTER = 'agenturMaster.getAgenturMaster';
export const SET_AGENTUR_MASTER = 'agenturMaster.setAgenturMaster';

export const GET_AGENTUR_MASTER_VORGAENGE = 'agenturMaster.getAgenturMasterVorgaenge';
export const SET_AGENTUR_MASTER_VORGAENGE = 'agenturMaster.setAgenturMasterVorgaenge';

export const AGENTUR_MASTER_SORT_CHANGED = 'agenturMaster.sortChanged';
export const AGENTUR_MASTER_PAGE_CHANGED = 'agenturmaster.pageChanged';

export const SET_AGENTUR_MASTER_COUNT = 'agenturMaster.setAgenturMasterCount';
export const SET_AGENTUR_MASTER_TOP = 'agenturMaster.setAgenturMasterTop';
export const SET_AGENTUR_MASTER_SKIP = 'agenturMaster.setAgenturMasterSkip';
export const SET_AGENTUR_MASTER_ORDER_BY = 'agenturMaster.setAgenturMasterOrderBy';
export const SET_AGENTUR_MASTER_IS_BUSY = 'agenturMaster.setAgenturMasterIsBusy';

export const SET_ALL_FILTER_TO_INTIAL = 'agenturMaster.resetAllFilter';
export const RESET_FILTER = 'agenturMaster.setAllFilterNull';

export const SET_AGENTUR_MASTER_IS_ANY_FILTER_APPLIED = 'agenturMaster.setAgenturMasterisAnyFilterApplied';
export const SET_AGENTUR_MASTER_IS_CURRENT_FILTER_APPLIED =
  'agenturMaster.setAgenturMasterIsCurrentFilterApplied';

export const SET_AGENTUR_MASTER_ID_FILTER = 'agenturMaster.setAgenturMasterIdFilter';
export const RESET_AGENTUR_MASTER_ID_FILTER = 'agenturMaster.resetAgenturMasterIdFilter';
export const GET_AGENTUR_MASTER_ID_FILTER_OPTIONS = 'agenturMaster.getAgenturMasterIdFilterOptions';
export const SET_AGENTUR_MASTER_ID_FILTER_OPTIONS = 'agenturMaster.setAgenturMasterIdFilterOptions';
export const SET_AGENTUR_MASTER_ALEAST_ONE_AGENTUR_FILTER =
  'agenturMaster.setAgenturMasterAtleastOneAgenturFilter';

import { convertTableSortToOdata } from '@/core/common/helpers/odataUtils';

import * as odataService from '@/core/common/services/odata.service';

const state = () => ({
  agenturMaster: null,
  appliedAgenturMasterFilter: null,
  currentAgenturMasterFilter: null,
  agenturMasterIdFilterOptions: [],
  top: 25,
  skip: 0,
  orderBy: ['updatedDateUtc desc'],
  count: 0,
  isCurrentFilterApplied: true,
  isAnyFilterApplied: false,
  filter: {
    isDeleted: false,
    agenturMasterIdFilter: [],
    atleastOneAgenturFilter: false,
  },
  expand: {
    agenturen: {},
  },
  isBusy: true,
});
const initialState = { ...state() };

const getters = {
  areSomeAgenturMasterFilterSet(state) {
    return Object.values(state.filter).some(val => val !== false);
  },
};

const actions = {
  [GET_AGENTUR_MASTER](context) {
    context.commit(SET_AGENTUR_MASTER_IS_BUSY, true);
    return getAgenturMaster(
      getQueryArrayFromFilterObject(context.state.filter),
      context.state.expand,
      context.state.top,
      context.state.skip,
      context.state.orderBy
    ).then(response => {
      context.commit(SET_AGENTUR_MASTER_IS_CURRENT_FILTER_APPLIED, true);

      context.commit(
        SET_AGENTUR_MASTER_IS_ANY_FILTER_APPLIED,
        compareCurrentFilterWithInitial(context.state.filter, initialState.filter)
      );
      context.commit(SET_AGENTUR_MASTER, response.data || []);
      context.commit(SET_AGENTUR_MASTER_IS_BUSY, false);
      context.commit(SET_AGENTUR_MASTER_COUNT, response.count || 0);
    });
  },
  [AGENTUR_MASTER_SORT_CHANGED](context, { sortContext: sortContext }) {
    if (sortContext.sortBy === 'agenturen') {
      sortContext.sortBy = 'agenturen/$count';
    }
    const orderBy = convertTableSortToOdata(sortContext.sortBy, sortContext.sortDesc);
    context.commit(SET_AGENTUR_MASTER_ORDER_BY, orderBy);
    context.commit(SET_AGENTUR_MASTER_SKIP, 0);
    return context.dispatch(GET_AGENTUR_MASTER);
  },
  [AGENTUR_MASTER_PAGE_CHANGED](context, { pageContext: pageContext }) {
    context.commit(SET_AGENTUR_MASTER_SKIP, pageContext.skip);
    context.commit(SET_AGENTUR_MASTER_TOP, pageContext.top);
    return context.dispatch(GET_AGENTUR_MASTER);
  },
  [GET_AGENTUR_MASTER_ID_FILTER_OPTIONS](context, { idFilter }) {
    const isRefreshable = isIdFilterRefreshable(idFilter, context.state.agenturMasterIdFilterOptions, 3);
    return isRefreshable
      ? getAgenturMasterIdFilterOptions(idFilter).then(options => {
          context.commit(SET_AGENTUR_MASTER_ID_FILTER_OPTIONS, options);
        })
      : context.commit(SET_AGENTUR_MASTER_ID_FILTER_OPTIONS, []);
  },
  [RESET_FILTER](context) {
    context.commit(SET_AGENTUR_MASTER_IS_ANY_FILTER_APPLIED, true);
    context.commit(SET_AGENTUR_MASTER_IS_CURRENT_FILTER_APPLIED, true);
    context.commit(SET_ALL_FILTER_TO_INTIAL);
    return context.dispatch(GET_AGENTUR_MASTER);
  },
  [GET_AGENTUR_MASTER_VORGAENGE](context, { agenturMasterId: agenturMasterId }) {
    const filter = {
      agentur: {
        isDeleted: false,
        id: parseInt(agenturMasterId),
      },
    };
    const expand = {
      hotelleistungen: {
        filter: {
          isDeleted: false,
        },
        expand: {
          Reisetermin: {
            filter: {
              isDeleted: false,
            },
            expand: { Reise: {}, pax: {} },
          },
        },
      },
      agentur: { select: ['id'] },
      kunde: {
        expand: {
          name: {},
        },
      },
    };
    context.commit(SET_AGENTUR_MASTER_IS_BUSY, true);

    return odataService
      .getVorgang({ filter, expand })
      .then(response => {
        context.commit(SET_AGENTUR_MASTER_IS_BUSY, false);
        context.commit(SET_AGENTUR_MASTER_VORGAENGE, { id: agenturMasterId, vorgaenge: response });
        return response;
      })
      .catch(error => {
        console.log(error);
      });
  },
};

const mutations = {
  // set general data und table mutations
  [SET_AGENTUR_MASTER_VORGAENGE](state, { id, vorgaenge }) {
    const agenturMasterWithId = state.agenturMaster.find(agenturMaster => agenturMaster.id === id);
    agenturMasterWithId.vorgaenge = vorgaenge.data;
    agenturMasterWithId.vorgaengeCount = vorgaenge.count;
  },
  [SET_AGENTUR_MASTER](state, agenturMaster) {
    state.agenturMaster = [...agenturMaster];
  },
  [SET_AGENTUR_MASTER_COUNT](state, count) {
    state.count = count;
  },
  [SET_AGENTUR_MASTER_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },
  [SET_AGENTUR_MASTER_ORDER_BY](state, orderBy) {
    state.orderBy = orderBy;
  },
  [SET_AGENTUR_MASTER_SKIP](state, skip) {
    state.skip = skip;
  },
  [SET_AGENTUR_MASTER_TOP](state, top) {
    state.top = top;
  },
  // Filter mutations
  [SET_AGENTUR_MASTER_IS_ANY_FILTER_APPLIED](state, isAnyFilterApplied) {
    state.isAnyFilterApplied = isAnyFilterApplied;
  },
  [SET_AGENTUR_MASTER_IS_CURRENT_FILTER_APPLIED](state, isCurrentFilterApplied) {
    state.isCurrentFilterApplied = isCurrentFilterApplied;
  },
  [SET_AGENTUR_MASTER_ALEAST_ONE_AGENTUR_FILTER](state, isAtleastOneAgentur) {
    state.filter.atleastOneAgenturFilter = isAtleastOneAgentur;
  },
  // Base State
  [SET_AGENTUR_MASTER_ID_FILTER_OPTIONS](state, options) {
    state.agenturMasterIdFilterOptions = options;
  },
  [SET_AGENTUR_MASTER_ID_FILTER](state, filter) {
    state.filter.agenturMasterIdFilter = filter;
  },
  [RESET_AGENTUR_MASTER_ID_FILTER](state) {
    state.filter.agenturMasterIdFilter = [];
  },
  // General mutations
  [SET_ALL_FILTER_TO_INTIAL](state) {
    state.filter.agenturMasterIdFilter = initialState.filter.agenturMasterIdFilter;
    state.filter.atleastOneAgenturFilter = initialState.filter.atleastOneAgenturFilter;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

function getQueryArrayFromFilterObject(filterObject) {
  if (!filterObject) return [];
  const agenturMasterIdFilter = buildAgenturMasterFilterQuery(filterObject.agenturMasterIdFilter);
  const agenturMasterDefaultFilter = getAgenturMasterDefaultFilter(); //General filter, is always set

  const atleastOneAgenturFilter = filterObject.atleastOneAgenturFilter
    ? { agenturen: { $count: { gt: 1 } } }
    : null;

  return [atleastOneAgenturFilter, agenturMasterIdFilter, agenturMasterDefaultFilter];
}

function getAgenturMaster(filter, expand, top, skip, orderBy) {
  return odataService
    .getAgenturMaster({
      filter: filter,
      expand: expand,
      top: top,
      skip: skip,
      orderBy: orderBy,
      count: true,
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      console.log(error);
    });
}

function getAgenturMasterDefaultFilter() {
  return null;
}

function buildAgenturMasterFilterQuery(agentur) {
  if (agentur && agentur.length > 0) {
    return {
      agenturen: {
        any: {
          id: { in: agentur.map(agentur => agentur.id) || null },
        },
      },
    };
  } else {
    return null;
  }
}

function compareCurrentFilterWithInitial(currentFilter, initalFilter) {
  return JSON.stringify(currentFilter) !== JSON.stringify(initalFilter);
}
