import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import errorHandler from '@/core/common/services/error-handler.service';
import store from '@/core/common/services/vuex-store';

import { GET_AUTH_RESPONSE } from '@/core/user/stores/auth.module.js';

let cache = {};

const refreshAuthLogic = failedRequest =>
  store.dispatch(GET_AUTH_RESPONSE).then(tokenRefreshResponse => {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${tokenRefreshResponse.accessToken}`;
    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.accessToken;

    return Promise.resolve();
  });

/**
 * Service to call HTTP request via Axios
 */
const apiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    axios.interceptors.response.use(undefined, async error => {
      // If its not an auth related error call errorHandler
      if (error.response?.status !== 401) {
        return errorHandler(error);
      }
      // return Error object with Promise
      return Promise.reject(error);
    });

    createAuthRefreshInterceptor(axios, refreshAuthLogic);

    // Fetch a new accessToken before every request is considered best practice by microsoft
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
    axios.interceptors.request.use(
      async request => {
        const res = await store.dispatch(GET_AUTH_RESPONSE);
        this.setHeader(res.accessToken);
        return request;
      },
      error => {
        return Promise.reject(error);
      }
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader(token) {
    if (token) {
      Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    return Vue.axios.get(`${resource}${slug}`);
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  getCached(resource, slug = '') {
    const cachedValue = cache[`${resource}${slug}`];
    if (cachedValue) {
      console.log(`get cache for ${resource}${slug}`);
      console.log('with cached value', cachedValue);
      // TODO trigger cache invalidation
      return new Promise(resolve => {
        return resolve(Object.assign({}, cachedValue));
      });
    }

    return Vue.axios.get(`${resource}${slug}`).then(res => {
      console.log(`save cache for ${resource}${slug}`);
      cache[`${resource}${slug}`] = Object.assign({}, res);
      return res;
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, body, params) {
    return Vue.axios.post(`${resource}`, body, { params: params });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PATCH HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, body, params) {
    return Vue.axios.put(`${resource}`, body, { params: params });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, body) {
    return Vue.axios.delete(resource, { data: body });
  },

  /**
   * Send the GET HTTP request with a new token
   * @param resource
   * @param slug
   * @returns {*}
   */
  getWithAuthToken(resource, token, slug = '') {
    //Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Vue.axios.get(`${resource}${slug}`, config);
  },
};

export default apiService;
