export const SET_OWN_USER_ID = 'userSettings.setOwnUserId';
export const INITIALIZE_USER_SETTINGS = 'userSettings.initializeUserSettings';

export const GET_USER_SETTINGS = 'userSettings.getUserSettings';
export const SET_USER_SETTINGS = 'userSettings.setUserSettings';
export const SAVE_DEFAULT_USER_SETTINGS = 'userSettings.saveDefaultUserSettings';
export const SAVE_USER_SETTINGS = 'userSettings.saveUserSettings';

export const SET_TIME_UPDATED = 'userSettings.setTimeUpdated';

import apiService from '@/core/common/services/api.service';
import * as odataService from '@/core/common/services/odata.service';
import fieldsConfig from '@/core/produkte/reisetermine/reisetermin-table-fields.config';
import { tryParseJSONString } from '@/core/common/helpers/utils';
const moment = require('moment');

const state = {
  ownUserId: null,
  timeUpdated: null,
};
const actions = {
  [INITIALIZE_USER_SETTINGS](context, { userId }) {
    context.commit(SET_OWN_USER_ID, userId);
    if (!context.state.timeUpdated || moment().diff(moment(context.state.timeUpdated), 'minutes') > 10) {
      return context.dispatch(GET_USER_SETTINGS, { userId: userId });
    }
  },
  [GET_USER_SETTINGS](context, { userId }) {
    return odataService.getUser({ filter: { idToken: userId || context.ownUserId } }).then(res => {
      context.commit(SET_TIME_UPDATED, moment());
      if (Array.isArray(res.data) && res.data.length > 0) {
        if (res.data[0].hasOwnProperty('settings') && res.data[0].settings) {
          const { data, isParsed } = tryParseJSONString(res.data[0].settings);
          if (isParsed) {
            return context.commit(SET_USER_SETTINGS, data);
          } else {
            return context.dispatch(SAVE_DEFAULT_USER_SETTINGS);
          }
        } else {
          return context.dispatch(SAVE_DEFAULT_USER_SETTINGS);
        }
      } else {
        return context.dispatch(SAVE_DEFAULT_USER_SETTINGS);
      }
    });
  },
  [SAVE_DEFAULT_USER_SETTINGS](context) {
    return context.dispatch(SAVE_USER_SETTINGS);
  },

  [SAVE_USER_SETTINGS](context) {
    return apiService
      .put('User', {
        settings: JSON.stringify(context.state.userStettingsObj),
      })
      .then(res => {
        return res.data.message;
      });
  },
};
const mutations = {
  [SET_TIME_UPDATED](state, time) {
    state.timeUpdated = time;
  },
  [SET_OWN_USER_ID](state, id) {
    state.ownUserId = id;
  },

  [SET_USER_SETTINGS](state, settings) {
    state.userStettingsObj = settings;
  },
};
const getters = {
  userSettingsOwnUserId(state) {
    return state.ownUserId;
  },
};

function getExpandFromFields(fields) {
  if (Array.isArray(fields) && fields.length > 0) {
    return fields.reduce((acc, cur) => {
      if (fieldsConfig[cur].expand) {
        return Object.assign(acc, fieldsConfig[cur].expand);
      } else {
        return acc;
      }
    }, {});
  } else {
    return {};
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
};
