import apiService from '@/core/common/services/api.service';

export const SET_REISETERMIN_EDIT_DATA = 'reiseterminplanung.SET_REISETERMIN_EDIT_DATA';
export const EDIT_REISETERMIN_METADATA = 'reiseterminplanung.EDIT_REISETERMINM_METADATA';
export const EDIT_REISETERMIN_METADATA_MULTIPLE = 'reiseterminplanung.EDIT_REISETERMIN_METADATA_MULTIPLE';
export const BULK_EDIT_REISETERMIN_VALUES = 'reiseterminplanung.BULK_EDIT_REISETERMIN_VALUES';

const state = () => ({
  reiseterminEditData: null,
});

const actions = {
  [EDIT_REISETERMIN_METADATA](context, params) {
    const url = `reise/${params.reisekuerzel}/reisetermine/metadata`;
    return apiService.put(url, params.formattedParams);
  },
  [EDIT_REISETERMIN_METADATA_MULTIPLE](context, params) {
    const url = `reise/${params.reisekuerzel}/reisetermine/metadata`;
    return apiService.put(url, params.formattedParams);
  },
  [BULK_EDIT_REISETERMIN_VALUES](context, params) {
    const url = `reise/${params.reisekuerzel}/reisetermine/bulkUpdateReiseterminValues`;
    return apiService.put(url, params.formattedParams);
  },
};

const mutations = {
  [SET_REISETERMIN_EDIT_DATA](state, data) {
    state.reiseterminEditData = data;
  },
};

export default {
  state,
  actions,
  mutations,
};
