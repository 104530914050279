import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import config from '@/core/common/stores/config.module';
import errorHandler from '@/core/common/stores/error-handler.module';
import htmlClass from '@/core/common/stores/htmlclass.module';
import stateConfig from '@/core/common/stores/state-config.module';

import auth from '@/core/user/stores/auth.module';
import userSettingsLocal from '@/core/user/stores/user-settings-local.module';
import userSettings from '@/core/user/stores/user-settings.module';
import users from '@/core/user/stores/users.module';

import kundeDublettenCleaningFilter from '@/core/kunden/stores/kunden-dubletten-cleaning-filter.module';
import kundeDublettenConfirmationFilter from '@/core/kunden/stores/kunden-dubletten-confirmation-filter.module';
import kundeMasterFilter from '@/core/kunden/stores/kunden-master-filter.module';

import filterVorgang from '@/core/vorgaenge/stores/vorgang-filter.module';

import reisetermine from '@/core/produkte/reisetermine/stores/reisetermine.module';
import flugverfuegbarkeit from '@/core/flugverfuegbarkeit/stores/flugverfuegbarkeit.module';
import reiseterminSearch from '@/core/produkte/reisetermine/stores/reisetermin-search.module';
import workflowManagerReisetermin from '@/core/produkte/reisetermine/stores/workflow-manager-reisetermin.module';

import agenturMaster from '@/core/agenturen/stores/agenturMaster.module';
import agenturDuplicates from '@/core/agenturen/stores/agenturDuplicates.module';

import reisen from '@/core/produkte/reisen/stores/reisen.module';
import reisenMetadata from '@/core/produkte/reisen/stores/reisen-metadata.module';
import kunden from '@/core/kunden/stores/kunden.module';
import vorgaenge from '@/core/vorgaenge/stores/vorgaenge.module';
import flughaefen from '@/core/flughaefen/stores/flughaefen.module';

import kpiFvc from '@/core/flugverfuegbarkeit/stores/kpi-fvc.module';
import rmReisebasis from '@/core/flugverfuegbarkeit/stores/rm-reisebasis.module';
import reisefinder from '@/core/produkte/reisetermine/stores/reisefinder.module';
import reiseterminplanung from '@/core/produkte/reisetermine/stores/reiseterminplanung.module';
import zvc from '@/core/produkte/reisetermine/stores/zvc.module';
import reiseterminTriggers from '@/core/produkte/reisetermine/stores/reisetermin-triggers.module';

import aufgaben from '@/core/aufgaben/stores/aufgaben.module';
import umfragen from '@/core/umfragen/umfragen.module';
import comments from '@/core/comments/comments.module';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    users,
    userSettings,
    userSettingsLocal,
    overview: reisetermine,
    teamdashboard: reisetermine,
    reisefinder,
    reiseterminplanung,
    reisenplanning: reisetermine,
    aufgaben,
    garantiertTable: reiseterminSearch,
    rmReisebasis,
    kpiFvc,
    flugverfuegbarkeit,
    filterVorgang,
    kundeMasterFilter,
    kundeDublettenCleaningFilter,
    kundeDublettenConfirmationFilter,
    workflowManagerReisetermin,
    errorHandler,
    stateConfig,
    reisen,
    reisenMetadata,
    kunden,
    vorgaenge,
    flughaefen,
    reiseterminTriggers,
    agenturMaster,
    agenturDuplicates,
    zvc,
    umfragen,
    comments,
  },
  plugins: [
    createPersistedState({
      paths: [
        'flughaefen.flughaefen',
        'flughaefen.timeUpdated',
        'stateConfig.vorgangBlankStates',
        'stateConfig.reiseterminStates',
        'userSettingsLocal.lastVisitedReiseterminId',
        'userSettingsLocal.lastVisitedReiseterminKuerzel',
        'userSettingsLocal.alwaysHideReiseterminCompactHeader',
        'userSettingsLocal.hideRoleIndicators',
        'userSettingsLocal.isUsingCustomRoles',
        'userSettingsLocal.isAnimationEnabled',
        'userSettingsLocal.customRoles',
      ],
    }),
  ],
});
