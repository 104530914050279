import apiService from '@/core/common/services/api.service';

import { isOptionsRefreshableByValueAndKey } from '@/core/common/helpers/utils';
import {
  getReiseterminAvailableInBlankFilter,
  buildOdataRangeFilterQuery,
  buildBearbeiterFilterQuery,
  buildMultiSelectFilterQueryElement,
  buildReiseQueryElement,
  buildQueryElementFromArrayWithProperty,
  buildDateRangeFilterQuery,
  buildBlankStateVorgangQuery,
  buildInteressentenFilterQueryElement,
  transformRegionsCountriesFilter,
} from '@/core/common/helpers/filterUtils.js';
import {
  getReiseterminKuerzelFilterOptions,
  getReiseFilterOptions,
} from '@/core/common/services/odata.service';

import * as odataService from '@/core/common/services/odata.service';
import { convertTableSortToOdata } from '@/core/common/helpers/odataUtils';
import { isObjectJSONStringifyEqual } from '@/core/common/helpers/utils';

export const SET_APPLIED_FILTER = 'reisetermine.setAppliedReiseterminFilter';

export const GET_REISETERMINE = 'reisetermine.getReisetermine';
export const SET_REISETERMINE = 'reisetermine.setReisetermine';
export const SET_REISETERMINE_TOP = 'reisetermine.setReisetermineTop';
export const SET_REISETERMINE_SKIP = 'reisetermine.setReisetermineSkip';
export const SET_REISETERMINE_ORDER_BY = 'reisetermine.setReisetermineOrderBy';
export const SET_REISETERMINE_IS_BUSY = 'reisetermine.setReisetermineIsBusy';
export const SET_REISETERMINE_COUNT = 'reisetermine.setReisetermineCount';
export const SET_IS_INITIALLY_LOADING = 'reisetermine.setIsInitiallyLoading';

export const SET_IS_ANY_FILTER_APPLIED = 'reisetermine.setIsAnyFilterApplied';
export const SET_IS_CURRENT_FILTER_APPLIED = 'reisetermine.setIsCurrentFilterApplied';

export const REISETERMINE_SORT_CHANGED = 'reisetermine.sortChanged';
export const REISETERMINE_PAGE_CHANGED = 'reisetermine.pageChanged';

export const UPDATE_REISETERMIN_BY_ID = 'reisetermine.updateReiseterminById';
export const SET_REISETERMIN_BY_ID = 'reisetermine.setReiseterminById';

export const SAVED_FILTER = 'reisetermine.savedFilter';
export const RESET_FILTER = 'reisetermine.resetFilter';

export const SET_BEARBEITER = 'reisetermine.setBearbeiter';
export const RESET_BEARBEITER = 'reisetermine.resetBearbeiterFilter';
export const SET_DEFAULT_BEARBEITER = 'reisetermine.setDefaultBearbeiter';

export const SET_BASE_STATE_FILTER = 'reisetermine.setBaseStateFilter';
export const RESET_BASE_STATE = 'reisetermine.resetBaseStateFilter';

export const SET_PAX_FILTER = 'reisetermine.setPaxFilter';
export const RESET_PAX_FILTER = 'reisetermine.resetPaxFilter';

export const SET_REISE_FILTER = 'reisetermine.setReiseFilter';
export const RESET_REISE_FILTER = 'reisetermine.resetReiseFilter';
export const GET_REISE_FILTER_OPTIONS = 'reisetermine.getReiseFilterOptions';
export const SET_REISE_FILTER_OPTIONS = 'reisetermine.setReiseFilterOptions';

export const SET_REGION_FILTER = 'reisetermine.setRegionFilter';
export const RESET_REGION_FILTER = 'reisetermine.resetRegionFilter';

export const SET_COUNTRY_FILTER = 'reisetermine.setCountryFilter';
export const RESET_COUNTRY_FILTER = 'reisetermine.resetCountryFilter';

export const SET_START_DATE_FILTER = 'reisetermine.setstartDateFilterFilter';
export const RESET_START_DATE_FILTER = 'reisetermine.resetstartDateFilterFilter';

export const SET_RELEASE_DATE_FILTER = 'reisetermine.setreleaseDateFilterFilter';
export const RESET_RELEASE_DATE_FILTER = 'reisetermine.resetreleaseDateFilterFilter';

export const SET_ABREISE_DATE_FILTER = 'reisetermine.setabreiseDateFilterFilter';
export const RESET_ABREISE_DATE_FILTER = 'reisetermine.resetabreiseDateFilterFilter';

export const SET_UMSATZ_FILTER = 'reisetermine.setUmsatzFilter';
export const RESET_UMSATZ_FILTER = 'reisetermine.resetUmsatzFilter';

export const SET_BASIS_PREIS_FILTER = 'reisetermine.setBasisPreisFilter';
export const RESET_BASIS_PREIS_FILTER = 'reisetermine.resetBasisPreisFilter';

export const SET_FRIST_FILTER = 'reisetermine.setFristFilter';
export const RESET_FRIST_FILTER = 'reisetermine.resetFristFilter';

export const SET_BLANK_STATE_VORGANG_FILTER = 'reisetermine.setblankStateVorgangFilter';
export const SET_BLANK_STATE_VORGANG_FILTER_OPTIONS = 'reisetermine.setblankStateVorgangFilterOptions';
export const RESET_BLANK_STATE_VORGANG_FILTER = 'reisetermine.resetblankStateVorgangFilter';

export const SET_GARANTIERT_FILTER = 'reisetermine.setGarantiertFilter';
export const RESET_GARANTIERT_FILTER = 'reisetermine.resetGarantiertFilter';

export const SET_STOP_SALE_FILTER = 'reisetermine.setStopSaleFilter';
export const RESET_STOP_SALE_FILTER = 'reisetermine.resetStopSaleFilter';

export const SET_REISETERMIN_KUERZEL_FILTER = 'reisetermine.setReiseterminKuerzelFilter';
export const RESET_REISETERMIN_KUERZEL_FILTER = 'reisetermine.resetReiseterminKuerzelFilter';
export const GET_REISETERMIN_KUERZEL_FILTER_OPTIONS = 'reisetermine.getReiseterminKuerzelFilterOptions';
export const SET_REISETERMIN_KUERZEL_FILTER_OPTIONS = 'reisetermine.setReiseterminKuerzelFilterOptions';

export const UPDATE_EXPAND_AND_FILTER_FOR_ROLE = 'reisetermine.updateExpandAndFilterForRole';
export const SET_ROLE_FILTER = 'reisetermine.setRoleFilter';
export const RESET_ROLE_FILTER = 'reisetermine.resetRoleFilter';

export const SET_EXPAND = 'reisetermine.setExpand';
export const RESET_EXPAND_TO_INITIAL = 'reisetermine.resetExpandToInitial';

export const SET_INTERESSENTEN_FILTER = 'reisetermine.setInteressentenFilter';
export const RESET_INTERESSENTEN_FILTER = 'reisetermine.resetInteressentenFilter';

export const SET_ALL_FILTER_TO_INTIAL = 'reisetermine.setAllFilterToInitial';
export const SET_QUERY_COMPUTE = 'reisetermine.setQueryCompute';

export const GET_FILTER_OPTIONS = 'reisetermine.getFilterOptions';

export const GET_VORAENGE_FOR_REISETERMIN_BY_ID = 'reisetermine.getVoraengeForReiseterminById';
const state = () => ({
  appliedReiseterminFilter: null,
  reisetermine: [],
  top: 25,
  skip: 0,
  orderBy: 'startdatum asc',
  count: 0,
  isInitiallyLoading: true,
  isBusy: true,
  isCurrentFilterApplied: true,
  isAnyFilterApplied: false,

  // always set blank filters
  blankStateVorgangFilterOptions: null,
  reiseterminKuerzelFilterOptions: null,
  reiseFilterOptions: [],

  filter: {
    availableInBlankFilter: getReiseterminAvailableInBlankFilter(),
    // Bearbeiter Filter
    selectedBearbeiter: [],
    // Base State Filter
    baseStateFilter: [],
    // Pax Filter
    paxFilter: [],
    // Reise Filter
    reisenFilter: [],
    // Continent Filter TODO: Rename region to continent
    regionFilter: [],
    // Country Filter
    countryFilter: [],
    // Start Datum Filter
    startDateFilter: { end: null, start: null },
    // Release Datum Filter
    releaseDateFilter: { start: null, end: null },
    // Abreise Datum Filter
    abreiseDateFilter: { start: null, end: null },
    // Umsatz Filter
    umsatzFilter: null,
    // BasisPreis Filter
    basisPreisFilter: null,
    // Frist Filter
    fristFilter: [],
    // Edit State Vorgang Filter
    blankStateVorgangFilter: null,
    // Garantiert Filter
    garantiertFilter: null,
    stopSaleFilter: null,
    // Reiseterminkuerzel Filter
    reiseterminkuerzelFilter: [],
    // Role Filter (For Teamdashboard)
    roleFilter: null,
    // reisedauer Filter
    reisedauerFilter: [],
    // interessenten Filter
    interessentenFilter: null,
  },
  expand: {
    Reise: {},
    Metadata: { expand: { ZiellandZielflughaefen: {}, ZiellandAbflughaefen: {}, Inlandsfluege: {} } },
    gueltigeKonfiguration: {},
    vorgangReleaseprozess: {},
    pax: {},
    comments: {},
  },
  compute: null,
});
const initialState = { ...state() };

const getters = {
  // gets a boolean for all filters if they are set or not
  garantiertFilterLabel(state) {
    return state.filter?.garantiertFilter?.label || null;
  },
  reiseterminFilter(state) {
    return buildOdataFilterFromFilter(state.filter);
  },
  filterMessage(state) {
    const startDate = new Date(state.filter.startDateFilter.start);
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(startDate.getDate() - 2);

    const abreiseDateMessage =
      new Date(state.filter.abreiseDateFilter.start) <= startDate ||
      new Date(state.filter.abreiseDateFilter.start) >= new Date(state.filter.startDateFilter.end)
        ? 'Die ausgewählten Zeiträume für das Start- und das Abreisedatum passen nicht zusammen - bitte überprüfen und korrigieren.'
        : null;
    return abreiseDateMessage;
  },
};

const actions = {
  [GET_FILTER_OPTIONS](context, { odataFilter }) {
    return apiService.get('/reisetermin' + odataFilter).then(response => {
      return response.data.result.value;
    });
  },
  [GET_REISETERMINE](context) {
    context.commit(SET_REISETERMINE_IS_BUSY, true);
    context.commit(SET_APPLIED_FILTER, context.state.filter);
    context.commit(
      SET_IS_ANY_FILTER_APPLIED,
      !isObjectJSONStringifyEqual(context.state.filter, initialState.filter)
    );
    return odataService
      .getReisetermin({
        filter: buildOdataFilterFromFilter(context.state.filter),
        ...(context?.state?.compute && { compute: context.state.compute }),
        ...(context?.state?.expand && { expand: context.state.expand }),
        ...(context?.state?.top && { top: context.state.top }),
        ...(context?.state?.skip && { skip: context.state.skip }),
        ...(context?.state?.orderBy && { orderBy: context.state.orderBy }),
        count: true,
      })
      .then(response => {
        context.commit(SET_IS_CURRENT_FILTER_APPLIED, true);
        context.commit(SET_IS_INITIALLY_LOADING, false);

        context.commit(SET_REISETERMINE, response.data || []);
        context.commit(SET_REISETERMINE_IS_BUSY, false);
        context.commit(SET_REISETERMINE_COUNT, response.count || 0);
        return response.data;
      });
  },
  [GET_VORAENGE_FOR_REISETERMIN_BY_ID](context, reiseterminId) {
    return odataService
      .getReisetermin({
        filter: { id: parseInt(reiseterminId) },
        expand: {
          customState: {},
          Metadata: { expand: { ZiellandZielflughaefen: {}, ZiellandAbflughaefen: {}, Inlandsfluege: {} } },
          GueltigeKonfiguration: {},
          reise: { expand: { konfigurationen: {} } },
          comments: {},
          pax: {},
          hotelleistungen: {
            expand: {
              vorgang: {
                expand: {
                  kunde: {
                    expand: {
                      master: {
                        expand: { kunden: {} },
                      },
                    },
                  },
                  agentur: { select: ['id'] },
                },
              },
            },
          },
        },
      })
      .then(response => {
        return response.data[0];
      });
  },
  [REISETERMINE_SORT_CHANGED](context, { sortContext: sortContext }) {
    let orderBy = convertTableSortToOdata(sortContext.sortBy, sortContext.sortDesc);
    // If its not sorted by startdatum set the second sort layer to startdatum asc
    if (!sortContext.sortBy.includes('startdatum')) {
      orderBy = orderBy + ',startdatum asc';
    }
    context.commit(SET_REISETERMINE_ORDER_BY, orderBy);
    context.commit(SET_REISETERMINE_SKIP, 0);
    return context.dispatch(GET_REISETERMINE);
  },
  [REISETERMINE_PAGE_CHANGED](context, { pageContext: pageContext }) {
    context.commit(SET_REISETERMINE_SKIP, pageContext.skip);
    context.commit(SET_REISETERMINE_TOP, pageContext.top);
    return context.dispatch(GET_REISETERMINE);
  },
  [UPDATE_REISETERMIN_BY_ID](context, id) {
    context.commit(SET_REISETERMINE_IS_BUSY, true);

    return odataService
      .getReisetermin({
        filter: { id: id },
        expand: context.state.expand,
      })
      .then(response => {
        context.commit(SET_REISETERMINE_IS_BUSY, false);

        return context.commit(SET_REISETERMIN_BY_ID, response.data[0]);
      })
      .catch(error => {
        console.log(error);
      });
  },
  [UPDATE_EXPAND_AND_FILTER_FOR_ROLE](context, roleFilterAndExpand) {
    return new Promise(resolve => {
      context.commit(SET_ROLE_FILTER, roleFilterAndExpand.filter);
      context.commit(SET_EXPAND, roleFilterAndExpand.expand);
      return resolve();
    });
  },
  [SAVED_FILTER](context) {
    context.commit(SET_REISETERMINE_SKIP, 0);
    return context.dispatch(GET_REISETERMINE);
  },
  [RESET_FILTER](context) {
    context.commit(SET_REISETERMINE_SKIP, 0);
    context.commit(SET_IS_ANY_FILTER_APPLIED, true);
    context.commit(SET_IS_CURRENT_FILTER_APPLIED, true);
    context.commit(SET_ALL_FILTER_TO_INTIAL);
    return context.dispatch(GET_REISETERMINE);
  },

  [GET_REISE_FILTER_OPTIONS](context) {
    const transformedRegionsCountriesFilter = transformRegionsCountriesFilter(
      context.state.filter.regionFilter,
      context.state.filter.countryFilter
    );
    return getReiseFilterOptions(transformedRegionsCountriesFilter).then(options => {
      return context.commit(SET_REISE_FILTER_OPTIONS, options);
    });
  },

  [GET_REISETERMIN_KUERZEL_FILTER_OPTIONS](context, { kuerzelPart }) {
    const isRefreshable = isOptionsRefreshableByValueAndKey(
      kuerzelPart,
      'reiseterminkuerzel',
      context.state.reiseterminKuerzelFilterOptions,
      3
    );
    return isRefreshable
      ? getReiseterminKuerzelFilterOptions(kuerzelPart).then(options => {
          context.commit(SET_REISETERMIN_KUERZEL_FILTER_OPTIONS, options);
        })
      : context.commit(SET_REISETERMIN_KUERZEL_FILTER_OPTIONS, []);
  },
};

const mutations = {
  [RESET_EXPAND_TO_INITIAL](state) {
    state.expand = initialState.expand;
  },
  [SET_EXPAND](state, expand) {
    state.expand = expand;
  },
  [SET_REISETERMIN_BY_ID](state, newReisetermin) {
    const reiseterminIndex = state.reisetermine.findIndex(
      reisetermin => reisetermin.id === newReisetermin.id
    );
    if (reiseterminIndex >= 0) {
      state.reisetermine[reiseterminIndex] = newReisetermin;
    }
  },
  [SET_REISETERMINE](state, reisetermine) {
    state.reisetermine = reisetermine;
  },
  [SET_REISETERMINE_COUNT](state, count) {
    state.count = count;
  },
  [SET_REISETERMINE_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },
  [SET_IS_INITIALLY_LOADING](state, isInitiallyLoading) {
    state.isInitiallyLoading = isInitiallyLoading;
  },
  [SET_REISETERMINE_ORDER_BY](state, orderBy) {
    state.orderBy = orderBy;
  },
  [SET_REISETERMINE_SKIP](state, skip) {
    state.skip = skip;
  },
  [SET_REISETERMINE_TOP](state, top) {
    state.top = top;
  },
  [SET_QUERY_COMPUTE](state, compute) {
    state.compute = compute;
  },
  // General filter mutations
  [SET_IS_ANY_FILTER_APPLIED](state, isAnyFilterApplied) {
    state.isAnyFilterApplied = isAnyFilterApplied;
  },
  [SET_IS_CURRENT_FILTER_APPLIED](state, isCurrentFilterApplied) {
    state.isCurrentFilterApplied = isCurrentFilterApplied;
  },
  [SET_APPLIED_FILTER](state, appliedReiseterminFilter) {
    state.appliedReiseterminFilter = { ...appliedReiseterminFilter };
  },
  [SET_ALL_FILTER_TO_INTIAL](state) {
    state.filter.selectedBearbeiter = initialState.filter.selectedBearbeiter;
    state.filter.baseStateFilter = initialState.filter.baseStateFilter;
    state.filter.paxFilter = initialState.filter.paxFilter;
    state.filter.reisenFilter = initialState.filter.reisenFilter;
    state.filter.regionFilter = initialState.filter.regionFilter;
    state.filter.countryFilter = initialState.filter.countryFilter;
    state.filter.startDateFilter = initialState.filter.startDateFilter;
    state.filter.releaseDateFilter = initialState.filter.releaseDateFilter;
    state.filter.abreiseDateFilter = initialState.filter.abreiseDateFilter;
    state.filter.umsatzFilter = initialState.filter.umsatzFilter;
    state.filter.basisPreisFilter = initialState.filter.basisPreisFilter;
    state.filter.fristFilter = initialState.filter.fristFilter;
    state.filter.blankStateVorgangFilter = initialState.filter.blankStateVorgangFilter;
    state.filter.garantiertFilter = initialState.filter.garantiertFilter;
    state.filter.stopSaleFilter = initialState.filter.stopSaleFilter;
    state.filter.reisedauerFilter = initialState.filter.reisedauerFilter;
    state.filter.reiseterminkuerzelFilter = initialState.filter.reiseterminkuerzelFilter;
    state.filter.interessentenFilter = initialState.filter.interessentenFilter;
  },
  // Bearbeiter
  [SET_BEARBEITER](state, bearbeiter) {
    state.filter.selectedBearbeiter = Array.isArray(bearbeiter) ? bearbeiter : [bearbeiter];
  },
  [RESET_BEARBEITER](state) {
    state.filter.selectedBearbeiter = [];
  },
  [SET_DEFAULT_BEARBEITER](state, bearbeiter) {
    if (state.filter.selectedBearbeiter.length === 0) {
      state.filter.selectedBearbeiter = Array.isArray(bearbeiter) ? bearbeiter : [bearbeiter];
    }
  },
  // Base State
  [SET_BASE_STATE_FILTER](state, filter) {
    state.filter.baseStateFilter = filter;
  },
  [RESET_BASE_STATE](state) {
    state.filter.baseStateFilter = [];
  },
  // Pax Filter
  [SET_PAX_FILTER](state, filter) {
    state.filter.paxFilter = filter;
  },
  [RESET_PAX_FILTER](state) {
    state.filter.paxFilter = [];
  },
  // Reise Filter
  [SET_REISE_FILTER](state, filter) {
    state.filter.reisenFilter = filter;
  },
  [RESET_REISE_FILTER](state) {
    state.filter.reisenFilter = [];
  },
  [SET_REISE_FILTER_OPTIONS](state, filterOptions) {
    state.reiseFilterOptions = filterOptions;
  },
  // Region (Continent) Filter
  [SET_REGION_FILTER](state, filter) {
    state.filter.regionFilter = filter;
  },
  [RESET_REGION_FILTER](state) {
    state.filter.regionFilter = [];
  },
  // Country (Continent) Filter
  [SET_COUNTRY_FILTER](state, filter) {
    state.filter.countryFilter = filter;
  },
  [RESET_COUNTRY_FILTER](state) {
    state.filter.countryFilter = [];
  },
  // Start Date Filter
  [SET_START_DATE_FILTER](state, date) {
    state.filter.startDateFilter = date || { start: null, end: null };
  },
  [RESET_START_DATE_FILTER](state) {
    state.filter.startDateFilter = { start: null, end: null };
  },
  // Release Date Filter
  [SET_RELEASE_DATE_FILTER](state, date) {
    state.filter.releaseDateFilter = date || { start: null, end: null };
  },
  [RESET_RELEASE_DATE_FILTER](state) {
    state.filter.releaseDateFilter = { start: null, end: null };
  },
  // Abreise Date Filter
  [SET_ABREISE_DATE_FILTER](state, date) {
    state.filter.abreiseDateFilter = date || { start: null, end: null };
  },
  [RESET_ABREISE_DATE_FILTER](state) {
    state.filter.abreiseDateFilter = { start: null, end: null };
  },
  // Umsatz Filter
  [SET_UMSATZ_FILTER](state, range) {
    state.filter.umsatzFilter = range;
  },
  [RESET_UMSATZ_FILTER](state) {
    state.filter.umsatzFilter = null;
  },
  // Basis Preis Filter
  [SET_BASIS_PREIS_FILTER](state, range) {
    state.filter.basisPreisFilter = range;
  },
  [RESET_BASIS_PREIS_FILTER](state) {
    state.filter.basisPreisFilter = null;
  },
  // Frist Filter
  [SET_FRIST_FILTER](state, filter) {
    state.filter.fristFilter = filter;
  },
  [RESET_FRIST_FILTER](state) {
    state.filter.fristFilter = [];
  },
  // Edit State Vorgang Filter
  [SET_BLANK_STATE_VORGANG_FILTER](state, blankStateVorgangFilter) {
    state.filter.blankStateVorgangFilter = blankStateVorgangFilter || null;
  },
  [SET_BLANK_STATE_VORGANG_FILTER_OPTIONS](state, filterOptions) {
    state.blankStateVorgangFilterOptions = filterOptions;
  },
  [RESET_BLANK_STATE_VORGANG_FILTER](state) {
    state.filter.blankStateVorgangFilter = null;
  },
  // Garantiert Filter
  [SET_GARANTIERT_FILTER](state, filter) {
    state.filter.garantiertFilter = filter;
  },
  [RESET_GARANTIERT_FILTER](state) {
    state.filter.garantiertFilter = null;
  },
  [SET_STOP_SALE_FILTER](state, filter) {
    state.filter.stopSaleFilter = filter;
  },
  [RESET_STOP_SALE_FILTER](state) {
    state.filter.stopSaleFilter = null;
  },
  // Reiseterminkuerzel Filter
  [SET_REISETERMIN_KUERZEL_FILTER](state, filter) {
    state.filter.reiseterminkuerzelFilter = filter;
  },
  [RESET_REISETERMIN_KUERZEL_FILTER](state) {
    state.filter.reiseterminkuerzelFilter = [];
  },
  [SET_REISETERMIN_KUERZEL_FILTER_OPTIONS](state, options) {
    state.reiseterminKuerzelFilterOptions = options;
  },
  // Role Filter (for teamdashboard)
  [SET_ROLE_FILTER](state, filter) {
    state.filter.roleFilter = filter;
  },
  [RESET_ROLE_FILTER](state) {
    state.filter.roleFilter = null;
  },
  // Interessenten Filter
  [SET_INTERESSENTEN_FILTER](state, filter) {
    state.filter.interessentenFilter = filter;
  },
  [RESET_INTERESSENTEN_FILTER](state) {
    state.filter.interessentenFilter = null;
  },
};

function buildOdataFilterFromFilter(filterObject) {
  const bearbeiterFilter = buildBearbeiterFilterQuery(filterObject.selectedBearbeiter);
  const baseStateFilter = buildMultiSelectFilterQueryElement(filterObject.baseStateFilter);
  const paxFilter = buildMultiSelectFilterQueryElement(filterObject.paxFilter);
  const garantiertFilter = filterObject?.garantiertFilter?.filter || null;
  const stopSaleFilter = filterObject?.stopSaleFilter?.filter || null;
  const reisedauerFilter = buildMultiSelectFilterQueryElement(filterObject.reisedauerFilter);
  const reisenFilter = buildReiseQueryElement(filterObject.reisenFilter);
  const regionFilter = buildQueryElementFromArrayWithProperty(
    filterObject.regionFilter.map(region => region.value),
    'Reise/region'
  );
  const countryFilter = buildQueryElementFromArrayWithProperty(
    filterObject.countryFilter.map(country => country.value),
    'Reise/country'
  );
  const startDateFilter = buildDateRangeFilterQuery(filterObject.startDateFilter, 'startdatum');
  const releaseDateFilter = buildDateRangeFilterQuery(filterObject.releaseDateFilter, 'releasedatum');
  const abreiseDateFilter = buildDateRangeFilterQuery(filterObject.abreiseDateFilter, 'abreisedatum');
  const umsatzFilter = buildOdataRangeFilterQuery(filterObject.umsatzFilter, 'umsatz');
  const basisPreisFilter = buildOdataRangeFilterQuery(filterObject.basisPreisFilter, 'basisPreis');
  const fristFilter = buildMultiSelectFilterQueryElement(filterObject.fristFilter);
  const blankStateVorgangFilter = buildBlankStateVorgangQuery(filterObject.blankStateVorgangFilter);

  const reiseterminkuerzelFilter = buildQueryElementFromArrayWithProperty(
    filterObject.reiseterminkuerzelFilter.map(filter => filter.kuerzelLabel),
    'reiseterminkuerzel'
  );

  const interessentenFilter = buildInteressentenFilterQueryElement(filterObject.interessentenFilter);

  const roleFilter = filterObject.roleFilter;

  //General filter,  is always set
  const availableInBlankFilter = filterObject.availableInBlankFilter;

  return [
    interessentenFilter,
    roleFilter,
    availableInBlankFilter,
    bearbeiterFilter,
    baseStateFilter,
    paxFilter,
    reisenFilter,
    regionFilter,
    countryFilter,
    startDateFilter,
    releaseDateFilter,
    abreiseDateFilter,
    umsatzFilter,
    basisPreisFilter,
    fristFilter,
    blankStateVorgangFilter,
    garantiertFilter,
    stopSaleFilter,
    reisedauerFilter,
    reiseterminkuerzelFilter,
  ];
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
