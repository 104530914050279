const trigger = {
  reisetermine: {
    TriggerAbgesagt: {
      color: 'danger',
    },
    TriggerBereitFuerRelease: {
      color: 'primary',
    },
    TriggerReleased: {
      color: 'primary',
      confirmmsg: 'ReiseterminTriggerConfirmMsgReleasedPaxZero',
      isConfirmRequiredFunction: reisetermin => {
        return reisetermin.pax.ist === 0;
      },
    },
    TriggerReleasedTransferplanung: {
      color: 'primary',
      confirmmsg: 'ReiseterminTriggerConfirmMsgReleasedPaxZero',
      isConfirmRequiredFunction: reisetermin => {
        return reisetermin.pax.ist === 0;
      },
    },
    TriggerDurchfuehrung: {
      color: 'primary',
    },
    TriggerStartePruefungDerDurchfuehrung: {
      color: 'primary',
    },
    TriggerDurchfuehrbarkeitPruefenDoing: {
      color: 'primary',
    },
    TriggerAbbrechenInAbsage: {
      color: 'primary',
    },

    TriggerStarteInAbsage: {
      color: 'primary',
    },
    TriggerAufgelegtChangeAbsageStateInBlankWaiting: {
      color: 'primary',
      confirmmsg: 'TriggerAufgelegtChangeAbsageStateInBlankWaiting',
    },
    TriggerAufgelegtVorgaengeWerdenAbgesagtWaiting: {
      color: 'primary',
    },
    TriggerBereitFuerReleaseChangeAbsageStateInBlankWaiting: {
      color: 'primary',
      confirmmsg: 'TriggerAufgelegtChangeAbsageStateInBlankWaiting',
    },
    TriggerBereitFuerReleaseVorgaengeWerdenAbgesagtWaiting: {
      color: 'primary',
    },

    TriggerStarteInReleasepruefung: {
      color: 'primary',
      confirmmsg: 'ReiseterminTriggerConfirmMsg500',
    },
    TriggerReleasepruefungDoing: {
      color: 'primary',
    },

    TriggerStarteInReleasepruefungErneut: {
      color: 'primary',
      confirmmsg: 'ReiseterminTriggerConfirmMsg600',
    },
    TriggerReleasepruefungErneutDoing: {
      color: 'primary',
    },
    TriggerReleasepruefungPart2Waiting: {
      color: 'primary',
    },
    TriggerReleasepruefungPart2Todo: {
      color: 'primary',
    },
    TriggerReleasepruefungPart2Doing: {
      color: 'primary',
    },
    TriggerStarteInReleasepruefungWennNeueBuchung: {
      color: 'primary',
    },
    TriggerStarteInTransferplanung: {
      color: 'primary',
    },
    TriggerTransferplanungDoing: {
      color: 'primary',
    },
    TriggerStarteInTransferplanungErneut: {
      color: 'primary',
    },
    TriggerTransferplanungErneutDoing: {
      color: 'primary',
    },
    TriggerReiseterminReaktivieren: {
      color: 'primary',
    },
  },
};
export default trigger;
