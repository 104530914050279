import { getKundenMasterIdFilterOptions, getKundeMaster } from '@/core/common/services/odata.service';
import { isIdFilterRefreshable } from '@/core/common/helpers/utils';
import { isObjectJSONStringifyEqual } from '@/core/common/helpers/utils';
import { convertTableSortToOdata } from '@/core/common/helpers/odataUtils';
import apiService from '@/core/common/services/api.service';

export const GET_KUNDEN = 'kunden.getKunden';
export const SET_KUNDEN = 'kunden.setKunden';
export const SET_KUNDEN_TOP = 'kunden.setKundenTop';
export const SET_KUNDEN_SKIP = 'kunden.setKundenSkip';
export const SET_KUNDEN_ORDER_BY = 'kunden.setKundenOrderBy';
export const SET_KUNDEN_IS_BUSY = 'kunden.setKundenIsBusy';
export const SET_KUNDEN_COUNT = 'kunden.setKundenCount';
export const SET_IS_INITIALLY_LOADING = 'kunden.setIsInitiallyLoading';

export const SET_IS_ANY_FILTER_APPLIED = 'kunden.setIsAnyFilterApplied';
export const SET_IS_CURRENT_FILTER_APPLIED = 'kunden.setIsCurrentFilterApplied';

export const KUNDEN_SORT_CHANGED = 'kunden.sortChanged';
export const KUNDEN_PAGE_CHANGED = 'kunden.pageChanged';

export const UPDATE_KUNDE_BY_ID = 'kunden.updateKundenById';
export const SET_KUNDE_BY_ID = 'kunden.setKundeById';

export const SAVED_FILTER = 'kunden.savedFilter';
export const RESET_FILTER = 'kunden.resetFilter';

export const SET_APPLIED_FILTER = 'kunden.setAppliedkunden';

export const SET_ALL_FILTER_TO_INTIAL = 'kunden.resetAllFilter';

export const SET_EXPAND = 'kunden.setExpand';
export const RESET_EXPAND_TO_INITIAL = 'kunden.resetExpandToInitial';

export const SET_KUNDE_MASTER_ID_FILTER = 'kunden.setkundeMasterIdFilter';
export const RESET_KUNDE_MASTER_ID_FILTER = 'kunden.resetkundeMasterIdFilter';
export const GET_KUNDE_MASTER_ID_FILTER_OPTIONS = 'kunden.getkundeMasterIdFilterOptions';
export const SET_KUNDE_MASTER_ID_FILTER_OPTIONS = 'kunden.setkundeMasterIdFilterOptions';
export const SET_KUNDE_MASTER_ALEAST_ONE_KUNDE_FILTER = 'kunden.setkundeMasterAtleastOneKundeFilter';

export const SET_KUNDE_MASTER_VALIDATION_STATE_KUNDE_FILTER =
  'kunden.setkundeMasterValidationStateKundeFilter';

export const UNMERGE_KUNDE = 'kunden.unmergeKunde';
export const CREATE_KUNDE_DUPLICATE_AND_ACCEPT = 'kunden.createKundeDuplicateAndAccept';

const state = () => ({
  kunden: [],
  top: 25,
  skip: 0,
  orderBy: 'updatedDateUtc desc',
  count: 0,
  isInitiallyLoading: true,
  isBusy: true,
  isCurrentFilterApplied: true,
  isAnyFilterApplied: false,
  appliedKunden: {},
  appliedKundenFilter: null,
  kundeMasterIdFilterOptions: [],
  // always set blank filters
  filter: {
    kundeMasterIdFilter: [],
    validationStateKundeFilter: [],
    atleastOneKundeFilter: true,
  },
  expand: {
    Kunden: {
      expand: {
        Name: {},
        Adresse: {},
        TelefonPrivat: {},
        TelefonGeschaeftlich: {},
        TelefonMobil: {},
      },
    },
  },
});
const initialState = { ...state() };

const getters = {
  // gets a boolean for all filters if they are set or not
  setKundenFiltersObject(state) {
    return {
      kundeMasterIdFilter:
        Array.isArray(state.filter.kundeMasterIdFilter) && state.filter.kundeMasterIdFilter.length > 0,
      validationStateKundeFilter:
        Array.isArray(state.filter.validationStateKundeFilter) &&
        state.filter.validationStateKundeFilter.length > 0,
      atleastOneKundeFilter: !state.filter.atleastOneKundeFilter,
    };
  },
  // get the filter object
  reiseterminFiltersObject(state) {
    return state.filter;
  },
  reiseterminFilter(state) {
    return buildOdataFilterFromFilter(state.filter);
  },
};

const actions = {
  [GET_KUNDEN](context) {
    context.commit(SET_KUNDEN_IS_BUSY, true);
    return getKundeMaster({
      filter: buildOdataFilterFromFilter(context.state.filter),
      expand: context.state.expand,
      top: context.state.top,
      skip: context.state.skip,
      orderBy: context.state.orderBy,
      count: true,
    })
      .then(response => {
        context.commit(SET_IS_CURRENT_FILTER_APPLIED, true);
        context.commit(SET_IS_INITIALLY_LOADING, false);

        context.commit(
          SET_IS_ANY_FILTER_APPLIED,
          !isObjectJSONStringifyEqual(context.state.filter, initialState.filter)
        );

        context.commit(SET_APPLIED_FILTER, context.state.filter);
        context.commit(SET_KUNDEN, response.data || []);
        context.commit(SET_KUNDEN_IS_BUSY, false);
        context.commit(SET_KUNDEN_COUNT, response.count || 0);
        return response.data;
      })
      .catch(error => {
        console.log(error);
      });
  },
  [KUNDEN_SORT_CHANGED](context, { sortContext: sortContext }) {
    let orderBy = convertTableSortToOdata(sortContext.sortBy, sortContext.sortDesc);
    // If its not sorted by startdatum set the second sort layer to startdatum asc
    if (sortContext.sortBy === 'state') {
      sortContext.sortBy = 'validationStateKunde';
    }
    if (sortContext.sortBy === 'kunden') {
      sortContext.sortBy = 'kunden/$count';
    }
    context.commit(SET_KUNDEN_ORDER_BY, orderBy);
    context.commit(SET_KUNDEN_SKIP, 0);
    return context.dispatch(GET_KUNDEN);
  },
  [KUNDEN_PAGE_CHANGED](context, { pageContext: pageContext }) {
    context.commit(SET_KUNDEN_SKIP, pageContext.skip);
    context.commit(SET_KUNDEN_TOP, pageContext.top);
    return context.dispatch(GET_KUNDEN);
  },
  [UPDATE_KUNDE_BY_ID](context, id) {
    context.commit(SET_KUNDEN_IS_BUSY, true);

    return getKundeMaster({
      filter: { id: id },
      expand: context.state.expand,
    })
      .then(response => {
        context.commit(SET_KUNDEN_IS_BUSY, false);

        return context.commit(SET_KUNDE_BY_ID, response.data[0]);
      })
      .catch(error => {
        console.log(error);
      });
  },
  [GET_KUNDE_MASTER_ID_FILTER_OPTIONS](context, { idFilter }) {
    const isRefreshable = isIdFilterRefreshable(idFilter, context.state.kundeMasterIdFilterOptions, 3);
    return isRefreshable
      ? getKundenMasterIdFilterOptions(idFilter).then(options => {
          context.commit(SET_KUNDE_MASTER_ID_FILTER_OPTIONS, options);
        })
      : context.commit(SET_KUNDE_MASTER_ID_FILTER_OPTIONS, []);
  },
  [SAVED_FILTER](context) {
    context.commit(SET_KUNDEN_SKIP, 0);
    return context.dispatch(GET_KUNDEN);
  },
  [RESET_FILTER](context) {
    context.commit(SET_KUNDEN_SKIP, 0);
    context.commit(SET_IS_ANY_FILTER_APPLIED, true);
    context.commit(SET_IS_CURRENT_FILTER_APPLIED, true);
    context.commit(SET_ALL_FILTER_TO_INTIAL);
    return context.dispatch(GET_KUNDEN);
  },
  [UNMERGE_KUNDE](context, id) {
    context.commit(SET_KUNDEN_IS_BUSY, true);
    if (context.state.kunde.kunden && context.state.kunde.kunden.length <= 2) {
      return apiService
        .put(`Kunde/${context.state.kunde.id}/KundenDuplicates/${id}`, {
          id: context.state.kunde.id,
          duplicateKundeId: id,
          trigger: 2,
        })
        .then(response => {
          return context.dispatch(GET_KUNDEN);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          context.commit(SET_KUNDEN_IS_BUSY, false);
        });
    } else {
      return apiService
        .put(`Kunde/${id}/reset-master`, { id: id })
        .then(response => {
          return context.dispatch(GET_KUNDEN);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          context.commit(SET_KUNDEN_IS_BUSY, false);
        });
    }
  },
  [CREATE_KUNDE_DUPLICATE_AND_ACCEPT](context, { kunde1Id: kunde1Id, kunde2Id: kunde2Id }) {
    return createKundenDuplicateAndAccept(kunde1Id, kunde2Id);
  },
};

const mutations = {
  [RESET_EXPAND_TO_INITIAL](state) {
    state.expand = initialState.expand;
  },
  [SET_EXPAND](state, expand) {
    state.expand = expand;
  },
  [SET_KUNDE_BY_ID](state, newKunde) {
    const kundeIndex = state.reisetermine.findIndex(kunde => kunde.id === newKunde.id);
    if (kundeIndex >= 0) {
      state.reisetermine[kundeIndex] = newKunde;
    }
  },
  [SET_KUNDEN](state, kunden) {
    state.kunden = kunden;
  },
  [SET_KUNDEN_COUNT](state, count) {
    state.count = count;
  },
  [SET_KUNDEN_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },
  [SET_IS_INITIALLY_LOADING](state, isInitiallyLoading) {
    state.isInitiallyLoading = isInitiallyLoading;
  },
  [SET_KUNDEN_ORDER_BY](state, orderBy) {
    state.orderBy = orderBy;
  },
  [SET_KUNDEN_SKIP](state, skip) {
    state.skip = skip;
  },
  [SET_KUNDEN_TOP](state, top) {
    state.top = top;
  },
  // General filter mutations
  [SET_IS_ANY_FILTER_APPLIED](state, isAnyFilterApplied) {
    state.isAnyFilterApplied = isAnyFilterApplied;
  },
  [SET_IS_CURRENT_FILTER_APPLIED](state, isCurrentFilterApplied) {
    state.isCurrentFilterApplied = isCurrentFilterApplied;
  },
  [SET_APPLIED_FILTER](state, appliedReiseterminFilter) {
    state.appliedReiseterminFilter = { ...appliedReiseterminFilter };
  },
  [SET_KUNDE_MASTER_ALEAST_ONE_KUNDE_FILTER](state, isAtleastOneKunde) {
    state.filter.atleastOneKundeFilter = isAtleastOneKunde;
  },
  // Base State
  [SET_KUNDE_MASTER_VALIDATION_STATE_KUNDE_FILTER](state, filter) {
    state.filter.validationStateKundeFilter = filter;
  },
  [SET_KUNDE_MASTER_ID_FILTER_OPTIONS](state, options) {
    state.kundeMasterIdFilterOptions = options;
  },
  [SET_KUNDE_MASTER_ID_FILTER](state, filter) {
    state.filter.kundeMasterIdFilter = filter;
  },
  [RESET_KUNDE_MASTER_ID_FILTER](state) {
    state.filter.kundeMasterIdFilter = [];
  },
  // General mutations
  [SET_APPLIED_FILTER](state, appliedKunden) {
    state.appliedKunden = { ...appliedKunden };
  },
  [SET_ALL_FILTER_TO_INTIAL](state) {
    state.filter.kundeMasterIdFilter = initialState.filter.kundeMasterIdFilter;
    state.filter.validationStateKundeFilter = initialState.filter.validationStateKundeFilter;
    state.filter.validationStateKundeFilter = initialState.filter.validationStateKundeFilter;
    state.filter.atleastOneKundeFilter = initialState.filter.atleastOneKundeFilter;
  },
};

function buildOdataFilterFromFilter(filterObject) {
  if (!filterObject) return [];
  const kundeMasterIdFilter = buildkundenQuery(filterObject.kundeMasterIdFilter);
  const kundeMasterDefaultFilter = getKundeMasterDefaultFilter(); //General filter, is always set
  const kundeMasterValidationStateKundeFilter = buildMultiSelectFilterQueryElement(
    filterObject.validationStateKundeFilter
  );
  const isDeletedFilter = { isDeleted: false };
  const atleastOneKundeFilter = filterObject.atleastOneKundeFilter ? { kunden: { $count: { gt: 1 } } } : null;

  return [
    atleastOneKundeFilter,
    kundeMasterValidationStateKundeFilter,
    kundeMasterIdFilter,
    kundeMasterDefaultFilter,
    isDeletedFilter,
  ];
}

function getKundeMasterDefaultFilter() {
  return null;
}

function buildkundenQuery(kunde) {
  if (kunde && kunde.length > 0) {
    return {
      kunden: {
        any: {
          id: { in: kunde.map(kunde => kunde.id) || null },
        },
      },
    };
  } else {
    return null;
  }
}

function buildMultiSelectFilterQueryElement(selectedArray) {
  if (Array.isArray(selectedArray) && selectedArray.length > 0) {
    return { or: selectedArray.map(selected => selected.filter) };
  } else {
    return null;
  }
}

function createKundenDuplicateAndAccept(kunde1Id, kunde2Id) {
  return apiService
    .post(`Kunde/KundenDuplicates`, {
      KundeId1: kunde1Id,
      KundeId2: kunde2Id,
    })
    .then(data => {
      return data;
    })
    .catch(err => {
      throw err;
    });
}

export default {
  state,
  actions,
  mutations,
  getters,
};
