import { LicenseManager } from 'ag-grid-enterprise';

const setLicenseKey = () => {
  const key = process.env.VUE_APP_AG_GRID_LICENSE;
  LicenseManager.setLicenseKey(key);
};

export default {
  setLicenseKey,
};
